import React, { useState, useRef, useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  IconButton,
  Typography,
  Breadcrumbs,
  Box,
  Link,
  TextField,
  Snackbar,
  FormControl,
  MenuItem,
  Dialog,
  DialogContent,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Select,
  TableRow,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

import {
  productGroups,
  manufacturers,
  productStatus
} from "../common/lookupSlice";

import { API_ALL_PRODUCTS } from "../../config/constants";

const ProductStatus = (props) => {
  const prodMfgs = useSelector(manufacturers);
  let prodStatus = useSelector(productStatus);
  const prodGrps = useSelector(productGroups);
  const pageSize = 10;
  const history = useHistory();

  const [count, setCount] = useState();
  const [prodForm, setProdForm] = useState({ open: false, prodId:null });
  const [mfgCode, setMfgCode] = useState("");
  const [productSt, setProdSt] = useState("ALL");
  const [fromProductSt, setFormProdSt] = useState(null);
  const [productNm, setProdName] = useState(null);
  // prodStatus = (prodStatus && prodStatus.length > 0 )? prodStatus.filter(x=>x.code !== "ACTIVE"):[];
  const [group, setGroup] = useState("");
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize, productStatus:"ALL" });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const itemNameRef = useRef();
  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.products);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      productName: e.target.elements.itemName.value,
      mfgId:
        e.target.elements.mfgCode.value !== "All"
          ? e.target.elements.mfgCode.value
          : "",
      prdGrpId: group,
      productStatus: productSt,    
      page: 1,
    });
  };

  const resetQuery = () => {
    window.location.reload();
  };

  const editProduct = async (rowData) => {
    console.log(rowData.productName);
    try {
      setFormProdSt(rowData && rowData.prodStatus && rowData.prodStatus.id);
      setProdName(rowData && rowData.productName);
      setProdForm({
        ...prodForm,
        prodId: rowData && rowData.id,
        open: true,
      });
    } catch (err) {
      setIsError(true);
    }
  };

  const makeProdActive = async() =>{
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVICE_URL}/products/${prodForm.prodId}`,
        {productStatus: fromProductSt},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProdForm({ ...prodForm, open: false, prodId: null,success:true });
      setFormProdSt(null)
      setProdName(null);
      setRefresh(true);
    } catch (err) {
      setIsError(true);
    }
  }

  return (
    <div>
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/reports")}>
                  Reports
                </Link>
                <Typography component="span" color="textPrimary">
                  Product Status
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>
      <div className="user-filter-grids">
        <Typography variant="h6">
         Product Search
        </Typography>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    inputRef={itemNameRef}
                    InputProps={{
                      name: "itemName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
          <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Group</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ display: "flex" }}
                >
                  <Autocomplete
              id="combo-box-demo"
              options={prodGrps ? prodGrps:[]}
              getOptionLabel={(option) => option.code}
              size="small"
              onChange={(e, val) => {
                if (val) {
                  setGroup(val.id);
                  setQuery({
                    ...query,
                    prdGrpId: val.id,
                    page: 1,
                  });
                }
                else{
                  setQuery({
                    ...query,
                    prdGrpId: "",
                    page: 1,
                  });
                }
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Manufacturer</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ display: "flex" }}
                >
                  <Select
                    name="mfgCode"
                    style={{ display: "flex" }}
                    value={mfgCode}
                    onChange={(e) => {
                      setMfgCode(e.target.value);
                      setQuery({
                        ...query,
                        mfgId: e.target.value !== "All" ? e.target.value : "",
                        page: 1,
                      });
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <MenuItem key={0} value={"All"}>
                      All
                    </MenuItem>
                    {prodMfgs.map((mfg, index) => {
                      return (
                        <MenuItem key={index + 1} value={mfg.id}>
                          {mfg.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Status</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ display: "flex" }}
                >
                  <Select
                    name="productStatus"
                    style={{ display: "flex" }}
                    value={productSt}
                    onChange={(e) => {
                      setProdSt(e.target.value);
                      setQuery({
                        ...query,
                        productStatus: e.target.value,
                        page: 1,
                      });
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <MenuItem key={0} value={"ALL"}>
                      All
                    </MenuItem>
                    {prodStatus.map((prodSt, index) => {
                      return (
                        <MenuItem key={index + 1} value={prodSt.id}>
                          {prodSt.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" md={2} xs={6}>
              <Grid item>
                <Box mt={2} mr={2}>
                  <Button
              variant="contained"
              color="primary"
              size="small"
              autoFocus
              type="submit"
            >
            Search
            </Button>
            </Box>
              </Grid>
            <Grid item>
            <Box mt={2}>
                <Button size="small" type="reset" onClick={() => resetQuery()}
                   variant="contained" color="secondary">
                  clear</Button>
              </Box>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Manufacturer</StyledHeaderCell>
                <StyledHeaderCell align="left">Brand</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Type</StyledHeaderCell>
                <StyledHeaderCell align="left">
                  Product Category
                </StyledHeaderCell>
                <StyledHeaderCell align="center">Status</StyledHeaderCell>
                <StyledHeaderCell align="center">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography variant="subtitle1">
                        No records found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row, index) => {
                    return(
                    <TableRow
                      key={row.id}
                      hover={true}
                      onClick={(e) =>
                        props.onRowSelect && props.onRowSelect(row)
                      }>
                     <TableCell component="th" scope="row">{row.productName}</TableCell>
                      <TableCell align="left">
                        {row.manufacturer && row.manufacturer.description}
                      </TableCell>
                      <TableCell align="left">
                        {row.brand && row.brand.description}
                      </TableCell>
                      <TableCell align="left">
                        {row.type && row.type.description}
                      </TableCell>
                      <TableCell align="left">
                        {row.category && row.category.description}
                      </TableCell>
                      <TableCell align="center">
                        {row.prodStatus && row.prodStatus.description}
                      </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              editProduct(row);
                            }}
                          >
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                    </TableRow>
                  )})
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={prodForm.open}
        onClose={() => {
          setProdForm({ ...prodForm, open: false, prodId: null });
          setFormProdSt(null);
          setProdName(null);
        }}
      >
        <DialogContent>
        <Grid container>
            <Grid item container xs={12} alignItems="center">
              <Grid item xs={12} style={{lineHeight:1.6}}>
              Update Product Status
              </Grid>
              <Grid item md={12} xs={12} style={{marginTop:"8px"}}>
                <b>Product Name : {productNm}</b>
              </Grid>
              <Grid item md={6} xs={12} style={{marginTop:"8px"}}>
                  <Typography variant="caption">Product Status <span style={{color: 'red'}}>*</span></Typography>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ display: "flex" }}
                    fullWidth
                  >
                    <Select
                      name="productStatus"
                      style={{ display: "flex" }}
                      value={fromProductSt}
                      fullWidth
                      onChange={(e) => {
                        setFormProdSt(e.target.value);
                      }}
                      variant="outlined"
                      size="small"
                    >
                      {prodStatus.map((prodSt, index) => {
                        return (
                          <MenuItem key={index + 1} value={prodSt.id}>
                            {prodSt.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
              </Grid>
             <Grid item xs={12}> 
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  onClick={makeProdActive}
                  color="primary"
                  size="small"
                  variant="contained"
                  style={{ marginRight: 10 }}
                >
                  Save
              </Button>
              <Button
                onClick={() => {setProdForm({ ...prodForm, open: false, prodId: null});
                setFormProdSt(null);
                setProdName(null)}}
                color="secondary"
                size="small"
                variant="contained"
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
            </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={prodForm.success}
        onClose={() => {setProdForm({ ...prodForm, success: false,  prodId: null });
        setFormProdSt(null);
        setProdName(null)}}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() =>{ setProdForm({ ...prodForm, success: false,  prodId: null }); 
          setFormProdSt(null);
          setProdName(null)}}
          elevation={6}
          variant="filled"
          severity="success"
        >
          Product Status Updated Successfull.
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ProductStatus;
