import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  API_ROLES,
  API_LOCALITIES,
  API_PRODUCT_LOOKUP,
  API_PRODUCT_GROUPS_LOOKUP,
  API_ALL_PRODUCT_GROUPS,
  API_JOB_LOOKUP,
  API_ALL_USERS,
  API_PURCHASE_ORDER,
  API_PO_LOOKUP,
  API_ALL_CUSTOMERS,
  API_LOCATION,
  API_ALL_PRODUCTS
} from "../../config/constants";
export const lookupSlice = createSlice({
  name: "lookup",
  initialState: {
    roles: null,
    brands: null,
    manufacturers: null,
    productTypes: null,
    partTypes: null,
    productGroupTypes:  null,
    productGroups: null,
    productCategories: null,
    productStatus: null,
    uoms: null,
    localities: null,
    jobStatuses: null,
    jobCategories: null,
    jobTypes: null,
    technicians: [],
    poStatus: [],
    poTypes: [],
    custStates: [],
    custTypes: [],
    locations: [],
    products: [],
    invoiceTypes:[],
    paymentTypes:[],
    paymentModes:[]
  },
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setManufacturers: (state, action) => {
      state.manufacturers = action.payload;
    },
    setProductTypes: (state, action) => {
      state.productTypes = action.payload;
    },
    setPartTypes: (state, action) => {
      state.partTypes = action.payload;
    },
    setProductGroupTypes: (state, action) => {
      state.productGroupTypes = action.payload;
    },
    setProductGroups: (state, action) => {
      state.productGroups = action.payload;
    },
    setProductCategories: (state, action) => {
      state.productCategories = action.payload;
    },
    setProductStatus: (state, action) => {
      state.productStatus = action.payload;
    },
    setUOMs: (state, action) => {
      state.uoms = action.payload;
    },
    setLocalities: (state, action) => {
      state.localities = action.payload;
    },
    setJobCategories: (state, action) => {
      state.jobCategories = action.payload;
    },
    setJobStatuses: (state, action) => {
      state.jobStatuses = action.payload;
    },
    setJobTypes: (state, action) => {
      state.jobTypes = action.payload;
    },
    setTechnicians: (state, action) => {
      state.technicians = action.payload;
    },
    setPOStatus: (state, action) => {
      state.poStatus = action.payload;
    },
    setPOTypes: (state, action) => {
      state.poTypes = action.payload;
    },
    setCustStates: (state, action) => {
      state.custStates = action.payload;
    },
    setCustTypes: (state, action) => {
      state.custTypes = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setInvoiceTypes: (state, action) => {
      state.invoiceTypes = action.payload;
    },
    setPaymentModes: (state, action) => {
      state.paymentModes = action.payload;
    },
    setPaymentTypes: (state, action) => {
      state.paymentTypes = action.payload;
    }
  },
});

export const {
  setRoles,
  setBrands,
  setManufacturers,
  setProductTypes,
  setPartTypes,
  setProductCategories,
  setProductStatus,
  setUOMs,
  setLocalities,
  setJobCategories,
  setJobStatuses,
  setJobTypes,
  setTechnicians,
  setProductGroupTypes,
  setProductGroups,
  setPOStatus,
  setPOTypes,
  setCustStates,
  setCustTypes,
  setLocations,
  setProducts,
  setInvoiceTypes,
  setPaymentTypes,
  setPaymentModes
} = lookupSlice.actions;

export const fetchRoles = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_ROLES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setRoles(res.data));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get roles.");
      }
    });
};

export const fetchLocalities = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_LOCALITIES}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setLocalities(res.data));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get localities.");
      }
    });
};

export const fetchProductLookup = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_PRODUCT_LOOKUP}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setManufacturers(res.data.manufacturers));
      dispatch(setProductTypes(res.data.productTypes));
      dispatch(setPartTypes(res.data.partTypes));
      dispatch(setBrands(res.data.brands));
      dispatch(setProductCategories(res.data.productCategories));
      dispatch(setUOMs(res.data.uoms));
      dispatch(setProductStatus(res.data.productStatus));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get product lookup.");
      }
    });
};

export const fetchProductGroupTypesLookup = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_PRODUCT_GROUPS_LOOKUP}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setProductGroupTypes(res.data.productGroupTypes));
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get product groups lookup.");
      }
    });
};

export const fetchProductGroupsLookup = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCT_GROUPS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setProductGroups(res.data.productGroups));
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get product groups lookup.");
      }
    });
};

export const fetchAllTechnicians = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_ALL_USERS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { role: "TECHNICIAN" },
    })
    .then((res) => {
      dispatch(setTechnicians(res.data.users));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get all technician lookup.");
      }
    });
};

export const fetchJobLookup = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_JOB_LOOKUP}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setJobCategories(res.data.jobCategories));
      dispatch(setJobTypes(res.data.jobTypes));
      dispatch(setJobStatuses(res.data.jobStatuses));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get Job lookup.");
      }
    });
};

export const fetchPOStatusAndTypes = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/${API_PO_LOOKUP}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setPOStatus(res.data.poStatuses));
      dispatch(setPOTypes(res.data.poTypes));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get PO status and types lookup.");
      }
    });
};

export const fetchCustStatesAndTypes = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_ALL_CUSTOMERS}/${API_PO_LOOKUP}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setCustStates(res.data.states));
      dispatch(setCustTypes(res.data.custTypes));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get Customer states and types lookup.");
      }
    });
};

export const fetchLocations = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_LOCATION}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setLocations(res.data));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get locations");
      }
    });
};

export const fetchProducts = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  const params = {page:1,pageSize:5}
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/${API_ALL_PRODUCTS}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    })
    .then((res) => {
      dispatch(setProducts(res.data.products));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get products");
      }
    });
};

export const fetchInvoiceLookup = () => (dispatch) => {
  const token = localStorage.getItem("authToken");
  axios
    .get(`${process.env.REACT_APP_SERVICE_URL}/invoices/seed/lookup`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      dispatch(setInvoiceTypes(res.data.invoiceTypes));
      dispatch(setPaymentModes(res.data.paymentModes));
      dispatch(setPaymentTypes(res.data.paymentTypes));
    })
    .catch((err) => {
      console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get invoice lookup.");
      }
    });
};

export const roles = (state) => state.lookup.roles;

export const brands = (state) => state.lookup.brands;
export const manufacturers = (state) => state.lookup.manufacturers;
export const productTypes = (state) => state.lookup.productTypes;
export const partTypes = (state) => state.lookup.partTypes;
export const productGroupTypes = (state) => state.lookup.productGroupTypes;
export const productGroups = (state) => state.lookup.productGroups;
export const productCategories = (state) => state.lookup.productCategories;
export const uoms = (state) => state.lookup.uoms;
export const productStatus = (state) => state.lookup.productStatus;

export const localities = (state) => state.lookup.localities;
export const locations = (state) => state.lookup.locations;

export const jobCategories = (state) => state.lookup.jobCategories;
export const jobStatuses = (state) => state.lookup.jobStatuses;
export const jobTypes = (state) => state.lookup.jobTypes;

export const technicians = (state) => state.lookup.technicians;

export const poStatus = (state) => state.lookup.poStatus;
export const poTypes = (state) => state.lookup.poTypes;

export const custStates = (state) => state.lookup.custStates;
export const custTypes = (state) => state.lookup.custTypes;
export const products = (state) => state.lookup.products;
export const invoiceTypes = (state) => state.lookup.invoiceTypes;
export const paymentModes = (state) => state.lookup.paymentModes;
export const paymentTypes =  (state) => state.lookup.paymentTypes;

export default lookupSlice.reducer;
