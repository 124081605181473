import React, { useState } from "react";
import axios from "axios";
import {
  paymentModes, paymentTypes
} from "../common/lookupSlice";
import _ from "lodash";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CircularProgress,
  FormHelperText,
  makeStyles
} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    marginBottom: '10px'
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  notpayable: {
    color: '#ff0000'
  },
  payable: {
    color: '#008000'
  }
}));

const Payment = (props) => {
  
  const classes = useStyles();
  const token = localStorage.getItem("authToken");
  const { handleSubmit, errors, register } = useForm({});
  const [value, setValue] = React.useState(1);
  const [payment, setPayment] = React.useState(props.isInvEdit&&props.paymentDetails.paymentDetails ? props.paymentDetails.paymentDetails : '');
  const [paymentId, setPaymentId] = React.useState(props.isInvEdit&&props.paymentDetails.paymentId ? props.paymentDetails.paymentId : '');
  const [isLoading, setIsLoading] = useState(false);
  const payTypes = useSelector(paymentModes);
  const chipData = useSelector(paymentTypes);
  const [paymentType, setPaymentType] = useState();
  const[amountPaid,setAmountPaid] = useState(0);
  const[refundAmount,setRefundAmount] = useState(0);

  const handleRadioChange = (event) => {
    const selectedPayment = Number(event.target.value);
    if(props.job){
      props.setPaymentId(((props.calculate.total<=0) || (paymentType && paymentType.id && paymentType.id===3))?null:selectedPayment)
    }
    else{
      setValue(selectedPayment);
    }
  };

  const handleChange = (event, field) => {
    if(props.job){
      if(field==="details"){
      props.setPaymentDetails(event.target.value)
      }
      else if(field==="id"){
      props.setPaymentData(event.target.value)
      }
    }
    else{
      if(field==="details"){
      setPayment(event.target.value)
      }
      else if(field==="id"){
      setPaymentId(event.target.value)
      }
    }
  }

  const handleCancel = () => {
    props.cancel()
  }
  const handleSave = async () => {
    setIsLoading(true)
    if(props.job){
      props.setAmountPaid(amountPaid);
      props.setRefundAmount((props.calculate.total<0 && (paymentType && paymentType.id && paymentType.id===1))?((Math.round(props.calculate.total * 100) / 100).toFixed(2)*-1):refundAmount);
      props.setPaymentType(paymentType);
      ((props.calculate.total<=0) || (paymentType && paymentType.id && paymentType.id===3))&&setPaymentId(null);
      props.generateInvoice();
    }
    else{
    let createEInvQR;
    let invNum;
    try {
      const clonedData = props.calculate ? _.cloneDeep(props.calculate) : {}
      //Below assignments needs to be updated
      const total = clonedData.total;
      const roundOff = total ? (Math.round((Math.round(total)-total) * 100) / 100).toFixed(2):0;
      clonedData.roundOff = Number(roundOff);
      if(props.poNum){
        clonedData.poNum = props.poNum
      }
      clonedData.orgCode= props.customerData.orgCode
      clonedData.customerId=props.customerData.id
      clonedData.custTypeId=props.customerData.custTypeId
      clonedData.locationId=props.store
      clonedData.invoiceDate=moment().format('MM/DD/YYYY')
      const payments = [
        {
          "status": "PAID",
          "paymentModeId": ((props.calculate.total<=0) || (paymentType && paymentType.id && paymentType.id===3))?null:value,
          "paymentId": paymentId,
          "paymentDetails": payment,
          "amount": Math.round(clonedData.total),
          "paymentTypeId": paymentType && paymentType.id,
          "totalAmount": Math.round(clonedData.total),
          "paidAmount": (paymentType && paymentType.id && paymentType.id===1) ? clonedData.total : amountPaid,
          "refundAmount": refundAmount,
        }
      ]
      clonedData.remarkNote = (props.invoiceRemarkNote)?props.invoiceRemarkNote : "";
      clonedData.payments = payments;
      clonedData.isEWayBill = (props.isEWayBill)?true:false;
      clonedData.isBtoB = (props.isBtoB)?true:false;
      clonedData.isEInvoice = (props.isEInvoice)?true:false;
      clonedData.transportDetails =  (props.transportDetails)?props.transportDetails:{};
      clonedData.sellerDetails = (props.sellerDetails)?props.sellerDetails:{};
      clonedData.buyerDetails = (props.buyerDetails)?props.buyerDetails:{};
      clonedData.consigneeDetails = (props.consigneeDetails)?props.consigneeDetails:{};
      clonedData.dispatchDetails = (props.dispatchDetails)?props.dispatchDetails:{};
      clonedData.eWayBillDetails = (props.eWayBillDetails)?props.eWayBillDetails:{};

      //Post or put the req to save changes to db
      const result = props.isInvEdit ? await axios.put(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/${props.saleId}`,
        clonedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ) : await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices`,
        clonedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resStatus = result && result.data && result.data.status;
     
      if(resStatus==="SUCCESS"){
        if(props.saleId && !props.isInvEdit){
          const patchreq =  {status:"COMPLETE"}; 
          const lineItems = clonedData.lineItems && clonedData.lineItems.map(x => {
            const item = {
              productId: x.productId && Number(x.productId),
              qty: x.qty && Number(x.qty),
              productPrice: x.unitPrice ? (Math.round(x.unitPrice * 100) / 100).toFixed(2) : '0.00',
              overridenDiscountPcnt: (x.discountPct>0) ? (Math.round(x.discountPct * 100) / 100).toFixed(2): '0.00'
            }
            return item;
          });
          patchreq.lineItems = lineItems;
          await axios.put(
            `${process.env.REACT_APP_SERVICE_URL}/draftsale/${props.saleId}`,
            patchreq,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ); 
        }
        invNum = result && result.data && result.data.invoiceNum;
        let isWar = false;
        if(result.data && result.data.invoice && result.data.invoice.typeId && (result.data.invoice.typeId === 1) && result.data.invoice.isEInvoice){
          createEInvQR = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/qrcode/${invNum}`,{invNum},
            {
              headers: {
                Authorization: `Bearer ${token}`
              },
            }
          );
          if(result.data.invoiceNum){
            if(createEInvQR&& createEInvQR.data && createEInvQR.data.status==="SUCCESS"){
              if(props.isEWayBill){
                isWar = (createEInvQR.data.resultEInv && createEInvQR.data.resultEInv.EwbNo)?false:true;
              }
              props.setMessage(createEInvQR && createEInvQR.data && createEInvQR.data.message)
              setIsLoading(false)
            }else{
              isWar = true;
              props.setMessage((createEInvQR&& createEInvQR.data && createEInvQR.data.message)?createEInvQR.data.message:"Error form ASP port unble to generate eInvoice/EWB")
              setIsLoading(false)
            }
          }else{
            isWar = true;
            props.setMessage(result && result.data && result.data.message)
            setIsLoading(false)
          }
        }else{
          isWar = false;
          props.setMessage(result && result.data && result.data.message)
          setIsLoading(false)
        }
        props.setInvoiceNo(result && result.data && result.data.invoiceNum);
        if(isWar){
          props.setIsSaleWarning(true);
          props.setIsSaleMsgWarning(true)
        }else{
          props.setIsSuccess(true)
        }
        props.setIsSaved(true)
        props.cancel();
    }
    else{
      setIsLoading(false)
      props.setMessage(result && result.data && result.data.message)
      props.setIsError(true)
      props.cancel()
    }
    }
    catch (err) {
      setIsLoading(false)
      props.setIsError(true)
      props.cancel()
    }
  }
  }

  const rateValidation = (val)=>{
    const valid = (props.calculate.total && props.calculate.total<0) ? (Number(val) > 0 && Number(val) < ((props.calculate.total && (Math.round(props.calculate.total * 100) / 100).toFixed(2)*-1))) : (Number(val) > 0 && Number(val) < (props.calculate.total && (Math.round(props.calculate.total * 100) / 100).toFixed(2)));
    return valid;
  }

  const handleChipClick = (data) => {
    (data.code==="CREDIT")?setValue(null):setValue(1);
    setPaymentType(data);
    if(props.job){
    props.setPaymentType(data);}
  }

  return (
    <div style={{marginTop:'-10px'}}>
      <Grid item md={12} xs={12}>
      <div component="ul" className={classes.root}>
          {chipData.map((data) => {
        return (
          <li key={data.id}>
            <Chip label={data.description} className={classes.chip} onClick={()=> handleChipClick(data)} icon={paymentType && paymentType.description && data.description===paymentType.description&&<DoneIcon style={{color:'#FFFFFF'}}/>} style={{color:'#FFFFFF',background: data.id===1 ? '#6CC17E': data.id===2 ? '#618976' : '#2B5E43'}} />
          </li>
        );
      })}
      </div>
        <Typography>Payment Mode</Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1" value={props.job ? props.paymentId: value} onChange={handleRadioChange}>
          <Grid container md={12} xs={12}>
            {payTypes.map(x => {
              return <Grid item xs={6}><FormControlLabel style={{ color: 'red8' }} value={!((props.calculate.total<=0) || (paymentType && paymentType.id && paymentType.id===3))&&x.id} control={<Radio disabled={paymentType ? ((((props.calculate.total<=0)) ||(paymentType.id && paymentType.id===3)) ? true:false) :true} />} label={x.description} /></Grid>
            })}
          </Grid>  
            <Box ml={4}>
              <Typography variant="caption">Payment ID (Transaction ID/Cheque No/Approval Code)</Typography>
              <TextField
                error={Boolean(errors.id)}
                variant="outlined"
                fullWidth
                size="small"
                name="id" disabled={paymentType ? ((((props.calculate.total<=0)) ||(paymentType.id && paymentType.id===3)) ? true:false) :true}
                onChange={(e) => handleChange(e, "id")}
                inputRef={register({
                  required: value &&(value !==6 && props.paymentId !==6) && (value !==1 && props.paymentId !==1) && "Enter Payment id" ,
                })}
              />
              <FormHelperText error>
              {errors.id && errors.id.message}
              </FormHelperText>
            </Box>
            <Box ml={4}>
              <Typography variant="caption">Payment Details (Bank & Branch Name,Payee Details,Issuing Bank/Card Type)</Typography>
              <TextField
                error={Boolean(errors.details)}
                variant="outlined"
                fullWidth
                size="small"
                name="details" disabled={paymentType ? ((((props.calculate.total<=0)) ||(paymentType.id && paymentType.id===3)) ? true:false) :true}
                inputRef={register({
                  required: value && (value !==1 && props.paymentId !==1) && "Enter Payment details",
                })}
                onChange={(e) => handleChange(e, "details")}
              />
            <FormHelperText error>
              {errors.details && errors.details.message}
            </FormHelperText>
            </Box>
            <Box  ml={4}>
              <Typography>Total Amount</Typography>
              <TextField 
                variant="outlined"
                fullWidth
                size="small" value={props.calculate && props.calculate.total ? (Math.round(props.calculate.total * 100) / 100).toFixed(2):0}
                name="amount" disabled
              />
            </Box>
            {(props.job && (!(props.calculate) || (props.calculate && props.calculate.total && props.calculate.total)) === 0)?
              <Box  ml={4}>
              <Typography>Amount Paid </Typography>
              <TextField inputProps={{className: classes.notpayable}}
                variant="outlined"
                fullWidth
                size="small" value={0}
                name="zeroAmountPaid" disabled
              />
            </Box>:(props.calculate && props.calculate.total && props.calculate.total < 0)?
            <Box  ml={4}>
            <Typography>Amount Refund </Typography>
            <TextField inputProps={{className: classes.notpayable}}
              error={Boolean(errors.refundAmount)}
              variant="outlined"
              fullWidth
              size="small" value={paymentType ? (paymentType.id && paymentType.id===1 ? props.calculate.total && ((Math.round(props.calculate.total * 100) / 100).toFixed(2)*-1):paymentType.id===3 ? 0 : refundAmount):refundAmount}
              name="refundAmount" disabled={paymentType ? (paymentType.id && paymentType.id!==2 ? true:false) : true}
              inputRef={register({
                required: (paymentType && paymentType.id && paymentType.id===2) && "Refund is required",
                pattern: {
                  value:/^(?:\d*\.\d{1,2}|\d+)$/,
                  message: "Provide a valid Refund",
                },
                validate: (paymentType && paymentType.id && paymentType.id===2) && ((value)=> rateValidation(value) || 'Refund should be greater than 0 and less than the Total Amount')
              })}
              onChange={(e) => {setRefundAmount(e.target.value); (props.job)&&props.setRefundAmount(e.target.value);}}
            />
          <FormHelperText error>
            {errors.refundAmount && errors.refundAmount.message}
          </FormHelperText>
          </Box>: <Box  ml={4}>
              <Typography>Amount Paid </Typography>
              <TextField inputProps={{className: classes.payable}}
                error={Boolean(errors.amountPaid)}
                variant="outlined"
                fullWidth
                size="small" value={paymentType ? (paymentType.id && paymentType.id===1 ? props.calculate.total && (Math.round(props.calculate.total * 100) / 100).toFixed(2):paymentType.id===3 ? 0 : amountPaid):amountPaid}
                name="amountPaid" disabled={paymentType ? (paymentType.id && paymentType.id!==2 ? true:false) :true}
                inputRef={register({
                  required: (paymentType && paymentType.id && paymentType.id===2) && "Amount is required",
                  pattern: {
                    value:/^(?:\d*\.\d{1,2}|\d+)$/,
                    message: "Provide a valid Amount",
                  },
                  validate: (paymentType && paymentType.id && paymentType.id===2) && ((value)=> rateValidation(value) || 'Amount should be greater than 0 and less than Total Amount')
                })}
                onChange={(e) => {setAmountPaid(e.target.value); (props.job)&&props.setAmountPaid(e.target.value);}}
              />
            <FormHelperText error>
              {errors.amountPaid && errors.amountPaid.message}
            </FormHelperText>
            </Box>}
          </RadioGroup>
        </FormControl>

        <Grid
          item
          container
          xs={12}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
         {isLoading&&<Grid item xs={12}>{props.isEInvoice && "Generating E-Invoice may take time kindly do not refresh the page"}</Grid>}
          <Grid item xs={12}>
            <Box mt={4} display="flex" justifyContent="flex-end">
              <Box mr={2}>
                {isLoading && <CircularProgress />}
              </Box>
              <Box mr={2}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary" disabled={!paymentType || isLoading}
                  onClick={handleSubmit(handleSave)}
                >
                  {props.isInvEdit?(props.isEInvoice)?"Update e-Invoice":"Update Invoice":(props.isEInvoice)?"Generate e-Invoice":"Generate Invoice"} 
            </Button></Box>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary" disabled={isLoading}
                  onClick={handleCancel}
                >
                  Cancel
            </Button></Box>
            </Box>
          </Grid>
        </Grid>
      </ Grid>
    </div>
  );
};

export default Payment;
