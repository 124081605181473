import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { API_AUTHENTICATE, API_CURRENT_USER } from "../../config/constants";
import axios from "axios";

import { setCurrentUser } from "./loginSlice";

import logo from "../../images/logo.png";

import {
  Paper,
  Typography,
  Grid,
  TextField,
  Box,
  Container,
  Button,
  InputAdornment,
  CircularProgress,
  Link,
  IconButton
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import "./Login.css";
const Login = (props) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [passwordShow, handleClickShowPassword] = useState(false);

  useEffect(() => {
    setErrorMsg("");
    setShowSpinner(false);
  }, [userName, password]);

  const isLoginDisabled = () => {
    if (userName.length < 5 || password.length < 8 || showSpinner) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setShowSpinner(true);
    await axios
      .post(`${process.env.REACT_APP_SERVICE_URL}/${API_AUTHENTICATE}`, {
        userName: userName,
        password: password,
      })
      .then((res) => {
        const token = res.data.authToken;
        axios
          .get(`${process.env.REACT_APP_SERVICE_URL}/${API_CURRENT_USER}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            localStorage.setItem("authToken", token);
            localStorage.setItem("qrCodeStatus", res.data.user.org.qrCodeStatus);
            dispatch(setCurrentUser(res.data.user));
          })
          .catch((err) => {
            if (err.response && err.response.data) {
              setErrorMsg(err.response.data.message);
            } else {
              setErrorMsg("Cannot process request. Please try again.");
            }
          });
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrorMsg(err.response.data.message);
        } else {
          setErrorMsg("Cannot process request. Please try again.");
        }
      });
    setShowSpinner(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm">
        <Paper elevation={10}>
          <Grid container direction="column">
            <Grid item xs container>
              <Grid item xs={12}>
                <Box
                  fontWeight="fontWeightBold"
                  bgcolor="primary.main"
                  color="primary.contrastText"
                >
                  <Typography className="header">LOGIN</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              className="grid-container"
              container
              spacing={2}
              direction="row"
              justify="center"
            >
              <Grid item sm container justify="center" alignItems="center">
                <Grid item>
                  <img src={logo} alt="logo" />
                </Grid>
              </Grid>
              <Grid
                item
                sm
                container
                direction="column"
                justify="center"
                spacing={5}
              >
                <Grid item container spacing={2} justify="center">
                  <Box margin="20px 0px 0px 0px">
                    <Grid item>
                      <Typography component="span" color="primary">
                        <Box fontWeight="fontWeightBold">USER NAME</Box>
                      </Typography>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        InputProps={{
                          name: "userName",
                          autoFocus: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography component="span" color="primary">
                        <Box fontWeight="fontWeightBold">PASSWORD</Box>
                      </Typography>
                      <TextField
                        type={passwordShow ? 'text' : 'password'}
                        variant="outlined"
                        size="small"
                        fullWidth
                        password={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                          name: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={(e)=>{handleClickShowPassword(!passwordShow)}}
                                edge="end"
                              >
                                {passwordShow ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Link color="secondary" href="/resetpassword" style={{fontSize:"12px"}}>
                        Forgot password? If you are an Admin, you can Reset your password.
                      </Link>
                    </Grid>
                    <Grid item style={{marginTop:"10px"}}>
                      <Button
                        type="submit"
                        disabled={isLoginDisabled()}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        LOG IN
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
               
              </Grid>
            </Grid>
            <Grid item xs container>
              <Grid container justify="center">
                <Grid item>
                  <Typography variant="caption" color="error">
                    {errorMsg ? (
                      errorMsg
                    ) : showSpinner ? (
                      <CircularProgress />
                    ) : (
                      <div></div>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </form>
  );
};

export default Login;
