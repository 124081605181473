import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Container } from "@material-ui/core";

import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NewLogin from "./components/login/NewLogin";
import ResetPassowrd from "./components/login/ResetPassowrd";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import POSDashboard from "./components/dashboard/POSDashboard";
import StatusDashboard from "./components/dashboard/StatusDashboard";
import UnAuthorized from "./components/common/UnAuthorized";

import Users from "./components/users/Users";
import Customer from "./components/customers/Customer";
import CustomersForm from "./components/customers/CustomersForm";
import CustomerHistory from "./components/customers/CustomerHistory";
import CustomerDraft from "./components/customers/CustomerDrafts";
import CustomerPurchaseOrder from "./components/customers/customerOrders/PurchaseOrder";
import CustomersDiscountForm from "./components/customers/customerDiscount/CustomerDiscount";
import Inventory from "./components/inventory/Inventory";
import InventoryMovement from "./components/inventory/InventoryMovement";
import InventoryLocation from "./components/inventory/InventoryLocation";
import InventoryProduct from "./components/inventory/InventoryProduct";
import POSProductInventory from './components/pos/POSProductInventory';
import ProductGroups from "./components/productGroups/ProductGroups";
import ManageProducts from "./components/productGroups/ManageProducts";
import ManageDiscounts from "./components/productGroups/ManageDiscounts";
import JobCard from "./components/jobs/JobCard";
import JobList from "./components/jobs/JobList";
import ManageProdGroups from "./components/products/manageProducts/ManageProducts";
import ManageProdPriceForm from "./components/products/manageProducts/ManagePrice";
import Reports from "./components/reports/Reports";
import ProductPo from "./components/reports/productPo";
import CustomerOrders from "./components/reports/customerOrders";
import RecordSalesNew from "./components/RecordSaleNew/recordSale";
import CustomerSales from "./components/reports/customerSales";
import UnsyncCustomerSales from "./components/reports/UnsyncCustomerSales";
import EInvAndEWBErrorReport from "./components/reports/eInvAndEWBErrorReport";
import CancelledInvoiceReport from "./components/reports/CancelledInvoiceReport";
import InvoicePos from "./components/reports/customerSalesPos";
import DailySync from "./components/reports/dailySync";
import ReplenishmentInsight from "./components/reports/replenishmentInsight";
import PriceReport from "./components/reports/missingPriceReport";
import Products from "./components/products/Products";
import POSCustomersForm from "./components/pos/POSCustomer";
import DailyJobSyncDashboard from "./components/dashboard/DailyJobSyncDashboard";
import BatchJobHistory from "./components/dashboard/BatchJobHistory";
import { currentUser } from "./components/login/loginSlice";
import {
  fetchRoles,
  fetchLocalities,
  fetchProductLookup,
  fetchProductGroupTypesLookup,
  fetchProductGroupsLookup,
  fetchJobLookup,
  fetchAllTechnicians,
  fetchPOStatusAndTypes,
  fetchCustStatesAndTypes,
  fetchLocations,
  fetchProducts,
  fetchInvoiceLookup
} from "./components/common/lookupSlice";

import InventoryMovementReport from "./components/reports/inventoryMovementReport";
import CustomerPreviousSales from "./components/reports/customerPreviousSales";
import ProductStatusReport from "./components/reports/ProductStatus";
import "./App.css";

const App = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(currentUser);
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAdmin =
    roles.findIndex((role) => role.name === "ADMIN") > -1 ? true : false;
  const isServiceAdvisor =
    roles.findIndex((role) => role.name === "SERVICE ADVISOR") > -1
      ? true
      : false;
  const isTechnician =
    roles.findIndex((role) => role.name === "TECHNICIAN") > -1 ? true : false;
  const isBillingAgent =
    roles.findIndex((role) => role.name === "BILLING AGENT") > -1 ? true : false;

  useEffect(() => {
    if (loggedInUser) {
      dispatch(fetchRoles());
      dispatch(fetchLocalities());
      dispatch(fetchLocations());
      dispatch(fetchProductLookup());
      dispatch(fetchProductGroupTypesLookup());
      if(isAdmin || isServiceAdvisor){
      dispatch(fetchProductGroupsLookup());
      }
      dispatch(fetchJobLookup());
      if(isAdmin || isServiceAdvisor ){
      dispatch(fetchAllTechnicians());
      }
      dispatch(fetchPOStatusAndTypes());
      dispatch(fetchCustStatesAndTypes());
      dispatch(fetchProducts());
      dispatch(fetchInvoiceLookup());
    }
  }, [loggedInUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return !loggedInUser ? (
    <div>     
      <Router>
        <Container>
          <div className="login-content">
            <Switch>
              <Route
                path={["/"]}
                exact
                component={NewLogin}
              />
              <Route path="/resetpassword" exact component={ResetPassowrd}/>
            </Switch>     
          </div>
        </Container>
      </Router>
      <Footer />
    </div>
  ) : (
    <div>
      <Router>
        <Header loggedInUser={loggedInUser} />
        <Container>
          <div className="body-content">
            {isAdmin ? (
              <Switch>
                <Route
                  path={["/", "/dashboard", "/home"]}
                  exact
                  component={AdminDashboard}
                />
                <Route path="/pos" exact component={POSDashboard}/>
                <Route path="/users" exact component={Users} />
                <Route path="/customers" exact component={Customer} />
                <Route path="/products" exact component={Products} />
                <Route path="/inventory" exact component={Inventory} />
                <Route path="/inventoryLocation" exact component={InventoryLocation} />
                <Route path="/inventoryProduct" exact component={InventoryProduct} />
                <Route path="/posProdInventory" exact component={POSProductInventory} />
                <Route path="/inventoryMovement" exact component={InventoryMovement} />
                <Route path="/reports" exact component={Reports} />
                <Route path="/productPurchaseOrders" exact component={ProductPo} />
                <Route path="/customerOrders" exact component={CustomerOrders} />
                <Route path="/customerSales" exact component={CustomerSales} />
                <Route path="/replenishmentInsight" exact component={ReplenishmentInsight} />
                <Route path="/priceReport" exact component={PriceReport} />
                <Route path="/inventorySync" exact component={DailySync} />
                <Route path="/invMovementReport" exact component={InventoryMovementReport} />
                <Route path="/unSyncCustSales" exact component={UnsyncCustomerSales} />
                <Route path="/eInvEWBErrorReport" exact component={EInvAndEWBErrorReport} />
                <Route path="/cancelledInvReport" exact component={CancelledInvoiceReport} />
                <Route path="/productGroups" exact component={ProductGroups} />
                <Route path="/manageProducts" exact component={ManageProducts} />
                <Route path="/recordSales/:id" exact component={RecordSalesNew} />
                <Route path="/recordSales" exact component={RecordSalesNew} />
                <Route path="/manageDiscounts" exact component={ManageDiscounts} />
                <Route path="/customerForm/:id" exact component={CustomersForm} />
                <Route path="/customerDiscount/:id" exact component={CustomersDiscountForm} />
                <Route path="/customerForm" exact component={CustomersForm} />
                <Route path="/customerHistory/:id" exact component={CustomerHistory} />
                <Route path="/customerOrder/:id" exact component={CustomerPurchaseOrder} />
                <Route path="/manageProdGroups/:id" exact component={ManageProdGroups} />                
                <Route path="/manageProdPriceForm/:id" exact component={ManageProdPriceForm} />
                <Route path="/customerDrafts/:id" exact component={CustomerDraft} />
                <Route
                  path="/jobs/:id"
                  key="viewJob"
                  exact
                  render={(props) => <JobCard {...props} />}
                />
                <Route
                  path="/jobs"
                  key="jobList"
                  exact
                  render={(props) => <JobList {...props} />}
                />
                <Route
                  path="/job"
                  key="unAuthorizedFeature"
                  exact
                  component={UnAuthorized}
                />
                <Route
                  path="/datasync"
                  exact
                  component={DailyJobSyncDashboard}
                />
                <Route
                  path="/jobhistory"
                  exact
                  component={BatchJobHistory}
                />
                 <Route
                  path="/customerprevioussales"
                  exact
                  component={CustomerPreviousSales}
                />
                <Route
                  path="/productstatusreport"
                  exact
                  component={ProductStatusReport}
                />
                <Route key="somethingElse" component={AdminDashboard} />
              </Switch>
            ) : (
              <></>
            )}
            {isServiceAdvisor ? (
              <Switch>
                <Route
                  path={["/", "/dashboard", "/home"]}
                  exact
                  component={StatusDashboard}
                />

                <Route
                  path="/job"
                  key="newJob"
                  exact
                  render={(props) => <JobCard {...props} isNew />}
                />
                <Route
                  path="/jobs/:id"
                  key="editJob"
                  exact
                  render={(props) => <JobCard {...props} />}
                />
                <Route
                  path="/jobs"
                  key="jobList"
                  exact
                  render={(props) => <JobList {...props} />}
                />
                <Route path="/customerForm" exact component={CustomersForm} />
                <Route
                  path={["/users", "/customers", "/products"]}
                  key="unAuthorizedFeature"
                  exact
                  component={UnAuthorized}
                />
                <Route key="somethingElse" component={StatusDashboard} />
              </Switch>
            ) : (
              <></>
            )}
            {isTechnician ? (
              <Switch>
                <Route
                  path="/jobs/:id"
                  key="editJob"
                  exact
                  render={(props) => <JobCard {...props} />}
                />
                <Route
                  path={["/", "/jobs", "/home", "dashboard"]}
                  key="jobList"
                  exact
                  render={(props) => <JobList {...props} />}
                />
                <Route
                  path="/job"
                  key="newJob"
                  exact
                  component={UnAuthorized}
                />

                <Route
                  path={["/users", "/customers", "/products", "/newJob"]}
                  key="unAuthorizedFeature"
                  exact
                  component={UnAuthorized}
                />
                <Route
                  key="somethingElse"
                  render={(props) => <JobList {...props} />}
                />
              </Switch>
            ) : (
              <></>
            )}{
              isBillingAgent ?(
              <Switch>
                <Route
                  path={["/", "/pos", "/home"]}
                  exact
                  component={POSDashboard}
                />
                <Route path="/posProdInventory" exact component={POSProductInventory} />
                <Route path="/invoicePos" exact component={InvoicePos} />
                <Route path="/recordSales/:id" exact component={RecordSalesNew} />
                <Route path="/recordSales" exact component={RecordSalesNew} />
                <Route path="/posCustomerForm" exact component={POSCustomersForm} />
                <Route path="/posCustomerForm/:id" exact component={POSCustomersForm} />
                <Route key="somethingElse" component={POSDashboard} />
              </Switch>):(<></>)
            }
          </div>
        </Container>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
