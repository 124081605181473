import React, { useEffect, useRef, useState } from "react";
import { renderToString } from 'react-dom/server'
import { CircularProgress,Breadcrumbs, Button, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Dialog, DialogTitle, TextField, FormHelperText, Snackbar, IconButton, DialogContent } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { API_ALL_CUSTOMERS, CUSTOMER_HISTORY } from "../../config/constants";
import axios from "axios";
import { formatDate } from '../common/util';
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import CompToPrintInv from '../reports/CompToPrintInv';
import ReactToPrint from "react-to-print";
import {
  invoiceTypes
} from "../common/lookupSlice";
import Pagination from "@material-ui/lab/Pagination";
import moment from 'moment';
import _ from "lodash";import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MailIcon from '@material-ui/icons/Mail';
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { receiverEmailId } from "../../components/common/constants";

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};


class CompToPrintHistory extends React.Component {
  render() {
    return (
      <div>
        <label style={{ textAlign: "left" }}>
          Hello, Here is the list of Customer Sales. For more information, kindly login to the application.
        </label><br></br>
        <div style={divStyle} align="center">
        <table style={{ paddingTop: 10 }} size="small">
            <thead>
              <tr>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Invoice No.</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Customer Name</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Invoice Type</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Invoice Date</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Invoice Total</span></td>
              </tr>
            </thead>
            <tbody>
              {this.props.customerSale.length === 0 ? (
                <tr>
                  <td colspan="9" align="center">
                    <span style={pStyle2}>No records found</span>
                  </td>
                </tr>
              ) : (
                this.props.customerSale.map((row, index) => (
                  <tr
                    id={index}
                  >
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.invoiceNo}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {this.props.customer}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.invoiceType && row.invoiceType[0] && row.invoiceType[0].code}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.invoiceDate && formatDate(row.invoiceDate)}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                      {row.invoiceTotal && (Math.round(row.invoiceTotal * 100) / 100).toFixed(2)}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
      </div>
      </div>
    );
  }
}

function CustomerHistory() {

  const history = useHistory();
  const componentRef = useRef();
  const componentRefInv = useRef();
  const { id } = useParams();
  const token = localStorage.getItem("authToken");
  const invoiceType = useSelector(invoiceTypes);
  const [isLoading, setIsLoading] = useState(false);  
  const [customerInv, setCustomerInv] = useState([]);
  const [data, setData] = useState([]);
  const pageSize = 10;  
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [ customer, setCustomer ] = useState({}); 
  const [count, setCount] = useState();  
  const [refresh, setRefresh] = useState(false);
  const [isSuccess,setIsSuccess]=useState(false);
  const [message, setMessage] = useState('');
  const [isSendInvoice, setSendInvoice] = useState(false);
  const [saleDate, setSaleDate] = useState({startDate:null,endDate:null});
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [ emailSendData, setEmailSendData ] = useState({
    emailId:'',
    emailSubject:''
  });
  let  { emailId, emailSubject} = emailSendData;
  const [customerSale, setCustomerSale] = useState([]);
  const [sendSale, setSendSale] = useState(false);
  const [isError, setIsError] = useState(false);

 const getCustomerInvoice = async (invNo) => {
  const result = await axios.get(
    `${process.env.REACT_APP_SERVICE_URL}/invoices/${invNo}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const sales = result.data && result.data;
  return sales;
}

 const handlePrintInv = async (invNo) => {
  try {
    const data = await getCustomerInvoice(invNo);
    setCustomerInv(data);      
  } catch (err) {
    console.log(err);
      if (err.response && err.response.data) {
        console.log(err.response.data.message);
      } else {
        console.log("Couldn't get invoice data.");
      }
  }
} 

const getCustomerSales = async () => {
  const clonedQuery = _.cloneDeep(query);
  delete clonedQuery.page;
  delete clonedQuery.pageSize;
  const result = await axios.get(
    `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_CUSTOMERS}/${id}/${CUSTOMER_HISTORY}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: query,
    }
  );
  const sales = result.data && result.data.invoices.map(sale => {
    return {
      id: sale.id,
      invoiceNo: sale.invoiceNum && sale.invoiceNum.toString(),
      invoiceType: invoiceType && sale.typeId  && invoiceType.filter(x=>x.id === sale.typeId),
      invoiceDate: sale.invoiceDate,
      invoiceTotal: sale.total
    }
  });
  return sales;
}

const handleSearch = (e) => {
  e.preventDefault();
  setQuery({
    ...query, 
    startDate: saleDate.startDate ? moment(saleDate.startDate).format('DD/MM/YYYY'):"",
    endDate: saleDate.endDate ? moment(saleDate.endDate).format('DD/MM/YYYY'):"",
    page: 1,
  });
};

const handleDateChange = (value,field) => {
  const clonedDate = _.cloneDeep(saleDate);
  clonedDate[field] = value;
  setSaleDate(clonedDate)
};

const handleOpenOrderMail = async () => {
  try {
    const data = await getCustomerSales();
    setCustomerSale(data);
    setSendSale(true);
    setEmailSendData({ emailId: receiverEmailId, emailSubject: 'Vindhya Associates - Invoice' });
  } catch (err) {
    setIsError(true);
  }
}

const onCloseSendInvoice = () =>{
  setSendInvoice(false);
  setSendSale(false);
  setEmailSendData({emailId:'',emailSubject:''})
 }

 const onSendEmailInvoice =async () =>{
  let isEmail = false;
  let isSubject = (emailSubject&&emailSubject.length>1)?true:false;
  if(emailId&&emailId.length>0){
    isEmail = true;
    let emailIds = emailId.split(';');
    const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    _.forEach(emailIds,(data)=>{
      if (!mailformat.test(String(data).toLowerCase())){
        isEmail = false;
      }
    })
  }
  if(isEmail&&isSubject){
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData = "<div></div>"
      if (sendSale) {
        invoiceBodyData = () => {
          return (
            <CompToPrintHistory ref={componentRef} customerSale={customerSale} customer={customer&&customer.fullName}/>
          )
        }
      } else {
        invoiceBodyData = () => {
          return (
            <CompToPrintInv ref={componentRefInv} customerInv={customerInv} />
          )
        }}  
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to',emailId)
      bodyFormData.append('subject',emailSubject)
      bodyFormData.append('invoice',renderToString(invoiceBodyData()))
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
        bodyFormData,
        config
      );
      if(result.status===200){
        setIsSuccess(true)
        setMessage(result.data&&result.data.message)
        setSendInvoice(false);
        setSendSale(false);
        setEmailSendData({emailId:''})
      }
  }else{
    if(!isEmail){
      setEmailHelperText(true);
    }
    if(!isSubject){
      setEmailSubjectHelperText(true);
    }
  }
}


const loadData = async () =>{
  if(id){
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_CUSTOMERS}/${id}/${CUSTOMER_HISTORY}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCustomer(result.data.customer);
      setCount(result.data.count);
      setData(result.data.invoices);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }    
    setRefresh(false);
  }};

  useEffect(()=> {
    loadData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReset = (e) =>{
    window.location.reload();
    // setSaleDate({startDate:null,endDate:null});
    // setQuery({ page: 1, pageSize: pageSize })
  }

  return(
    <div>
      <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Link color="inherit" href="" onClick={() => history.push("/customerForm/" + id)}>
                  Customers
                </Link>
                <Typography component="span" color="textPrimary">
                  Customer Purchase and Service History
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>
        <Grid container >
          <Typography style={{marginTop: '3%'}}>
            Customer Number : {customer&&customer.mobileNumber} | Customer Name: {customer&&customer.fullName}
          </Typography>
        </Grid>
        <div style={{paddingTop:10}}>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Start Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    name="startDate"
                    value={saleDate.startDate}
                    onChange={(value)=>handleDateChange(value,"startDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item container md= {2} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">End Date</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    helperText=""
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    name="endDate"
                    value={saleDate.endDate}
                    onChange={(value)=>handleDateChange(value,"endDate")}
                    minDateMessage=''
                    maxDate="9999-12-31"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item>
            <Box mt={2}>
            <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Generate Report
            </Button>
            </Box>
            </Grid>
            <Grid item container md={2} xs={6}>
                <Box mt={2} mr={2}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<MailIcon />}
                    onClick={handleOpenOrderMail}
                  >
                    Mail
                  </Button>
                </Box>
                <Box mt={2}>
                <Button size="small"
                  onClick={handleReset} variant="contained" color="secondary">
                  clear</Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
        <div style={{ paddingTop: 40, paddingBottom: 40 }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left">Invoice</StyledHeaderCell>
                <StyledHeaderCell align="left">Invoice Type</StyledHeaderCell>
                <StyledHeaderCell align="left">Sales Date</StyledHeaderCell>
                <StyledHeaderCell align="left">Total</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ):
            <TableBody>
            {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) :    
                  data.map((inputField, index) =>{
             let type = invoiceType && inputField.typeId  ? invoiceType.filter(x=>x.id === inputField.typeId):[];
            return (<TableRow key={index}>
            
            <ReactToPrint onBeforeGetContent={()=>handlePrintInv(inputField.invoiceNum)}
              trigger={() =>  <TableCell align="left">
                <Link>{inputField.invoiceNum}</Link>
              </TableCell>} content={() => componentRefInv.current}/>
            <TableCell align="left">{type[0] && type[0].code}
            </TableCell>
            <TableCell align="left">{inputField.invoiceDate && formatDate(inputField.invoiceDate)}
            </TableCell>
            <TableCell align="right">{inputField.total && (Math.round(inputField.total * 100) / 100).toFixed(2)}
            </TableCell>
          </TableRow>
        )}) }
        </TableBody>}
        </Table></TableContainer></div>
        {data && count > pageSize ? (
          <Grid container justify="flex-end">
            <Grid item>
              <Pagination
                style={{ paddingTop: 15 }}
                count={
                  Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
                }
                color="secondary"
                variant="outlined"
                defaultPage={1}
                page={query.page}
                onChange={(event, value) => {
                  setQuery({ ...query, page: value });
                }}
                size="medium"
              />
            </Grid>
          </Grid>
        ) : (
            <div />
          )}
        <Box display="none">
          <CompToPrintInv ref={componentRefInv} customerInv={customerInv} />
        </Box>
        <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice || sendSale}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                {sendSale ? "Mail Open Order" : "Mail Invoice"}
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                    onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{height:'180px'}}>
            <Grid container>
               <Grid xs={3}>
                <Typography className='recordSaleInvoiceLabel' style={{fontWeight:"bold"}} component="span" >Mail to *</Typography>
               </Grid>
               <Grid item md={9}>
                <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                value={emailId}
                onChange = {e => setEmailSendData({ ...emailSendData, emailId: e.target.value})}
                />
                {isEmailHelpertext&&<FormHelperText style={{color:'red'}}>
                  Enter valid email id
                </FormHelperText>}
              </Grid>
              </Grid>
              <Grid container style={{paddingTop:'10px'}}>
               <Grid xs={3}>
                <Typography className='recordSaleInvoiceLabel' style={{fontWeight:"bold"}} component="span">Subject *</Typography>
               </Grid>
               <Grid item md={9}>
                <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                value={emailSubject}
                onChange = {e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value})}
                />
                {isEmailSubjectHelpertext&&<FormHelperText style={{color:'red'}}>
                  Enter email subject
                </FormHelperText>}
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                onClick={onSendEmailInvoice}
                color="secondary"
                size="small"
                variant="contained"
              >
                Send
              </Button>
            </Box>    
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setIsSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message ? message : "Thanks for shopping!"}
        </MuiAlert>
      </Snackbar>
    </div>
  )
}

export default CustomerHistory;