import React from "react";
import { Helmet } from "react-helmet";
import { withStyles } from '@material-ui/styles';
import moment from "moment";

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '1.6',
};


const tableStyle = {
  border: '1px solid #ccc',
  borderCollapse: 'collapse',
}


class CompToPrintJob extends React.Component {
  render() {
    const { classes } = this.props;
    const jobDetails = this.props.job;
    const renderAddress = () => {
      if (!jobDetails.customer) return "";
      return (
        jobDetails.customer.fullName +
        "\n" +
        (jobDetails.customer.address1 ? jobDetails.customer.address1 : "-") +
        "\n" +
        (jobDetails.customer.address2 ? jobDetails.customer.address2 : "-") +
        "\n" +
        (jobDetails.customer.address3 ? jobDetails.customer.address3 : "-")
      );
    };
    return (
      <div>
        <div align="center">
          <img alt="compLogo" src="https://res.cloudinary.com/dynamixe-digital-solution/image/upload/v1645520576/FSM/VACompanyLogo_sxuljv.png"
            style={{ height: "90px" }} />
        </div>
        <div style={divStyle} align="center">
          <Helmet><title></title></Helmet>
          <table style={tableStyle} width="95%" color="#ccc">
            <tr >
              {/* <td>
                &nbsp;&nbsp;<img style={{ height: "60px", width: "60px", filter: "grayscale(100%)", WebkitFilter: "grayscale(100%)" }} src={FsmLogo} alt="logo" />
              </td> */}
              <td>
                <div>
                 <div style={{display:"inline-block"}}>
                    <b style={{ fontSize: "24px", fontFamily: 'Arial, sans-serif' }}><u>{jobDetails && jobDetails.org && jobDetails.org.name.toUpperCase()
                      ? jobDetails.org.name.toUpperCase() :
                      "VINDHYA ASSOCIATES"}</u></b>
                  </div>
                  <div style={{display:"inline-block",textAlign:"right",flex:"end",width:"60%"}}>
                    <span style={{textAlign:"right"}}><b>JOB CARD<br /></b> </span>
                  </div>
                </div>
                <span style={pStyle} >
                  {jobDetails.location && jobDetails.location.address1.toUpperCase()}
                  <br />
                  {jobDetails.location && jobDetails.location.address2.toUpperCase()}
                  <br />
                  {jobDetails.location && jobDetails.location.address3.toUpperCase()}{jobDetails.location && jobDetails.location.pincode ? "-" : ""}{jobDetails.location && jobDetails.location.pincode}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Mob: {jobDetails.location && jobDetails.location.mobileNumber}
                </span>
              </td>
            </tr>
          </table>

          <table style={tableStyle} width="95%" border="1px" color="#ccc">
            <tbody>
              <tr style={{ fontFamily: 'Arial, sans-serif' }}>
                <td width="33%"><span><b>Job No.:</b> {jobDetails && "JC-"+jobDetails.id}</span></td>
                <td width="33%"><span><b>Date:</b>{jobDetails && jobDetails.createdOn}</span></td>
                <td width="34%"><span><b>Invoice No.:</b> {jobDetails && jobDetails.invoiceNo}</span></td>
              </tr>
            </tbody>
          </table>

          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody style={{ fontFamily: 'Arial, sans-serif' }}>
                <tr >
                  <td width="60%" align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span ><b>Customer Name / Address:</b>
                      <br />
                      {renderAddress()}</span>
                    <br /><br />
                    <span ><b>Phone No.:</b>
                      <br />
                      {jobDetails && jobDetails.customer && jobDetails.customer.mobileNumber}</span>
                  </td>
                  <td width="40%" align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span ><b>Status of the machine</b></span>
                    <br />
                    <span >Repair on charges:<b>{jobDetails && jobDetails.type && jobDetails.type.code === "REPAIR" ? <span>&#10003;</span> : ""}</b></span>
                    <br />
                    <span >Estimate:<b>{jobDetails && jobDetails.type && jobDetails.type.code === "EST" ? <span>&#10003;</span> : ""}</b></span>
                    <br />
                    <span >Warranty:<b>{jobDetails && jobDetails.type && jobDetails.type.code === "WARRANTY" ? <span>&#10003;</span> : ""}</b></span>
                    <br />
                    <span >Service:<b>{jobDetails && jobDetails.type && jobDetails.type.code === "SERVICE" ? <span>&#10003;</span> : ""}</b></span>
                  </td>
                </tr>
                </tbody>
            </table>
          </div>
                
          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody style={{ fontFamily: 'Arial, sans-serif' }}>
                <tr>
                  <td width="33.33%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span><b>Machine Name</b>
                      <br />
                      {jobDetails && jobDetails.product && jobDetails.product.productName}&nbsp;</span>
                  </td>
                  <td width="33.33%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span><b>Machine Type</b>
                      <br />
                      {jobDetails && jobDetails.product && jobDetails.product.type && jobDetails.product.type.description}&nbsp;</span>
                  </td>
                  <td width="33.34%" align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span><b>Manufacturer Code</b>
                      <br />
                      {jobDetails && jobDetails.mfgName}&nbsp;</span>
                  </td>
                </tr>
                <tr>
                  <td width="33.33%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span><b>Model</b>
                      <br />
                      {jobDetails && jobDetails.model}&nbsp;</span>
                  </td>
                  <td width="33.33%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span><b>Category</b>
                      <br />
                      {jobDetails && jobDetails.product && jobDetails.product.category && jobDetails.product.category.description}&nbsp;</span>
                  </td>
                  <td width="33.34%" align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span><b>Serial Number</b>
                      <br />
                      {jobDetails && jobDetails.serialNumber}&nbsp;</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody style={{ fontFamily: 'Arial, sans-serif' }}>
                <tr>
                  <td width="20%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p><b>Total Inspection Report</b></p>
                  </td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p><b>&#10003;</b></p>
                  </td>
                  <td rowSpan="3" valign="top" width="60%" align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p><b>Parts given along with the machine</b>
                      <br />
                      {jobDetails && jobDetails.partsGivenAlong}</p>
                  </td>
                </tr>
                <tr>
                  <td width="20%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p>Intact</p>
                  </td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p>{jobDetails && jobDetails.inspectCondition === "INTACT" ? <b>&#10003;</b> : ""}</p>
                  </td>
                </tr>
                <tr>
                  <td width="20%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p>Tampered</p>
                  </td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p>{jobDetails && jobDetails.inspectCondition === "TAMPERED" ? <b>&#10003;</b> : ""}</p>
                  </td>
                </tr>
                <tr>
                  <td width="20%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p>Damaged</p>
                  </td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p>{jobDetails && jobDetails.inspectCondition === "DAMAGED" ? <b>&#10003;</b> : ""}</p>
                  </td>
                  <td width="60%" valign="top" rowSpan="3" align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p><b>Problems in the machine</b>
                      <br />
                      {jobDetails && jobDetails.problems}</p>
                  </td>
                </tr>
                <tr >
                  <td width="20%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <p>Dismantled</p>
                  </td>
                  <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                    <p>{jobDetails && jobDetails.inspectCondition === "DISMANTLED" ? <b>&#10003;</b> : ""}</p>
                  </td>
                </tr>
                <tr >
                  <td width="40%" align="left" valign="top" style={{ borderTop: '1px solid #ccc' }}>
                    <p><b>Expected Delivery Date:</b>
                      {jobDetails && jobDetails.expectedDeliveryDate && moment(jobDetails.expectedDeliveryDate).format("DD/MM/YYYY")}
                      <br /><span style={{ fontSize: "13px" }}>Subject to availability of spares</span></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>


          <table style={tableStyle} width="95%" border="1px" color="#ccc">
            <tr >
              <td style={{ fontFamily: 'Arial, sans-serif' }}>
                <span style={{ margin: "auto", display: "table", textDecoration: "underline", fontSize: "16px" }} ><b>Terms and Conditions<br /></b></span>
                <ol type="1" style={{ fontSize: "13px" }}>
                  <li>Full amount to be paid before the delivery of the machine/tool.</li>
                  <li>While we endeavor to deliver the machine/tool as per the scheduled date, we may postpone the delivery date due to the circumstances beyond our control.</li>
                  <li>Estimated cost may vary depending upon the additional spare part(s) and the service required for carrying out the repair.</li>
                  <li>After the approval of estimate and our confirmation of repair, the machine tool must be collected with in a week.</li>
                  <li>If the machine is not collected with in 30 days after the confirmation of the repair, the machine will be disposed off.</li>
                  <li>Defects due to normal wear and tear,overloading, mishandling, wrong application, tampering, unauthorized servicing and usage of wrong worn out accessories does not come under purview of warranty.</li>
                  <li>Machine will be delivered only on producing of the customer copy of the job Card</li>
                  <li>Any disputes shall be in the courts of Mangaluru jurisdiction.</li>
                  <li>If the bill amount is not paid in the stipulated period of one month, interest amount at 15% shall be charged.</li>
                  <li>Charge will be applicable for dismantling the machine and giving the estimate, the charges will be different for different kinds of machines.</li>
                </ol>
              </td>
            </tr>
          </table>

          <div className={classes.printTogether}>
            <table style={tableStyle} width="95%">
              <tbody>
                <tr>
                  <td width="50%" align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle}><span style={{ fontSize: "20px" }}>Machine is received</span>
                      <br /><br /><br />Signature
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span>Date:</span></span>
                  </td>
                  <td width="50%" align="center" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                    <span style={pStyle}><i>I accept the Terms & Conditions mentioned above</i><br /><br /><br />
                      <i>Signature</i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(CompToPrintJob);