import React, { useState, useEffect, useRef } from "react";
import { renderToString } from 'react-dom/server'
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { jsonToCsv,downloadCSVFile } from '../common/util';
import {
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Snackbar,
  FormControl,
  Button,
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  FormHelperText
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";

import {
  productGroups,
  locations
} from "../common/lookupSlice";

import { API_INVENTORY } from "../../config/constants";
import _ from "lodash";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useForm, Controller } from "react-hook-form";
import MailIcon from '@material-ui/icons/Mail';
import CloseIcon from "@material-ui/icons/Close";
import { receiverEmailId } from "../../components/common/constants";

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};


class CompToPrintStock extends React.Component {
  render() {
    return (
      <div>
        <label style={{ textAlign: "left" }}>
          Hello, Here is the list of Inventories. For more information, kindly login to the application.
        </label><br></br>
        <div style={divStyle} align="center">
        <table style={{ paddingTop: 10 }} size="small">
            <thead>
              <tr>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Product Code</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Product Name</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Safety Stock</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Location</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Count</span></td>
                <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>UOM</span></td>
              </tr>
            </thead>
            <tbody>
              {this.props.inventoryStock.length === 0 ? (
                <tr>
                  <td colspan="9" align="center">
                    <span style={pStyle2}>No records found</span>
                  </td>
                </tr>
              ) : (
                this.props.inventoryStock.map((row, index) => (
                  <tr
                    id={index}
                  >
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.ProductCode}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.ProductName}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.safetyStock}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.Location}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                        {row.Count}
                      </span>
                    </td>
                    <td align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle2}>
                      {row.UOM}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
      </div>
      </div>
    );
  }
}

const Inventory = (props) => {
  const {  control  } = useForm({});
  const prodGrps = useSelector(productGroups);
  const inventoryLocations = useSelector(locations);
  const pageSize = 10;
  const history = useHistory();

  const componentRef = useRef();
  const [count, setCount] = useState();
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [oss, setOss] = useState(false);
  const [group, setGroup] = useState('');
  const [location, setLocation] = useState('');
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [ emailSendData, setEmailSendData ] = useState({
    emailId:'',
    emailSubject:''
  });
  let  { emailId, emailSubject} = emailSendData;
  const [stockInv, setStockInv] = useState([]);
  const [sendStockInv, setSendStockInv] = useState(false);
  const token = localStorage.getItem("authToken");
  const [isSuccess,setIsSuccess]=useState(false);
  const [message, setMessage] = useState('');

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_INVENTORY}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setCount(result.data.count);
      setData(result.data.rows);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
    setRefresh(false);
  };

  const handleOss = () =>{
    setQuery({
      ...query,
      OOSCheck: !oss ? true:undefined,
      page: 1,
    });
    setOss(!oss)
  }
  useEffect(() => {
    loadData();
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      loadData();
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery({
      ...query,
      productName: e.target.elements.itemName.value,
      productCode: e.target.elements.itemCode.value,
      locationId: location,
      prdGrpId: group,
      page: 1,
    });
  };
  
  const handleLocationClick = (id) =>{
    history.push({
      pathname: '/inventoryLocation',
      state: { id: id }
  })
  }

  const resetQuery = () => {
    setQuery({
      ...query,
      productName: "",
      productCode: "",
      locationId: "",
      prdGrpId: "",
      page: 1,
    });
  };

  const handleOpenOrderMail = async () => {
    try {
      const data = await getStockInventory();
      setStockInv(data);
      setSendStockInv(true);
      setEmailSendData({ emailId: receiverEmailId, emailSubject: 'Vindhya Associates - Inventory' });
    } catch (err) {
      setIsError(true);
    }
  }

  const onCloseSendInvoice = () =>{
    setSendStockInv(false);
    setEmailSendData({emailId:'',emailSubject:''})
   }
  
   const onSendEmailInvoice =async () =>{
    let isEmail = false;
    let isSubject = (emailSubject&&emailSubject.length>1)?true:false;
    if(emailId&&emailId.length>0){
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds,(data)=>{
        if (!mailformat.test(String(data).toLowerCase())){
          isEmail = false;
        }
      })
    }
    if(isEmail&&isSubject){
        setEmailHelperText(false);
        setEmailSubjectHelperText(false);
        let invoiceBodyData = "<div></div>"
        if (sendStockInv) {
          invoiceBodyData = () => {
            return (
              <CompToPrintStock ref={componentRef} inventoryStock={stockInv}/>
            )
          }
        }  
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('to',emailId)
        bodyFormData.append('subject',emailSubject)
        bodyFormData.append('invoice',renderToString(invoiceBodyData()))
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/x-www-form-urlencoded',
            'Content-Length': bodyFormData.length
          }
        }
        const result = await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
          bodyFormData,
          config
        );
        if(result.status===200){
          setIsSuccess(true)
          setMessage(result.data&&result.data.message)
          setSendStockInv(false);
          setEmailSendData({emailId:''})
        }
    }else{
      if(!isEmail){
        setEmailHelperText(true);
      }
      if(!isSubject){
        setEmailSubjectHelperText(true);
      }
    }
  }

  const handleDownload = async () => {
    try {
      const clonedQuery = _.cloneDeep(query);
      delete clonedQuery.page;
      delete clonedQuery.pageSize;
      const result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/${API_INVENTORY}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: clonedQuery,
        }
      );
      const inventoryData = result.data && result.data.rows.map(inventory =>{
        return {
        ProductCode: inventory.product.productCode,
        ProductName: inventory.product.productName,
        Location: inventory.location.name,
        Count: inventory.openingQty,
        UOM: inventory.product.uom
        }
      });
      const data = jsonToCsv(inventoryData)
      downloadCSVFile(data,"inventory")
    } catch (err) {
      setIsError(true);
    }
  }

  const getStockInventory = async () => {
    const clonedQuery = _.cloneDeep(query);
    delete clonedQuery.page;
    delete clonedQuery.pageSize;
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/${API_INVENTORY}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: query,
      }
    );
    const inventories = result.data && result.data.rows.map(inventory =>{
      return {
      ProductCode: inventory.product.productCode,
      ProductName: inventory.product.productName,
      safetyStock: inventory.safetyStock,
      Location: inventory.location.name,
      Count: inventory.openingQty,
      UOM: inventory.product.uom
      }
    });
    return inventories;
  }

  return (
    <div>
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Typography component="span" color="textPrimary">
                  Inventory
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={1}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push("/inventoryMovement" )}>
                Inventory Movement </Button>
            </Grid>
          </Grid>
        </Grid>
      <div style={{paddingTop:10}}>
        <Typography variant="h6">
          {"Inventory Search"}
        </Typography>
        <form onSubmit={handleSearch}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container md= {3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Name</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    InputProps={{
                      name: "itemName",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Code</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl style={{ display: "flex" }}>
                  <TextField
                    InputProps={{
                      name: "itemCode",
                    }}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Product Group</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <Autocomplete
                  options={prodGrps}
                  size="small"
                  getOptionLabel={(option) => option.code}
                  onChange={(e, val) => {
                    if (val) {
                      setGroup(val.id);
                      setQuery({
                        ...query,
                        prdGrpId: val.id,
                        page: 1,
                      });
                    }
                  }}
                  onInputChange = {(e,val)=>{
                    if(!val){
                      setGroup(val);
                      setQuery({
                        ...query,
                        prdGrpId: val.id,
                        page: 1,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Grid>
            </Grid>
            <Grid item container md={3} xs={6}>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">Location</Typography>
              </Grid>
              <Grid item md={12} xs={12}>
              <Autocomplete
                  options={inventoryLocations}
                  size="small"
                  getOptionLabel={(option) => option.name}
                  onChange={(e, val) => {
                    if (val) {
                      setLocation(val.id);
                      setQuery({
                        ...query,
                        locationId: val.id,
                        page: 1,
                      });
                    }
                  }}
                  onInputChange = {(e,val)=>{
                    if(!val){
                      setLocation(val);
                      setQuery({
                        ...query,
                        locationId: val,
                        page: 1,
                      });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                />
              </Grid>
            </Grid>
            <Grid item container md={6} xs={12}>
              <Box ml={-2}>
            <Controller
              as={Checkbox}
              name="oos"
              type="checkbox"
              onChange={()=>handleOss()}
              checked={oss}
              control={control}
            /></Box>
            <Box mt={1.3}>
            <Typography>Filter items below safety stock</Typography>
            </Box>
            </Grid>
            <Grid item container  justify={"flex-end"}  md={12} xs={12}>
              <Grid item>
                <Box mt={2} mr={4}>
                  <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Generate Report
            </Button></Box></Grid>
              <Grid item>
                <Box mt={2} mr={4}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<MailIcon />}
                    onClick={handleOpenOrderMail}
                  >
                    Mail
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={2} mr={4}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Download
            </Button></Box>
              </Grid> 
            <Grid item>
            <Box mt={2}>
                <Button size="small" type="reset" onClick={() => resetQuery()}
                   variant="contained" color="secondary">
                  clear</Button>
              </Box></Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <div style={{ paddingTop: 30 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Code</StyledHeaderCell>
                <StyledHeaderCell align="left">Safety Stock</StyledHeaderCell>
                <StyledHeaderCell align="left">Location</StyledHeaderCell>
                <StyledHeaderCell align="left">Count</StyledHeaderCell>
                <StyledHeaderCell align="left">UOM</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={props.modal ? 6 : 7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row, index) => (
                        <TableRow
                          style={{ cursor: props.modal ? "pointer" : "" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell component="th" scope="row">
                            {row.product && row.product.productName}
                          </TableCell>
                          <TableCell align="left">
                            {row.product && row.product.productCode}
                          </TableCell>
                          <TableCell align="right" >
                            {row.safetyStock && row.safetyStock}
                          </TableCell>
                          <TableCell align="left"  onClick={()=>handleLocationClick(row.location && row.location.id)}  className="underLine" style={{cursor:"pointer"}}>
                            {row.location && row.location.name}
                          </TableCell>
                          <TableCell align="right">
                            {row.openingQty}
                          </TableCell>
                          <TableCell align="left">
                            {row.product && row.product.uom}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      ) : (
          <div />
        )}
        <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={sendStockInv}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Mail Inventory
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton onClick={onCloseSendInvoice}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{height:'180px'}}>
            <Grid container>
               <Grid xs={3}>
                <Typography className='recordSaleInvoiceLabel' style={{fontWeight:"bold"}} component="span" >Mail to *</Typography>
               </Grid>
               <Grid item md={9}>
                <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                value={emailId}
                onChange = {e => setEmailSendData({ ...emailSendData, emailId: e.target.value})}
                />
                {isEmailHelpertext&&<FormHelperText style={{color:'red'}}>
                  Enter valid email id
                </FormHelperText>}
              </Grid>
              </Grid>
              <Grid container style={{paddingTop:'10px'}}>
               <Grid xs={3}>
                <Typography className='recordSaleInvoiceLabel' style={{fontWeight:"bold"}} component="span">Subject *</Typography>
               </Grid>
               <Grid item md={9}>
                <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                value={emailSubject}
                onChange = {e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value})}
                />
                {isEmailSubjectHelpertext&&<FormHelperText style={{color:'red'}}>
                  Enter email subject
                </FormHelperText>}
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                onClick={onSendEmailInvoice}
                color="secondary"
                size="small"
                variant="contained"
              >
                Send
              </Button>
            </Box>    
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => setIsSuccess(false)}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setIsSuccess(false)}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message && message }
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Inventory;
