import React from "react";
import { useHistory } from "react-router-dom";

import {
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon
} from "@material-ui/core";

import customerSale from "../../images/customerSales.svg";
import dailyInventory from "../../images/dailyInventory.svg";
import replenishment from "../../images/replenishment.svg";
import productPrice from "../../images/productPrice.svg";
import CustomerOrderReport from "../../images/Customer_order_report.svg";
import InventoryMovementReport from "../../images/Inventory_movement.svg";
import CancelledSalesReport from "../../images/CancelledSales.svg";
import ErrInvoiceReport from "../../images/ErrorReport.svg";
import UnsyncedSalesReport from "../../images/UnsyncedSales.svg";
import PreviousSalePrice from "../../images/previousSalePrice.svg";
import ProductStatusReport from "../../images/ProductStatus_Report.svg";

const Reports = (props) => {
  const history = useHistory();

  return (
    <div>
      <Grid container>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
                </Link>
              <Typography component="span" color="textPrimary">
                Reports
                </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
      <div>
      <Grid item container xs={12} md={3} direction="row">
        <List subheader={<ListSubheader style={{color:"#0C4B60",fontWeight:"bold"}}>List of Reports</ListSubheader>} >
          <ListItem>
          <ListItemIcon>
            <img src={CustomerOrderReport} alt="order" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/customerOrders")}
            >
             Customer Purchase Orders
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
            <img src={customerSale} alt="sale" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/customerSales")}
            >
             Customer Sales
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
            <img src={UnsyncedSalesReport} alt="unsyncedSales" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/unSyncCustSales")}
            >
            Unsynced Sales
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
          <img src={CancelledSalesReport} alt="cancelledInv" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/cancelledInvReport")}
            >
            Cancelled Sales Report
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
          <img src={ErrInvoiceReport} alt="errInv" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/eInvEWBErrorReport")}
            >
            e-Inv & EWB Error Report
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
            <img src={dailyInventory} alt="dailysync" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/inventorySync")}
            >
             Daily Inventory Sync
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
            <img src={replenishment} alt="dailysync" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/replenishmentInsight")}
            >
            Replenishment Insight
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
            <img src={productPrice} alt="productPrice" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/priceReport")}
            >
            Missing Price Report
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
            <img src={InventoryMovementReport} alt="productPrice" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/invMovementReport")}
            >
            Inventory Movement Report
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
            <img src={PreviousSalePrice} alt="custSales" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/customerprevioussales")}
            >
            Product Price Report
          </Link>
          </ListItem>
          <ListItem>
          <ListItemIcon>
            <img src={ProductStatusReport} alt="prodStatus" />
          </ListItemIcon>
            <Link
              component="button"
              variant="body2"
              onClick={() => history.push("/productstatusreport")}
            >
            Product Status Report
          </Link>
          </ListItem>
        </List>
        </Grid>
      </div>
      </div>
  );
};

export default Reports;
