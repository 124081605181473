import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";


import {
  Avatar,
  AppBar,
  Toolbar,
  List,
  ListItemIcon,
  ListItem,
  Box,
  ListItemText,
  Divider,
  IconButton,
  Drawer,
  ListItemSecondaryAction,
  Link,
  Grid,
  Collapse,
  Typography
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import _ from 'lodash';
import axios from "axios";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import createNew from "../images/CreateNew.svg";
import byTechnician from "../images/ByTechnician.svg";
import byStatus from "../images/ByStatus.svg";
import search from "../images/Search.svg";
import { pages } from "./common/constants"
import { logout } from "../components/login/loginSlice";
import { technicians } from "./common/lookupSlice";
import Switch from '@material-ui/core/Switch';
import fsmLogo from "../images/fsmLogo.svg";

const Header = ({ loggedInUser }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const techies = useSelector(technicians);
  const qrCode = localStorage.getItem("qrCodeStatus");
  const [qrStatus, setQRStatus] = React.useState((qrCode && qrCode === "true")?true : false);
  const [openInnerMenu, setOpenInnerMenu] = React.useState({ "/inventory": false, "/productGroups": false, "/products": false });
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isServiceAdvisor =
    roles.findIndex((role) => role.name === "SERVICE ADVISOR") > -1
      ? true
      : false;

  const isAdmin =
    roles.findIndex((role) => role.name === "ADMIN") > -1
      ? true
      : false;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClickHeader = (id) => {
    const collapseOpen = _.cloneDeep(openInnerMenu);
    collapseOpen[id] = !(collapseOpen[id])
    setOpenInnerMenu(collapseOpen);
  };
  const [open, setOpen] = React.useState(false);
  const token = localStorage.getItem("authToken");

  const invoiceChange = async (event) =>{
    await axios.put(`${process.env.REACT_APP_SERVICE_URL}/roles/org/VINDHYA`, {qrCodeStatus: event.target.checked}, {
      headers: {
            Authorization: `Bearer ${token}`,
      },
          })
          .then((res) => {
            localStorage.removeItem("qrCodeStatus");
            localStorage.setItem("qrCodeStatus", res.data.qrCodeStatus);
            setQRStatus(res.data.qrCodeStatus);
          })
          .catch((err) => {
            console.log("err",err);
          });
  }


 const appBarTitle = location.pathname && (location.pathname.includes("job")||location.pathname.includes("datasync")) ? "VA RETAIL : Field Service Management":"VA RETAIL";
  return (
    <AppBar position="static">
      <Toolbar>
        <Grid container spacing={1} justify="space-around">
          <Grid item container xs={5} alignItems="center" spacing={1}>
            {isAdmin ? <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Drawer variant="persistent" anchor="left" open={open}>
                <div>
                  <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                <List dense={false} style={{ width: 230, height: 30 }}>
                  {pages.map((page, key) => {
                    return <><ListItem
                      button
                      key={key}
                      onClick={() => {
                        setOpen(false);
                        history.push(page.href);
                      }}
                    >
                      <>
                        <ListItemIcon style={{ minWidth: 30 }}>
                          <img src={page.icon} alt="menuIcon"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Box fontWeight={"normal"} fontSize={12}>
                            {page.title}
                          </Box>
                        </ListItemText></>
                      <ListItemSecondaryAction>
                        {page.items && (openInnerMenu[page.href] ? <ExpandLess onClick={() => handleClickHeader(page.href)} /> : <ExpandMore onClick={() => handleClickHeader(page.href)} />)}
                      </ListItemSecondaryAction>
                    </ListItem>
                      <Collapse in={openInnerMenu[page.href]} timeout="auto" unmountOnExit>
                        {
                          page.items && page.items.map((child, idx) => {
                            return <ListItem
                              disableGutters
                              style={{width: '93%', borderLeft: '1px solid #8E969F', marginLeft:14, cursor: 'pointer' }}
                              key={idx}
                              onClick={() => {
                                setOpen(false);
                                const collapseOpen = _.cloneDeep(openInnerMenu);
                                collapseOpen[page.href] = !(collapseOpen[page.href])
                                setOpenInnerMenu(collapseOpen);
                                history.push(child.href);
                              }}
                            >
                              <ListItemText>
                                <Box fontWeight={"normal"} fontSize={12} ml={2}>
                                  {child.title}
                                </Box>
                              </ListItemText>
                            </ListItem>
                          })
                        }
                      </Collapse></>
                  })}
                </List>
                <Typography display="block" variant="overline" style={{font: 'normal normal normal 10px/13px Roboto',color:'#687181',position: 'fixed',bottom: 24, left: 4}}>
                 Logged in as:
                </Typography>
                <Typography display="block" variant="overline" style={{font: 'normal normal normal 12px/16px Roboto', color:'#001500',position: 'fixed',bottom: 8, left: 4}}>
                {loggedInUser.firstName} {loggedInUser.lastName}
                </Typography>
              </Drawer>
            </Grid>
              : isServiceAdvisor ? (
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer variant="persistent" anchor="left" open={open}>
                    <div>
                      <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                      </IconButton>
                    </div>
                    <Divider />
                    <List dense={true}>
                      <ListItem
                        button
                        key={"Create New"}
                        onClick={() => {
                          setOpen(false);
                          history.push({
                            pathname: "/job",
                            state: {
                              click: true,
                            },
                          })
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 30 }}>
                          <img src={createNew} alt="create new"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Box fontWeight={"fontWeightBold"} fontSize={16}>
                            Create New
                        </Box>
                        </ListItemText>
                      </ListItem>
                    </List>
                    <Divider />
                    <List dense={true}>
                      <ListItem key={"By Technician"}>
                        <ListItemIcon style={{ minWidth: 30 }}>
                          <img src={byTechnician} alt="by technician"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Box fontWeight={"fontWeightBold"} fontSize={16}>
                            By Technician
                        </Box>
                        </ListItemText>
                      </ListItem>
                    </List>

                    {techies && techies.length > 0 ? (
                      techies.map((tech, index) => (
                        <List key={index} dense={true}>
                          <ListItem
                            button
                            key={tech.id}
                            onClick={() => {
                              history.push({
                                pathname: "/jobs",
                                state: {
                                  technicianId: tech.id,
                                },
                              });
                              setOpen(false);
                            }}
                            style={{ paddingLeft: 40 }}
                          >
                            <ListItemIcon style={{ minWidth: 40 }}>
                              <Avatar
                                style={{
                                  width: 30,
                                  height: 30,
                                }}
                              >
                                {tech.firstName.charAt(0).toUpperCase()}
                              </Avatar>
                            </ListItemIcon>

                            <ListItemText
                              primary={`${tech.firstName} ${tech.lastName}`}
                            />
                          </ListItem>
                        </List>
                      ))
                    ) : (
                        <List dense={true}>
                          <ListItem style={{ paddingLeft: 50 }}>
                            <ListItemText primary="No technicians available" />
                          </ListItem>
                        </List>
                      )}
                    <Divider />
                    <List dense={true}>
                      <ListItem
                        button
                        key={"Search"}
                        onClick={() => {
                          setOpen(false);
                          history.push("/jobs");
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 30 }}>
                          <img src={search} alt="search"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Box fontWeight={"fontWeightBold"} fontSize={16}>
                            Search
                        </Box>
                        </ListItemText>
                      </ListItem>
                    </List>
                    <Divider />
                    <List dense={true}>
                      <ListItem
                        button
                        key={"dashboard"}
                        onClick={() => {
                          setOpen(false);
                          history.push("/");
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 30 }}>
                          <img src={byStatus} alt="by status"/>
                        </ListItemIcon>
                        <ListItemText>
                          <Box fontWeight={"fontWeightBold"} fontSize={16}>
                            Dashboard
                        </Box>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Drawer>
                </Grid>
              ) : null}
            <Grid item>
              <Link component="button" onClick={() => history.push("/")}>
                <img src={fsmLogo} alt="logo" />
              </Link>
            </Grid>
            <Grid item>
              <Box fontWeight={"fontWeightBold"}>{appBarTitle}</Box>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={6}
            spacing={1}
            alignItems="center"
            justify="flex-end"
          >
           {isAdmin? <Grid item>
              <span>e-Invoice/e-Way Bill</span>
            </Grid>:null}
            {isAdmin?<Grid item>
              <Switch
                  checked={qrStatus}
                  onChange={invoiceChange}
                  style={{color:"white"}}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
            </Grid>:null}
            <Grid item>
              <AccountCircleIcon fontSize="large" />
            </Grid>
            <Grid item>
              {loggedInUser.firstName} {loggedInUser.lastName}
            </Grid>
          </Grid>
          <Grid item container xs={1} justify="flex-end" alignItems="center">
            <Grid item>
              <IconButton color="inherit" onClick={() => dispatch(logout())}>
                <ExitToAppIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
