import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress
} from "@material-ui/core";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import productJobImg from "../../images/Productjob.svg";
import inventoryjobImg from "../../images/Inventoryjob.svg";
import customerjobImg from "../../images/Customerjob.svg";
import salesjobImg from "../../images/Salesjob.svg";
import moment from "moment";
import axios from "axios";

const DailyJobSyncDashboard = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [pData, setPData] = useState(null);
  const [invData, setInvData] = useState(null);
  const [custData, setCustData] = useState(null);
  const [saleData, setSaleData] = useState(null);

  const getRecentBatchJob = async () => {
    const result = await axios.get(`${process.env.REACT_APP_SERVICE_URL}/batch/recntbatchjob`);
    if (result && result.data && result.data.batchJob) {
      setPData(result.data.batchJob.product);
      setInvData(result.data.batchJob.productInv);
      setCustData(result.data.batchJob.customer);
      setSaleData(result.data.batchJob.voucher);
    }
  }
  useEffect(() => {
    // Run! Like go get some data from an API.
    getRecentBatchJob()
  }, []);

  let onProductJobClick = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVICE_URL}/batch/products`);
    getRecentBatchJob()
    setIsLoading(false)
  }
  let onInvJobClick = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVICE_URL}/batch/productinventory`);
    getRecentBatchJob()
    setIsLoading(false)
  }
  let onCustJobClick = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVICE_URL}/batch/customer`);
    getRecentBatchJob()
    setIsLoading(false)
  }
  let onSaleJobClick = async () => {
    setIsLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVICE_URL}/batch/voucher`);
    getRecentBatchJob()
    setIsLoading(false)
  }
  let onHistoryClick = async (jobType) => {
    window.location.href=`/jobhistory?jobType=${jobType}`;
  }
  return (
    <div>
      {isLoading?<div className="loader">
        <CircularProgress />
      </div>:null}
      <div>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
              <Link color="inherit" href="" onClick={() => history.push("/")}>
                Home
              </Link>
              <Typography component="span" color="textPrimary" style={{ fontWeight: 'bold' }}>
                Run Jobs
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </div >
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Tally Outbound Jobs</StyledHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Grid container>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Products
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={productJobImg}
                            alt="product job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(pData && pData.createdAt) ? moment(pData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}</Typography>
                          <Typography>Product in file : {pData && pData.totalItem}</Typography>
                          <Typography>Newly created products : {pData && pData.newItem}</Typography>
                          <Typography>Updated products : {pData && pData.updatedItem}</Typography>
                          <Typography>Status : {(pData && pData.status)?<span style={{color:(pData.status==="SUCCESS")?"green":"red"}}>{pData.status}</span>:""}</Typography>
                          <Typography>Errors : {pData && pData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(pData && pData.message) ? pData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                            onClick={()=>{onHistoryClick("PRODUCT")}}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onProductJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Inventory
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={inventoryjobImg}
                            alt="Inv job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(invData && invData.createdAt) ? moment(invData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY')  : ""}</Typography>
                          <Typography>Product in file : {invData && invData.totalItem}</Typography>
                          <Typography>Newly created products : {invData && invData.newItem}</Typography>
                          <Typography>Updated products : {invData && invData.updatedItem}</Typography>
                          <Typography>Status : {(invData && invData.status)?<span style={{color:(invData.status==="SUCCESS")?"green":"red"}}>{invData.status}</span>:""}</Typography>
                          <Typography>Errors : {invData && invData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(invData && invData.message) ? invData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                             onClick={()=>{onHistoryClick("PRODUCT_INV")}}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onInvJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper}>
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Tally Inbound Jobs</StyledHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Grid container>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Customer
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={customerjobImg}
                            alt="customer job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(custData && custData.createdAt) ? moment(custData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}</Typography>
                          <Typography>New Customer : {custData && custData.newItem}</Typography>
                          <Typography>Status : {(custData && custData.status)?<span style={{color:(custData.status==="SUCCESS")?"green":"red"}}>{custData.status}</span>:""}</Typography>
                          <Typography>Errors : {custData && custData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(custData && custData.message) ? custData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                             onClick={()=>{onHistoryClick("CUSTOMER")}}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onCustJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={6} className='dailyJobMainBody'>
                  <Paper className="dailyJobContentMainBody">
                    <div className="dailyJobContentSubBody">
                      <Grid container style={{ height: "45px" }}>
                        <Grid item xs={4} className="dailyLabelHeadr">
                          Sales
                        </Grid>
                        <Grid item xs={8}>
                          <img
                            src={salesjobImg}
                            alt="Inv job"
                            className="dailyJobIcon"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Last Run : {(saleData && saleData.createdAt) ? moment(saleData.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY') : ""}</Typography>
                          <Typography>New Sales : {saleData && saleData.newItem}</Typography>
                          <Typography>Status : {(saleData && saleData.status)?<span style={{color:(saleData.status==="SUCCESS")?"green":"red"}}>{saleData.status}</span>:""}</Typography>
                          <Typography>Errors : {saleData && saleData.errorItem}</Typography>
                          <Typography className="errorMsg">Message : {(saleData && saleData.message) ? saleData.message : "Nil"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container className="btnBody">
                        <Grid item xs={12}>
                          <Button
                             onClick={()=>{onHistoryClick("VOUCHER")}}
                            color="secondary"
                            variant="contained"
                            style={{ float: "right" }}
                          >
                            History
                          </Button>
                          <Button
                            onClick={onSaleJobClick}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: "10px", float: "right" }}
                          >
                            Run
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default DailyJobSyncDashboard;
