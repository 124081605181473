import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocalities, custTypes, custStates } from "../common/lookupSlice";
import { API_ALL_CUSTOMERS } from "../../config/constants";

import {
  Typography,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Breadcrumbs,
  Link,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PurchaseHistoryIcon from "../../images/Purchase_history.svg";
import DraftSalesIcon from "../../images/DraftSales.svg"
import CustomerOrderIcon from "../../images/Customer_order.svg";
import DiscountIcon from "../../images/Discount.svg";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

let errorMsg;

function CustomersForm(props) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("authToken");
  const { id } = useParams();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [ customerData, setCustomerData ] = useState({});
  let { fullName, email, mobileNumber, address1, address2, address3, pincode, locality, GSTIN} = customerData;
  const [ custType, setCustType ] = useState();
  const [isError, setIsError] = useState(false);
  const { handleSubmit, errors, register, getValues } = useForm({});
  const [inputFields, setInputFields] = useState([]);
  const [ create, setCreate ] = React.useState({});
  const stateObj = useSelector(custStates);
  const types = useSelector(custTypes);
  const states = stateObj ? stateObj.map(state=>state.name):[];
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [ saved, setSaved ] = React.useState(false);
  const [isGSTNErr, setGSTNErr] = useState(false);
  const [isPincodeErr, setPincodeErr] = useState(false);
  const [pincodeErrMsg, setPincodeErrMsg] = useState(false);
  var queryStringData = window.location.search;
  var chars = queryStringData.split('=');
  let isQuotation = (chars[1]) ? chars[1].toUpperCase() === "QUOTATION": false;
  let isPO = (chars[1]) ? chars[1].toUpperCase() === "PURCHASEORDER": false;

  useEffect(()=> {
    async function fetchData(){
    if(id){
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_CUSTOMERS}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let modifiedContacts = result.data.contacts.map((obj) => {
          obj.contactId = obj.id;
          delete obj["id"];
          return obj;
        });
        result.data.contacts = modifiedContacts;
        setInputFields(result.data.contacts);
        setCustomerData(result.data);
        setCustType(result.data.customerType.id);
        const stateIndex = states.indexOf(result.data.customerState.name)
        setValue(states[stateIndex])
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
    }}
    fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  }

  const handleRemoveFields = (index) => {
    const values  = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  }
  const onSubmit = async (customerData) => {
    const contacts = getValues({ nest: true })["contacts"];
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    let isContinue = true;
    const selectedState = stateObj.filter(x=>x.name===value);
    customerData.contacts = inputFields;
    customerData.GSTIN = GSTIN;
    customerData.id = id;
    //customerData.state = selectedState.length>0 ? selectedState[0].code:'';
    customerData.state = selectedState && selectedState[0] ? selectedState[0].code:'';
    
      if(customerData.GSTIN){
        if (/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(customerData.GSTIN)) {
          let stCode = customerData.GSTIN.slice(0, 2);
          if(parseInt(stCode) === selectedState[0].stateId){
            setGSTNErr(false);
          }else{
          isContinue = false;
          errorMsg = "GSTIN not matching to state."
          setGSTNErr(true);
          }
        }else{
          isContinue = false;
          errorMsg = "Enter Valid GSTIN."
          setGSTNErr(true);
        }
      }else{
        if(custType==="2"){
          isContinue = false;
          errorMsg = "Enter Valid GSTIN."
          setGSTNErr(true);
        }else{
          setGSTNErr(false);
        }
      }
    if(customerData.pincode && selectedState &&selectedState[0]  ){
      let testPinCode = parseInt(customerData.pincode.toString().slice(0, 3));
      if(testPinCode >= parseInt(selectedState[0].pinCodeSR) && testPinCode <= parseInt(selectedState[0].pinCodeER)){
        setPincodeErr(false);
      }else{
        setPincodeErr(true);
        setPincodeErrMsg("Pincode not matching to state.");
        isContinue=false;
      }
    }else{
      isContinue = false;
      setPincodeErrMsg("Enter Valid Pincode.");
        setPincodeErr(true);
    }
    customerData.custTypeId = custType?custType:1;
    if (customerData.contacts) {
      let modifiedContacts = contacts ? contacts.map(obj=>{
        obj.id = Number(obj.id);
        return obj;
      }):[];
      customerData.contacts = JSON.stringify(modifiedContacts);
    }
    if(isContinue){
      setGSTNErr(false);
      try {
        let newCustomer;
        if (id) {
          await axios.patch(
            `${process.env.REACT_APP_SERVICE_URL}/customers/${id}`,
            customerData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }else {
          newCustomer = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/customers`,
            customerData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } 
        dispatch(fetchLocalities());
        setIsProcessing(false);
        if(props.job){
          props.cancel();
          props.onCreate(newCustomer && newCustomer.data && newCustomer.data.customerData)
        }
        else{
        setCreate({
          success: true
        });
        setSaved(true);
      }
      let custData = newCustomer && newCustomer.data && newCustomer.data.customerData;
      if(isPO){
        history.push({state: custData})
          window.location.href = `/customerOrder/${custData.id}?from=purchaseorder`;
      }else if(isQuotation){
        history.push({state: custData})
        window.location.href = `/customerOrder/${custData.id}?from=quotation`;
      }
      } catch (err) {
        console.log("err.response.data.message",err.response.data.message);
        errorMsg = (err.response.data.message&& err.response.data.message.length>0 &&err.response.data.message[1]==="fullName must be unique")?"Customer Name has to be unique":err.response.data.message;
        setIsError(true);
        setIsProcessing(false);
        setSaved(false);
      }
    }else{
        setIsProcessing(false);
    }
  };
  return (
    <div>
     {!props.job &&  <Grid container>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                <Link color="inherit" href="" onClick={() => history.push("/")}>
                  Home
                </Link>
                <Typography component="span" color="textPrimary">
                  Customers
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Grid>}
    
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className="customer-form">
      <Grid container spacing={2}>
        <Grid item container md={9} xs={9} spacing={1}>

          <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Customer Number</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
          <TextField
              variant="outlined"
              fullWidth 
              value={id}
              disabled={true}
              size="small"
            />
          </Grid>
          </Grid>

          <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Full Name*</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.fullName)}
              inputRef={register({
                required: "Minimum 5 letters required. No digits.",
                // pattern: {
                //   value: /^[a-zA-Z ]{5,}$/,
                //   message: "Minimum 5 letters required. No digits.",
                // },
              })}
              disabled={(id)?true:false}
              InputProps={{
                autoFocus: true,
                name: "fullName",
              }} value={fullName}
              onChange = {e => setCustomerData({ ...customerData, fullName: e.target.value})}
              size="small"
              name="fullName"
            />
            <FormHelperText error>
              {errors.fullName && errors.fullName.message}
            </FormHelperText>
          </Grid>
            <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption" style={{float: "right"}}>
              Customer Type
            </Typography>
            </Grid>
            <Grid item md={3} xs={3}>
                <TextField  select value={custType?custType:''} size="small" fullWidth
                  onChange={e => setCustType(e.target.value)}
                    SelectProps={{ native: true }} variant="outlined">
                      <option key={"type"} value=''>Select Type</option>
                        {types && Object.values(types).map((obj) => {
                          const { id, description } = obj;
                          return <option key={id} value={id} >{description} </option>
                        })}
                </TextField>
              </Grid>
          </Grid>
        </Grid>
        {id&&<Grid item container md={3} xs={3}>
        <ul style={{listStyle: 'none'}}>
                <li style={{marginTop: '-6%', fontSize: '12px'}}>
                <Link color="inherit" className="underLine" style={{cursor: "pointer"}} onClick={() => history.push("/customerHistory/"+id)}>
                  <IconButton>
                  <img src={PurchaseHistoryIcon} alt="history"/></IconButton> Purchase and Service History
                </Link>
                </li>
                <li style={{marginTop: '-2%', fontSize: '12px'}}>
                <Link color="inherit" className="underLine" style={{cursor: "pointer"}} onClick={() => {
                  history.push({
                  pathname: "/customerOrder/" + id,
                  state: custType})
                  }}>
                <IconButton><img src={CustomerOrderIcon}  alt="Customer Purchase Order"/></IconButton> Customer Purchase Order</Link>
                </li></ul>
        </Grid>}

        <Grid item container md={9} xs={9} spacing={1}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Building/Floor No</Typography>
          </Grid>
          <Grid item md={9} xs={9}>
            <TextField
              variant="outlined"
              fullWidth
              type="adress"
              error={Boolean(errors.address1)}
              inputRef={register({
                // minLength: {
                //   value: 5,
                //   message: "Minimum 10 characters required",
                // },
              })} 
              value={address1}
              onChange = {e => setCustomerData({ ...customerData, address1: e.target.value})}
              size="small"
              name="address1"
            />
            <FormHelperText error>
              {errors.address1 && errors.address1.message}
            </FormHelperText>
          </Grid>
          </Grid>
        </Grid>
        {id&&<Grid item container md={3} xs={3}>
          <ul style={{listStyle: 'none', marginTop: '-13%', fontSize: '12px'  }}>
            <li>
              <Link color="inherit" className="underLine" style={{cursor: "pointer"}} onClick={() => history.push("/customerDiscount/"+id)}>
                <IconButton><img src={DiscountIcon}  alt="discount"/></IconButton> Discount</Link>
            </li>
            <li style={{marginTop: '-6%', fontSize: '12px'}}>
                <Link color="inherit" className="underLine" style={{cursor: "pointer"}} onClick={() => 
              history.push({
                pathname: "/customerDrafts/"+id,
                state: customerData
              })}>
                  <IconButton>
                  <img src={DraftSalesIcon} alt="draft"/></IconButton> Draft Sales
                </Link>
                </li></ul>
        </Grid>}

        <Grid item container md={9} xs={9} spacing={1}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Street Name</Typography>
          </Grid>
          <Grid item md={9} xs={9}>
            <TextField
              variant="outlined"
              fullWidth
              type="adress"
              error={Boolean(errors.address2)}
              inputRef={register({
                // minLength: {
                //   value: 5,
                //   message: "Minimum 10 characters required",
                // },
              })} 
              value={address2}
              onChange = {e => setCustomerData({ ...customerData, address2: e.target.value})}
              size="small"
              name="address2"
            />
            <FormHelperText error>
              {errors.address2 && errors.address2.message}
            </FormHelperText>
          </Grid>
          </Grid>
        </Grid>

        <Grid item container md={9} xs={9} spacing={1}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">City</Typography>
          </Grid>
          <Grid item md={9} xs={9}>
            <TextField
              variant="outlined"
              fullWidth
              type="adress"
              error={Boolean(errors.address3)}
              inputRef={register({
                // minLength: {
                //   value: 5,
                //   message: "Minimum 10 characters required",
                // },
              })} 
              value={address3}
              onChange = {e => setCustomerData({ ...customerData, address3: e.target.value})}
              size="small"
              name="address3"
            />
            <FormHelperText error>
              {errors.address3 && errors.address3.message}
            </FormHelperText>
          </Grid>
          </Grid>
        </Grid>

        <Grid item container md={9} xs={9} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Pincode *</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              error={Boolean(errors.pincode) || isPincodeErr}
              type="number"
              inputRef={register({                
                required: "6 digit pincode is required",
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: "6 digit pincode is required",
                },
              })} value={pincode}
              onChange = {e => setCustomerData({ ...customerData, pincode: e.target.value})}
              size="small"
              name="pincode"
            />
            <FormHelperText error={Boolean(errors.pincode) || isPincodeErr}>
             {(isPincodeErr)?pincodeErrMsg:errors.pincode && errors.pincode.message}
           </FormHelperText>
          </Grid>

          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Locality</Typography>
          </Grid>
          <Grid item md={3} xs={3}>
                <TextField variant="outlined" value={locality}
                  size="small" name="locality"
                  onChange = {e => setCustomerData({ ...customerData, locality: e.target.value})}
                  error={Boolean(errors.locality)}
                  fullWidth inputRef={register({
                    // pattern: {
                    //   value: /^[a-zA-Z0-9 ]{3,}$/,
                    //   message: "Minimum of 3 characters required",
                    // },
                  })}/>
            <FormHelperText error>
              {errors.locality && errors.locality.message}
            </FormHelperText>
          </Grid>
        </Grid>

        <Grid item container md={9} xs={9} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Mobile Number *</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField variant="outlined" fullWidth error={Boolean(errors.mobileNumber)}
            onChange = {e => setCustomerData({ ...customerData, mobileNumber: e.target.value})}
              type="number" inputRef={register({
                required: "Enter valid 10 digit mobile number",
                pattern: {
                  value: /^[6789]{1}[0-9]{9}$/,
                  message: "Enter valid 10 digit mobile number",
                },
              })} value={mobileNumber} size="small" name="mobileNumber" />
            <FormHelperText error>
              {errors.mobileNumber && errors.mobileNumber.message}
            </FormHelperText>
          </Grid>

          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">State *</Typography>
          </Grid>
          <Grid item md={3} xs={3}>
            <Autocomplete size="small"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={states}
        style={{ width: 215 }}
        renderInput={(params) => <TextField {...params} 
        variant="outlined"
        name="st"
        error={Boolean(errors.st)} 
        inputRef={register({
          required: "Required"
        })}/>}
      />
          </Grid>
          </Grid>

          <Grid item container md={9} xs={9} spacing={1}>
          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">Email</Typography>
          </Grid>
          <Grid item md={4} xs={4}>
            <TextField
              variant="outlined"
              fullWidth onChange = {e => setCustomerData({ ...customerData, email: e.target.value})}
              type="email" value={email}
              error={Boolean(errors.email)}
              inputRef={register({
                // minLength: {
                //   value: 5,
                //   message: "Minimum 5 characters required",
                // },
              })}
              size="small"
              name="email"
            />
            <FormHelperText error>
              {errors.email && errors.email.message}
            </FormHelperText>
          </Grid>

          <Grid item md={2} xs={2}>
            <Typography className="customer-form-label" variant="caption">GSTIN{(custType && custType==="2")?"*":""}</Typography>
          </Grid>
          <Grid item md={3} xs={3}>
            <TextField value={GSTIN}
              variant="outlined" fullWidth
              onChange = {e => setCustomerData({ ...customerData, GSTIN: e.target.value})}
              size="small" name="gstin"
              error={Boolean(errors.gstin) || isGSTNErr}
              inputRef={register({})} 
              />
              <FormHelperText error={Boolean(errors.gstin) || isGSTNErr}>
             {(isGSTNErr)?errorMsg:errors.gstin && errors.gstin.message}
           </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      </div>
      <div style={{ paddingTop: 40, paddingBottom: 40 }}>
      <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left">Contact Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Mobile Number</StyledHeaderCell>
                <StyledHeaderCell align="left">Action</StyledHeaderCell>
              </TableRow>
            </TableHead>
            {inputFields.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Typography variant="subtitle1">
                      No contacts added.
                    </Typography>
                    <IconButton
                      size="medium"
                      align="center"
                      onClick={() => setInputFields([...inputFields, {
                        contactId: "",
                        contactName: "",
                        mobileNumber: "",
                      }])}>
                      <PersonAddIcon color="primary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}
          <TableBody>
        { inputFields.map((inputField, index) => (
            <TableRow key={inputField.contactId}>
              <input
                        type="text"
                        style={{ display: "none" }}
                        name={`contacts[${index}].id`}
                        defaultValue={inputField.contactId}
                        ref={register({})}
                      />
            <TableCell align="left">
            <TextField 
              error={Boolean(
                errors.contacts &&
                  errors.contacts[index] &&
                  errors.contacts[index].contactName
              )}
              inputRef={register({
                required: "Min 3 characters required",
                pattern: {
                  value: /^[a-zA-z0-9 ]{3,}$/,
                  message: "Min 3 characters required",
                },
              })}
              //   name="contactName"
              //value={cont && cont[index] && (cont[index]).contactName }

              name={`contacts[${index}].contactName`}
              defaultValue={
                inputField.contactName
              }
              onChange={event => handleChangeInput(index, event)}
            />
            <FormHelperText error>
              {errors.contacts &&
              errors.contacts[index] &&
              errors.contacts[index].contactName &&
              errors.contacts[index].contactName.message}
            </FormHelperText></TableCell>
            <TableCell>
            <TextField 
              error={Boolean(
                errors.contacts &&
                  errors.contacts[index] &&
                  errors.contacts[index].mobileNumber
              )}
              inputRef={register({
                required: "Provide Mobile Number"
              })}
              // name="mobileNumber"
              //value={cont && cont[index] && (cont[index]).mobileNumber }

              name={`contacts[${index}].mobileNumber`}
              defaultValue={
                inputField.mobileNumber
              }
              onChange={event => handleChangeInput(index, event)}
            />
            <FormHelperText error>
              {errors.contacts &&
              errors.contacts[index] &&
              errors.contacts[index].mobileNumber &&
              errors.contacts[index].mobileNumber.message}
            </FormHelperText></TableCell>
            <TableCell align="left">
            <IconButton onClick={() => setInputFields([...inputFields, {
                          contactId: "",
                          contactName: "",
                          mobileNumber: "",
                        }])}>
              <AddIcon color="secondary"  />
            </IconButton>
            <IconButton onClick={() => handleRemoveFields(index)}>
              <DeleteIcon color="secondary" />
            </IconButton>
            </TableCell>
            </TableRow>
        )) }
        </TableBody>
        </Table>
      </TableContainer>
      </div>
      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button size="small" type="submit"
              variant="contained" color="primary" disabled={saved}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => props.job ? props.cancel():history.push("/customers")}
              color="secondary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <input type="hidden" name="isDirty" value="true" ref={register()} />
      <input type="hidden" name="id" ref={register()} />
    </form>
    <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={() => setIsError(false)}
        >
          <MuiAlert
            onClose={() => setIsError(false)}
            elevation={6}
            variant="filled"
            severity="error"
          >
            Some error occured. Please retry after sometime.
        </MuiAlert>
        </Snackbar>
    <Snackbar
        open={create.success}
        onClose={() => setCreate({ ...create, success: false })}
        autoHideDuration={5000}
      >
        <MuiAlert
          onClose={() => setCreate({ ...create, success: false })}
          elevation={6}
          variant="filled"
          severity="success">
          {create.success && id?"Customer updated successfully"
            : "Customer created successfully"}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default CustomersForm;
