import Home from "../../images/HomeMenu.svg";
import Inventory from "../../images/InventoryMenu.svg";
import Reports from "../../images/ReportsMenu.svg";
import ProductGroups from "../../images/ProductGroupsMenu.svg";
import Products from "../../images/ProductMenu.svg";
import Customers from "../../images/CustomerMenu.svg";
import ServiceRequest from "../../images/ServiceTool.svg";
import Users from "../../images/UserMenu.svg";
import BatchJobs from "../../images/BatchJobsMenu.svg";

export const pages = [
  {
    title: 'Home',
    href: '/',
    icon: Home
  },
  {
    title: 'Inventory',
    href: '/inventory',
    icon: Inventory,
    items: [
      {
        title: 'Inventory Reconcile - Location',
        href: '/inventoryLocation'
      },
      {
        title: 'Inventory Reconcile - Product',
        href: '/inventoryProduct'
      }
    ]
  },
  {
    title: 'Product Groups',
    href: '/productGroups',
    icon: ProductGroups,
    items: [
      {
        title: 'Manage Products',
        href: '/manageProducts'
      },
      {
        title: 'Manage Discounts',
        href: '/manageDiscounts'
      }
    ]
  },
  {
    title: 'Products',
    href: '/products',
    icon: Products,
    items: [
      {
        title: 'Manage Products',
        href: '/manageProducts'
      },
    ]
  },
  {
    title: 'Reports',
    href: '/reports',
    icon: Reports,
    items: [
      {
        title: 'Customer Purchase Orders',
        href: '/customerOrders'
      },
      {
        title: 'Customer Sales',
        href: '/customerSales'
      },
      {
        title: 'Unsync Customer Sales',
        href: '/unSyncCustSales'
      },
      {
        title: 'Cancelled Invoice Report',
        href: '/cancelledInvReport'
      },
      {
        title: 'Daily Inventory Sync',
        href: '/inventorySync'
      },
      {
        title: 'Inventory Movement Report',
        href: '/invMovementReport'
      }
      
    ]
  },
  {
    title: 'Customers',
    href: '/customers',
    icon: Customers
  },
  {
    title: 'Service Requests',
    href: '/jobs',
    icon: ServiceRequest
  },
  {
    title: 'Users',
    href: '/users',
    icon: Users
  },
  {
    title: "Data Management",
    href: "/datasync",
    icon: BatchJobs
  },
];

export const supplySubTypes = [{name:"Exhibition or fairs"},
                              {name:"Export"},
                              {name:"For own Use"},
                              {name:"Job Work"},
                              {name:"Line Sales"},
                              {name:"Recipient not known"},
                              {name:"SKD/CKD/Lots"},
                              {name:"Supply"}, 
                              {name:"Others"}];

export const vehicleTypes = [{id:"O",name:"ODC"},
                             {id:"R",name:"Regular"}];

export const supplyTypes = [{name:"Inward"},
                            {name:"Outword"}];

export const transportModes = [{id:"1",name:"Road"},
                               {id:"2",name:"Rail"},
                               {id:"3",name:"Air"},
                               {id:"4",name:"Ship"}];

export const receiverEmailId = "rvikas@dynamixedigital.com"