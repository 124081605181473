import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  Typography,
  TextField,
  Snackbar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";

import Pagination from "@material-ui/lab/Pagination";
import { StyledHeaderCell } from "../common/StyledHeaderCell";
import { useForm } from "react-hook-form";

const ManageProducts = (props) => {
  const { register, getValues } = useForm()
  const pageSize = 10;
  const [count, setCount] = useState();
  const [product, setProduct] = useState(null);
  const [search, setSearch] = useState('');
  const [subLoc, setSubLoc] = useState('');
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({ page: 1, pageSize: pageSize });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  const token = localStorage.getItem("authToken");

  const loadData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
   let result;    
   let finaliZedQuery = (query)?JSON.parse(JSON.stringify(query)):{};
   finaliZedQuery.productName = search;
   finaliZedQuery.subLocation = subLoc;
   if(props.lineIdentifier || props.saleIdentifier){
     const custTypeId = props.addonData ? props.addonData.customerType:1
     const locationId = props.addonData ? props.addonData.locationId:1
     finaliZedQuery.custTypeId = custTypeId;
     finaliZedQuery.locationId = locationId;
   result = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}/pos/products`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: finaliZedQuery,
        }
      );
      }
      else{
        result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/products`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: finaliZedQuery,
          }
        );
      }
      setCount(result.data.count);
      setData(result.data.products);
    } catch (err) {
      setCount(0);
      setData([]);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [query, search, subLoc]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(product){
    handleSave();
    }
    
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = () => {
    let existing =false;
    if(props.exist){
    const lineItems = props.getValues()
    const lineItemskeys = Object.keys(lineItems);
    const productIdLineItem= lineItemskeys.filter(x=>x.includes("productId"))
    const productIds=[];
    productIdLineItem.forEach(x=> {
    productIds.push(lineItems[x])
   })
   existing = productIds.some(x=>product.product && Number(x)===product.product.id)
  }

  if(existing){
    setIsWarning(true)
  }
  else{
    if (product) {
      if(props.lineIdentifier){
        const productCodeKey = `lineItems[${props.form.lineKey}].productCode`;
        const availableQtyKey = `lineItems[${props.form.lineKey}].availableQty`;
        const fullfilledQtyKey = `lineItems[${props.form.lineKey}].fulfilledQty`;
        const productIdKey = `lineItems[${props.form.lineKey}].productId`;
        const productPrice = `lineItems[${props.form.lineKey}].negotiatedPrice`;
        const productHSNCode = `sales[${props.form.lineKey}].HSNCode`;
        const isQtyDecimal = `sales[${props.form.lineKey}].isQtyDecimal`;
        props.setValue(productPrice, product.product.price ? (Math.round(product.product.price * 100) / 100).toFixed(2):'0.00') //set the product price if we are getting it from api
        props.setValue(productCodeKey, product.product.productName)
        props.setValue(productHSNCode, product.product.HSNCode)
        props.setValue(availableQtyKey, product.availableQty ? product.availableQty:0)
        props.setValue(fullfilledQtyKey, product.fulfilledQty ? product.fulfilledQty:0)
        props.setValue(productIdKey, product.product.id)
        props.setValue(isQtyDecimal,( product.product && product.product.isQtyDecimal)?1:0)
        }
        else if(props.saleIdentifier){
          const ex = props.sales ? props.sales.findIndex(x=>Number(x.productId)===product.product.id):-1;
        if(product.availableQty<=0){
          props.setIsWarning(true)
        } 
        else if(ex !== -1){
          const qtyKey = `sales[${ex}].qty`;
          const oldQty = props.sales && props.sales[ex] ? props.sales[ex].qty:0;
          const availability = props.sales && props.sales[ex] ? props.sales[ex].avail:0;
          const newQty = Number(oldQty)+1;
          props.setValue(qtyKey, newQty>=Number(availability) ? Number(availability):newQty )
        }
        else{
        const productCodeKey = `sales[${props.form.lineKey}].productName`;
        const productHSNCode = `sales[${props.form.lineKey}].HSNCode`;
        const productData = `sales[${props.form.lineKey}].productData`;
        const qtyKey = `sales[${props.form.lineKey}].qty`;
        const availableQtyKey = `sales[${props.form.lineKey}].avail`;
        const discountKey = `sales[${props.form.lineKey}].disc`;
        const uomKey = `sales[${props.form.lineKey}].uom`;
        const productIdKey = `sales[${props.form.lineKey}].productId`;
        const productPrice = `sales[${props.form.lineKey}].rate`;
        const actualProductPrice = `sales[${props.form.lineKey}].actualRate`;
        const amountPrice = `sales[${props.form.lineKey}].amount`;
        const isQtyDecimal = `sales[${props.form.lineKey}].isQtyDecimal`;
        props.setValue(productCodeKey, product.product && product.product.productName)
        props.setValue(productHSNCode, product.product && product.product.HSNCode)
        props.setValue(qtyKey, 1)
        props.setValue(availableQtyKey, product.availableQty ? product.availableQty:0)
        props.setValue(discountKey, '0.00')
        props.setValue(uomKey, product.product && product.product.uom)
        props.setValue(productData, product.product && JSON.stringify(product.product))
        props.setValue(productIdKey, product.product && product.product.id)
        props.setValue(productPrice, product.product.price ? (Math.round(product.product.price * 100) / 100).toFixed(2):'0.00')
        props.setValue(actualProductPrice, product.product.price ? (Math.round(product.product.price * 100) / 100).toFixed(2):'0.00')
        props.setValue(amountPrice, product.product.price ? (Math.round(product.product.price * 100) / 100).toFixed(2):'0.00')
        props.setValue(isQtyDecimal,( product.product && product.product.isQtyDecimal)?true:false)  
        !(props.isInventoryMove) && props.setCalculate(null)
        product.safetyStock && product.availableQty && (product.availableQty < product.safetyStock) &&
        props.setSafetyStockWarning(true);
      } 
      }
        else{
        props.setValue("productId", product.productName);
        product.uom && props.setUom && props.setUom(product.uom);
        props.setProdId(product.id);
        }
        props.success()
    }
    else {
      props.cancel()
    }
  }
  }

  const handleProductClick = (row) =>{
    setProduct(row)
  }

  const handleKeyDownProductName = (event) => {
    if (event.key === 'Enter') {
      setSearch(getValues('productId'));
    }
  };

  
  const handleKeyDownSubLoc = (event) => {
    if (event.key === 'Enter') {
      setSubLoc(getValues('subLocation'));
    }
  };

  return (
    <div>
      <Grid container spacing={1}>
      <Grid item md={6}>
        <Typography variant="caption">Product Name</Typography>
        <TextField
          variant="outlined"
          fullWidth
          inputRef={register({})}
          InputProps={{
            name: "productId",
            autoFocus: true,
            endAdornment: (
              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                <SearchIcon onClick={() => { setSearch(getValues('productId')) }} />
              </InputAdornment>
            ),
          }}
          size="small"
          name="productId"
          onKeyDown={handleKeyDownProductName}
        />
      </Grid>
      <Grid item md={6}>
        <Typography variant="caption">Sub Location</Typography>
        <TextField
          variant="outlined"
          fullWidth
          inputRef={register({})}
          InputProps={{
            name: "subLocation",
            endAdornment: (
              <InputAdornment position="end" style={{ cursor: "pointer" }}>
                <SearchIcon onClick={() => { setSubLoc(getValues('subLocation')) }} />
              </InputAdornment>
            ),
          }}
          size="small"
          name="subLocation"
          onKeyDown={handleKeyDownSubLoc}
        />
      </Grid>
      </Grid>
      <div style={{ paddingTop: 10 }}>
        <TableContainer component={Paper} >
          <Table style={{ paddingTop: 10 }} size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="center">Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Available Qty</StyledHeaderCell>
                <StyledHeaderCell align="left">Price</StyledHeaderCell>
                <StyledHeaderCell align="left">Sub Location</StyledHeaderCell>
              </TableRow>

            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
                <TableBody>
                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle1">
                          No records found
                      </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                      data.map((row, index) => (
                        <TableRow
                          style={{ cursor: "pointer" }}
                          key={row.id}
                          hover={true}
                          onClick={(e) =>
                            props.onRowSelect && props.onRowSelect(row)
                          }
                        >
                          <TableCell align="left" onClick={()=>handleProductClick(row)}>
                            {row.productName || (row.product && row.product.productName)}
                          </TableCell>
                          <TableCell align="left" style={{color: row.safetyStock > row.availableQty? "red":""}}>
                            {row.availableQty || (row.product && row.product.availableQty) || 0}
                          </TableCell>
                          <TableCell align="left" style={{color: row.product && row.product.sysComputed===true ? "red":""}}>
                            {(row.product && row.product.price) || 0}
                          </TableCell>
                          <TableCell align="left">
                            {(row.subLocation) || "NA"}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                </TableBody>
              )}
          </Table>
        </TableContainer>
      </div>
      {data && count > pageSize ? (
        <Grid container justify="flex-end">
          <Grid item>
            <Pagination
              style={{ paddingTop: 15 }}
              count={
                Math.floor(count / pageSize) + (count % pageSize > 0 ? 1 : 0)
              }
              color="secondary"
              variant="outlined"
              defaultPage={1}
              page={query.page}
              onChange={(event, value) => {
                setQuery({ ...query, page: value });
              }}
              size="medium"
            />
          </Grid>
        </Grid>

      ) : (
          <div />
        )}
      <Grid
        item
        container
        xs={12}
        spacing={2}
        alignItems="flex-start"
        justify="flex-end"
      >
        <Grid item>
        </Grid>
      </Grid>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          Some error occured. Please retry after sometime.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isWarning}
        autoHideDuration={5000}
        onClose={() => setIsWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          Product already exists in the list
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ManageProducts;
