/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { renderToString } from 'react-dom/server'
import {
  useForm,
  useFieldArray,
  Controller,
  FormContext,
} from "react-hook-form";
import CustomerEditForm from '../recordSales/CustomerEditForm';
import ConsigneeForm from '../recordSales/ConsigneeForm';
import DispatchAddressForm from '../recordSales/DispatchAddressForm';
import ErrorLists from '../RecordSaleNew/errorLists';
import ErrInvoiceReportIcon from "../../images/ErrInvoiceReport.svg";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import Carousel from "react-material-ui-carousel";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import "react-html5-camera-photo/build/css/index.css";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { currentUser } from "../login/loginSlice";
import {
  paymentModes
} from "../common/lookupSlice";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import SignaturePad from "react-signature-canvas";
import ConfirmationDialog from "../common/ConfirmationDialog";
import warrantyCard from "../../images/Warranty Card.svg";
import userManual from "../../images/User Manual.svg";
import machineDiagram from "../../images/Machine Diagram.svg";
import calculateIcon from '../../images/calculate.svg';
import Link from "@material-ui/core/Link";

import Products from "../products/Products";
import ServicePartsProducts from "../products/ServicePartsProducts";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Dialog,
  InputAdornment,
  DialogTitle,
  Paper,
  Grid,
  Backdrop,
  Snackbar,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  FormHelperText,
  Select,
  TextField,
  IconButton,
  DialogContent,
  Button,
  Typography,
  DialogActions,
  Breadcrumbs,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { StyledHeaderCell } from "../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import CloseIcon from "@material-ui/icons/Close";
import Payment from '../recordSales/Payment';
import axios from "axios";
import { API_ALL_JOBS, API_PROD_NAME } from "../../config/constants";

import {
  jobCategories,
  jobStatuses,
  jobTypes,
  technicians,
  locations,
  invoiceTypes
} from "../common/lookupSlice";
import "./jobCard.css";
import CustomerDetails from "./CustomerDetails";
import { closeModal } from "../common/confirmationDialogSlice";
import ReactToPrint from "react-to-print";
import CompToPrintInv from '../reports/CompToPrintInv';
import CompToPrintEstimate from './CompToPrintEstimate';
import JobToPrint from './jobToPrint';
import Calculator from '../recordSales/calculator';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DepositInvoice = (props) =>{
   const { setPayment, setPaymentId, isServiceAdvisor,attachInvoice, errors, register, clearError, value, setValue } = props;
   const payTypes = useSelector(paymentModes);
   const handleRadioChange = (event) => {
    const selectedPayment = Number(event.target.value);
    setValue(selectedPayment);
  };

  const handleChange = (event,field) => {
    if(field==="details"){
      setPayment(event.target.value)
    }
    else if(field==="id"){
     setPaymentId(event.target.value)
    }
  }
  
  return (
  <Grid container style={{ minHeight: 50 }} spacing={2}>
  <Grid
    item
    container
    alignItems="center"
    justify="flex-end"
  >
    <Grid item md={4} xs={4}>
      Receipt No.
    </Grid>
    <Grid item md={8} xs={8}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        disabled
        name="tempInvoice"
        defaultValue={attachInvoice.invoiceNum ? attachInvoice.invoiceNum : "Auto Generated..."}
      />
    </Grid>
  </Grid>
  <Grid
    item
    container
    alignItems="center"
    justify="flex-end"
  >
    <Grid item md={4} xs={4}>
      Amount
    </Grid>
    <Grid item md={8} xs={8}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        autoFocus
        type="number"
        disabled={!isServiceAdvisor || (attachInvoice.invoiceNum ? true : false)}
        error={Boolean(errors.tempAmount)}
        defaultValue={attachInvoice.amount}
        onChange={(e) => {
          clearError("tempAmount");
        }}
        name="tempAmount"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">₹</InputAdornment>
          ),
        }}
        inputRef={register({
          required: "Amount is required",
          pattern: {
            value: /^\d+(\.\d{1,2})?$/,
            message: "Numbers and max two decimal places allowed",
          },
          min: {
            value: 1,
            message: "Minimum 1 Rupee needed",
          },
        })}
      />
      <FormHelperText error>
        {errors.tempAmount && errors.tempAmount.message}
      </FormHelperText>
    </Grid>
  </Grid>
  { !(attachInvoice.invoiceNum) && <Grid
    item
    container
    alignItems="center"
    justify="flex-start"
  >
    <Grid item md={12} xs={12}>
      <Typography>Payment Mode</Typography>
    <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleRadioChange}>
          <Grid container md={12} xs={12}>
            {payTypes.map(x => {
              return <Grid item xs={6}><FormControlLabel style={{ color: 'red8' }} value={x.id} control={<Radio />} label={x.description} /></Grid>
            })}
          </Grid>  
          </RadioGroup>
          <Box ml={4}>
              <Typography variant="caption">Payment ID (Transaction ID/Cheque No/Approval Code)</Typography>
              <TextField
                error={Boolean(errors.pid)}
                variant="outlined"
                fullWidth
                size="small"
                name="pid"
                onChange={(e) => handleChange(e, "id")}
                inputRef={register({
                  required: value !==6 && value !==1  && "Enter Payment id",
                })}
              />
              <FormHelperText error>
              {errors.pid && errors.pid.message}
              </FormHelperText>
            </Box>
          <Box ml={4}>
              <Typography variant="caption">Payment Details (Bank & Branch Name,Payee Details,Issuing Bank/Card Type)</Typography>
              <TextField
                error={Boolean(errors.details)}
                variant="outlined"
                fullWidth
                size="small"
                name="details"
                inputRef={register({
                  required: value !==6 && value !==1 && "Enter Payment details",
                })}
                onChange={(e) => handleChange(e, "details")}
              />
              <FormHelperText error>
              {errors.details && errors.details.message}
              </FormHelperText>
            </Box>
        </FormControl>
    </Grid>
  </Grid>}
</Grid>
  )
}

const GenerateEInvoice = (props) =>{
  let invoiceDetails = props.invoiceDetails;
 return (
 <Grid container style={{ minHeight: 50 }} spacing={2}>
 <Grid
   item
   container
   alignItems="center"
   justify="flex-end"
 >
   <Grid item md={4} xs={4}>
     Invoice No.
   </Grid>
   <Grid item md={8} xs={8}>
     <TextField
       variant="outlined"
       size="small"
       fullWidth
       disabled
       name="tempInvoice"
       defaultValue={invoiceDetails.invoiceNum ? invoiceDetails.invoiceNum : "Auto Generated..."}
     />
   </Grid>
 </Grid>
 <Grid
   item
   container
   alignItems="center"
   justify="flex-end"
 >
   <Grid item md={4} xs={4}>
     Invoice Amount
   </Grid>
   <Grid item md={8} xs={8}>
     <TextField
        variant="outlined"
        size="small"
        fullWidth
        disabled
        name="tempInvoice"
        defaultValue={invoiceDetails.total}
       InputProps={{
         startAdornment: (
           <InputAdornment position="start">₹</InputAdornment>
         ),
       }}
     />
   </Grid>
 </Grid>

</Grid>
 )
}

const JobCard = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const componentRefInv = useRef();
  const componentRefEstimate = useRef();
  const buttonRef = useRef();
  const componentRefJob = useRef();
  const jobButtonRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const jCategories = useSelector(jobCategories);
  const jStatuses = useSelector(jobStatuses);
  const jTypes = useSelector(jobTypes);
  const techies = useSelector(technicians);
  const loggedInUser = useSelector(currentUser);
  const corporateLoc = location && location.state &&location.state.jobLocData
  const allInventoryLocations = loggedInUser && loggedInUser.locations ? loggedInUser.locations : [];
  const serviceInventoryLocations = allInventoryLocations.filter(loc => loc.type !== "CORPORATE");
  const [initialLoc, setInitialLoc] = useState([]);
  const qrCode = localStorage.getItem("qrCodeStatus");
  let qrCodeStatus = (qrCode && qrCode === "true")?true:false;
  const roles = loggedInUser && loggedInUser.roles ? loggedInUser.roles : [];
  const isAdmin =
    roles.findIndex((role) => role.name === "ADMIN") > -1 ? true : false;
  const isServiceAdvisor =
    roles.findIndex((role) => role.name === "SERVICE ADVISOR") > -1
      ? true
      : false;
  const isTechnician =
    roles.findIndex((role) => role.name === "TECHNICIAN") > -1 ? true : false;
  const token = localStorage.getItem("authToken");
  const signRef = useRef({});

  const [productModal, setProductModal] = useState({
    isForServiceParts: false,
    open: false,
  });
  const [attachInvoice, setAttachInvoice] = useState({
    index: -1,
    invoiceNum: "",
    amount: "",
    open: false,
  });

  const invoiceType = useSelector(invoiceTypes);
  const inv_service = invoiceType.filter(type=> type.code === "SERVICE");
  const inv_deposit = invoiceType.filter(type=> type.code === "DEPOSIT");
  const [isLoading, setIsLoading] = useState(false);
  const [newDeposit, setNewDeposit] = useState(false);
  const [openCarousel, setOpenCarousel] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [jobPrint, setJobPrint] = useState(false);
  const [calculate, setCalculate] = useState(null);
  const [ generated, setGenerated ] = useState(false);
  const [ invoiceNo, setInvoiceNo ] = useState('');
  const [invoice, setInvoice] = useState([]);
  const [quote, setQuote] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invError, setInvError] = useState(false);
  const [payments, setPayments] = useState({ open: false });
  const [paymentId, setPaymentId] = useState(1)
  const [paymentData, setPaymentData] = useState("")
  const [paymentDetails, setPaymentDetails] = useState("")
  const [cameraOpen, setCameraOpen] = useState(false);
  const [signCanvasStyle, setSignCanvasStyle] = useState("sigCanvas");
  const [ depositProd, setDepositProd ] = useState();
  const [ prodInv, setProdInv ] = useState({ success: false });
  const [ message, setMessage ] = useState();
  const [ paymentMode, setPaymentMode ] = useState(1);
  const [payment, setPayment] = React.useState('');
  const [paymentIdDep, setPaymentIdDep] = React.useState('');
  const warningMessage = "Quantity exceeds Availability. Contact Admin to increase Availability at your Store.";
  const [ isWarning, setIsWarning ] = useState(false);
  const [ isProdWarning, setIsProdWarning ] = useState(false);
  const [machineErrorClass, setMachineErrorClass] = useState(
    "machineNoErrorCanvas"
  );
  const [inspectCondErrorClass, setInspectCondErrorClass] = useState(
    "machineNoErrorCanvas"
  );
  const [isEmailMessage, setMailMessage] = useState(false);
  const [isSendInvoice, setSendInvoice] = useState(false);
  const [isSendQuote, setSendQuote] = useState(false);
  const [isSendDepInvoice, setSendDepInvoice] = useState(false);
  const [isEmailHelpertext, setEmailHelperText] = useState(false);
  const [isEmailSubjectHelpertext, setEmailSubjectHelperText] = useState(false);
  const [ emailSendData, setEmailSendData ] = useState({
    emailId:'',
    emailSubject:''
  });
  let  { emailId, emailSubject} = emailSendData;
  const [qtyDecimal, setQtyDecimal ] = useState([]);
  const [uom, setUOM] = useState([]);
  const [data, setData] = useState({
    serviceParts: [],
    deposits: [],
    images: [],
  });
  const [imgData, setImgData] = useState([])
  const [orgDetails, setOrgDetails] = useState(null);
  const [custDetails, setCustDetails] = useState(null);

  const [isServicePartMessage, setServicePartMessage] = useState(false);
  let { id } = useParams();
  if (id) {
    data.id = id;
  }

  const [HSNCodeData, setHSNData] = useState([]);
  const [errHSNProIndex, setErrHSN] = useState([]);
  const [priceData, setPriceData] = useState({ open: false });

  const [totalAmount, setTotalAmount] = useState(0);
  const [balanceAmount, setBalanceAmount] = useState({});
  const [amountPaid, setAmountPaid] = useState(0);
  const [paymentType,setPaymentType] = useState({});
  const [refundAmount, setRefundAmount] = useState(0);
  const [calData,setCalData] = useState();
  const [sellerDetails, setSellerDetails] = useState(null);
  const [showCustForm, setShowCustForm] = useState(false);
  const [editCustDetails, setEditCustDetails] = useState(null);
  const [showConsingeeForm, setShowConsingeeForm] = useState(false);
  const [editConsigneeDetails, setEditConsingeeDetails] = useState(null);
  const [showDispatchForm, setShowDispatchForm] = useState(false);
  const [editDispatchDetails, setEditDispatchDetails] = useState(null);
  const [isEditAll, setEditAllDetails] = useState(false);
  const [isEInvoice, setEInvoice] = useState(false);
  const [invDetails, setInvDetails] = useState(null);
  const [showEinvoice, setShowEinvoice] = useState(false);
  const [isBDNotFilled, setBDNotFilled] = useState(false);
  const [isCDNotFilled, setCDNotFilled] = useState(false);
  const [isDDNotFilled, setDDNotFilled] = useState(false);
  const [isRequiredDetailsNot, setRequiredDetailsNot] = useState(false);
  const [eInvEWBErrorDetails, setErrorDetails] = useState([]);
  const [showInvErr, setShowInvErr] = useState(false);
  const [errQtyProIndex, setErrQtyProd] = useState([]);

  const handleDateChange = (date) => {
    setData({
      ...data,
      expectedDeliveryDate: date,
    });
  };

  const getCustomerInvoice = async (invNum) => {
    let genInvNum = (invNum)?invNum:invoiceNo
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/invoices/${genInvNum}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const sales = result.data && result.data;
    return sales;
  }
  const handleInvoicePrint = async (invNum) => {
    try {
      let data;
      if(invNum){
        data = await getCustomerInvoice(invNum);
      }else{
        data = await getCustomerInvoice();
      }
      
      setInvoice(data);      
    } catch (err) {
      setIsError(true);
    }
  }

  const handleProductSelection = (product) => {
    if (!productModal.isForServiceParts) {
      data.product = product;
      data.brandName = data.product && data.product.brand ? data.product.brand.description : "";      
      data.mfgName = data.product && data.product.manufacturer ? data.product.manufacturer.description : "";
      data.model = data.product && data.product.model ? data.product.model : "";
      data.serialNumber = data.product && data.product.serialNumber ? data.product.serialNumber : "";
      data.isQtyDecimal = data.product && data.product.isQtyDecimal ? data.product.isQtyDecimal : "";
      //data.productId = product.id;
      setProductModal({
        ...productModal,
        open: false,
      });
      clearError("productId");
      setMachineErrorClass("machineNoErrorCanvas");
    } else {
      var sps = getValues({ nest: true })["serviceParts"];
      let existing = sps.some(x=> product.product && Number(x.productId) === product.product.id )
      if(!existing){
      sps[productModal.servicePartsIndex] = {
        ...sps[productModal.servicePartsIndex],
        qty: 1,
        productName: product.product.productName,
        HSNCode: product.product.HSNCode,
        price: product.product.price ? (Math.round(product.product.price * 100) / 100).toFixed(2):'0.00' ,
        productId:product.product.id,
        product: product.product,
        isSpecial:product.product.isSpecial,
        availability:
        product.availableQty && product.availableQty > 0
            ? product.availableQty
            : "Not Available",
        uom: product.product.uom,
        isQtyDecimal: product.product.isQtyDecimal
      };
      uom[productModal.servicePartsIndex] = product.product.uom;
      qtyDecimal[productModal.servicePartsIndex] = product.product.isQtyDecimal;
      setQtyDecimal(qtyDecimal);
      setUOM(uom);
      setValue("serviceParts", sps);
      setProductModal({
        ...productModal,
        open: false,
      });
    }
    else{
      setIsProdWarning(true)
    }
      clearError(`serviceParts[${productModal.servicePartsIndex}].productName`);
      clearError(`serviceParts[${productModal.servicePartsIndex}].HSNCode`);
      clearError(`serviceParts[${productModal.servicePartsIndex}].qty`);
      clearError(`serviceParts[${productModal.servicePartsIndex}].price`);
      clearError(`serviceParts[${productModal.servicePartsIndex}].isSpecial`);
    }
  };

  const handleCustomerSelection = (customer) => {
    data.customerId = customer.id;
    data.customer = customer;
    clearError("customerId");
  };

  const methods = useForm();
  const calculateMethods = useForm();

  const {
    handleSubmit,
    form,
    control,
    errors,
    setValue,
    reset,
    getValues,
    register,
    clearError,
  } = methods;

  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "serviceParts",
  });

  const handleCheckout = async() => {
    if(partsData&& partsData.length>0){
      let isPrice = 0;
      for(var index=0; index<partsData.length; index++){
        isPrice += (partsData[index] && partsData[index].price && parseFloat(partsData[index].price));
      }
      if(isPrice && isPrice>=1){
        try{
          const reqObj = {
            customerId:data.customer.id,
            custTypeId:data.customer.custTypeId,
            locationId:data.locationId && data.locationId,
            lineItems:lineItems
          }
          const result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
            reqObj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const cal_data = result.data && result.data;
          cal_data.orgCode= data.customer.orgCode
          cal_data.customerId= data.customer.id
          cal_data.custTypeId= data.customer.custTypeId
          cal_data.locationId= data.locationId && data.locationId
          cal_data.invoiceDate=moment().format('MM/DD/YYYY')
          cal_data.jobId = data.id
          const depositArr = data.deposits ? data.deposits.map(x=>x.amount):[];
          const depositTotal = depositArr.reduce((accumulator, currentValue) => accumulator + currentValue,0);
          cal_data.typeId = inv_service && inv_service[0].id;
          setTotalAmount(cal_data.total)
          setBalanceAmount({...balanceAmount, total:(cal_data.total && (depositTotal || depositTotal == 0) ? cal_data.total-depositTotal:0)});
          setCalData(cal_data);
        }catch(err){
          setIsError(true);
          setIsProcessing(false);
          setPayments({ ...payments, open: false });
          console.log(err);
        }
        setPayments({ ...payments, open: true })
      }else{
        setMessage("Can't generate invoice for zero amount.")
        setIsError(true);
      }
    }else{
      setMessage("Can't generate invoice for zero amount.")
      setIsError(true);
    }
    
  }

  
const saveSnap = async (dataURI,jobData,indexNum) => {
  try {
    var dataURIArr = dataURI.split(',');
   let  mime = dataURIArr[0].match(/:(.*?);/)[1];
    let binaryString = window.atob(dataURIArr[1]);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
       const ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    let fileData = new File([bytes], `${jobData.orgCode}_JobNum_${jobData.id}_CustId_${jobData.customerId}_SlNo_${indexNum}.png`, {type:mime});
    const formdata = new FormData();
    formdata.append("file", fileData);
    const result = await axios.post(
      `${process.env.REACT_APP_SERVICE_URL}/uploadfile`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(result && result.data && result.data.publicUrl){
      setImgData(imgData.push(result.data.publicUrl));
    }
  } catch (err) {
    console.log("err",err)
  }
};

  const onSubmit = async (formData, type) => {
    setIsProcessing(true);
    setServicePartMessage(false);
    try {
      formData.customerSignature =
        props.isNew && !signRef.current.isEmpty()
          ? signRef.current.getTrimmedCanvas().toDataURL("image/jpeg")
          : data.customerSignature;
      formData.deposits = data.deposits;
      formData.brandName = data.brandName;
      formData.mfgName = data.mfgName;
      formData.model = data.model;
      formData.serialNumber = data.serialNumber;
      
      if (props.isNew) {
        var d = formData.expectedDeliveryDate.split("/");
        formData.expectedDeliveryDate = d[1] + "/" + d[0] + "/" + d[2];
        d = formData.createdOn.split(" ")[0].split("/");
        formData.createdOn = d[1] + "/" + d[0] + "/" + d[2];
        formData.images = []; //JSON.stringify(data.images);
        if (!signRef.current.isEmpty()) {
          const result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/jobs`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          //_id = result.data.job.id;
          //_id && history.push("/jobs/" + _id);
          let jobLoc = serviceInventoryLocations.filter(loc => loc.id === formData.locationId);
          setInitialLoc(jobLoc);
          if(data.images && data.images.length>0){
            for(var i=0; i < data.images.length; i++){
              await saveSnap(data.images[i], result.data.job, i)
            }
          }
          if(imgData && imgData.length >0){
           await axios.post(
              `${process.env.REACT_APP_SERVICE_URL}/jobs/uploadfile/${result.data.job.id}`,
              {images: imgData},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
          data.id = result.data.job.id;
          setIsSuccess(true);
        } else {
          setSignCanvasStyle("sigErrorCanvas");
        }
      } else {
        let isContinue = true;
        let errqtyProd = [];
        if(formData.statusId === 5){
          isContinue = (formData.serviceParts && formData.serviceParts.length>0)?true:false;
        }
        if(isContinue && !(data.statusId === 5) && formData.statusId === 5){
          formData.serviceParts.forEach((x, y) => {
            let isQtyErr = (parseFloat(x.qty) <= parseFloat(x.availability)) ? false : true;
            if(isQtyErr){
              errqtyProd.push(y)
            }
          })
        }
        if(isContinue && (errqtyProd.length===0)){
          setErrQtyProd([])
          const d = formData.expectedDeliveryDate.split("/");
          formData.expectedDeliveryDate = d[1] + "/" + d[0] + "/" + d[2];
          const invCheck = formData.serviceParts &&
          formData.serviceParts.filter(sp=> sp.qty > sp.availability);
  
          (invCheck && invCheck.length >0) && setIsWarning(true);
          await axios.patch(
            `${process.env.REACT_APP_SERVICE_URL}/jobs/${formData.id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          //history.go();
          if(type === "DEPOSIT"){
            setAttachInvoice({
              index:
                data && data.deposits && data.deposits.length,
              jobId: data.id,
              invoiceNum: "",
              amount: "",
              open: true,
            });
          }else{
            setIsSuccess(true);
          }
        }else{
          if(!isContinue){
            setServicePartMessage(true)
          }
          setErrQtyProd(errqtyProd)
        }
      }
      setIsProcessing(false);
    } catch (err) {
      setIsError(true);
      setIsProcessing(false);
      console.log(err);
    }
  };

  const loadJob = async () => {
    if (props.isNew) {
      reset({
        ...data,
        createdOn: moment(new Date()).format("DD/MM/YYYY"),
      });

      return;
    } else {
      setIsProcessing(true);

      try {
        const result = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/${API_ALL_JOBS}/${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        result.data.createdOn = moment(result.data.createdOn).format(
          "DD/MM/YYYY"
        );
        reset({ ...result.data });
        setData({ ...result.data });
        setOrgDetails(result.data && result.data.org);
        setCustDetails(result.data && result.data.customer);
        if(result.data && result.data.invoiceNum){
          setInvoiceNo(result.data && result.data.invoiceNum);
        }
        const invCheck = result.data.serviceParts &&
        result.data.serviceParts.filter(sp=> sp.qty > (sp.product && sp.product.inventories && 
          (sp.product.inventories.filter(inv=> inv.locationId === (corporateLoc && corporateLoc[0].id))[0].openingQty)));
        (invCheck.length >0) && setIsWarning(true);
        if(result.data && result.data.invoiceNum){
          const invRes = await axios.get(
            `${process.env.REACT_APP_SERVICE_URL}/invoices/${result.data.invoiceNum}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setInvDetails(invRes && invRes.data);
          setEInvoice(invRes && invRes.data && invRes.data.isEInvoice);
          setEditAllDetails((invRes && invRes.data && invRes.data.isEInvoice && !invRes.data.eInvIRN )?true:false)
          if(invRes && invRes.data && invRes.data.invOrgDetails){
            setSellerDetails(invRes.data.invOrgDetails);
          }else{
            let sellerData= {
              GSTIN: result.data && result.data.org && result.data.org.GSTIN,
              tradeName: result.data && result.data.org && result.data.org.name,
              address1: result.data && result.data.org && result.data.org.address1,
              address2: result.data && result.data.org && result.data.org.address2,
              address3: null,
              locality:  result.data && result.data.org && result.data.org.address3,
              pincode: result.data && result.data.org && result.data.org.pincode,
              state: result.data && result.data.org && result.data.org.state,
              phoneNo: result.data && result.data.org && result.data.org.phoneNo,
              email: result.data && result.data.org && result.data.org.email
              };
            setSellerDetails(sellerData);
          }
          
          if(invRes && invRes.data && invRes.data.invCustmerDetails){
            setEditCustDetails({...invRes.data.invCustmerDetails, stateCode:invRes.data.invCustmerDetails.state });
          }else{
            let editCust = {
              tradeName: result.data && result.data.customer && result.data.customer.fullName,
              email: result.data && result.data.customer && result.data.customer.email,
              mobileNumber: result.data && result.data.customer && result.data.customer.mobileNumber,
              address1: result.data && result.data.customer && result.data.customer.address1,
              address2: result.data && result.data.customer && result.data.customer.address2,
              address3: result.data && result.data.customer && result.data.customer.address3,
              pincode: result.data && result.data.customer && result.data.customer.pincode,
              locality: result.data && result.data.customer && result.data.customer.locality,
              state: result.data && result.data.customer && result.data.customer.state,
              GSTIN: result.data && result.data.customer && result.data.customer.GSTIN,
              stateCode: result.data && result.data.customer && result.data.customer.state
            }
            setEditCustDetails(editCust);
          }
          if(invRes && invRes.data && invRes.data.invShipDetails){
            setEditConsingeeDetails({...invRes.data.invShipDetails, stateCode:invRes.data.invShipDetails.state});
          }else{
            let editConsignee = {
              tradeName: result.data && result.data.customer && result.data.customer.fullName,
              address1: result.data && result.data.customer && result.data.customer.address1,
              address2: result.data && result.data.customer && result.data.customer.address2,
              address3: result.data && result.data.customer && result.data.customer.address3,
              pincode: result.data && result.data.customer && result.data.customer.pincode,
              locality: result.data && result.data.customer && result.data.customer.locality,
              state: result.data && result.data.customer && result.data.customer.state,
              GSTIN: result.data && result.data.customer && result.data.customer.GSTIN,
              stateCode: result.data && result.data.customer && result.data.customer.state
            }
            setEditConsingeeDetails(editConsignee);
          }
          if(invRes && invRes.data && invRes.data.invDispatchDetails){
            setEditDispatchDetails({...invRes.data.invDispatchDetails, stateCode:invRes.data.invDispatchDetails.state});
          }else{
            let editDispatch = {
              tradeName: result.data && result.data.org && result.data.org.name,
              address1: result.data && result.data.org && result.data.org.address1,
              address2: result.data && result.data.org && result.data.org.address2,
              pincode: result.data && result.data.org && result.data.org.pincode,
              locality: result.data && result.data.org && result.data.org.address3,
              state: result.data && result.data.org && result.data.org.state,
              stateCode: result.data && result.data.org && result.data.org.state
            }
            setEditDispatchDetails(editDispatch);
          } 
          if(invRes && invRes.data && invRes.data.invErrorList && invRes.data.invErrorList.length>0){
            setErrorDetails(invRes.data.invErrorList)
          }
        }
        const hsncoderes = await axios.get(
          `${process.env.REACT_APP_SERVICE_URL}/hsncode`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if(hsncoderes.status === 200){
          if(hsncoderes.data && hsncoderes.data.length>0){
            let hsnCodes = [];
            await hsncoderes.data.map((hsnData)=>{
              hsnCodes.push(hsnData.code);
              return hsnData;
            })
            setHSNData(hsnCodes);
          }
        }
      } catch (err) {
        setIsError(true);
        console.log(err);
      }
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    loadJob();// eslint-disable-next-line
    const results = depositProduct();// eslint-disable-next-line
    setJobPrint(true)
  }, []);// eslint-disable-next-line

  useEffect(() => {
    if(invoiceNo && (data.status && data.status.code === "WC")){
     buttonRef && buttonRef.current && buttonRef.current.click()
    }
  }, [invoiceNo]);

  useEffect(() => {
    if(invoiceNo && newDeposit){
     buttonRef && buttonRef.current && buttonRef.current.click()
    }
  }, [newDeposit]);

  useEffect(() => {
    Boolean(errors.productId)
      ? setMachineErrorClass("machineErrorCanvas")
      : setMachineErrorClass("machineNoErrorCanvas");
    Boolean(errors.inspectCondition)
      ? setInspectCondErrorClass("machineErrorCanvas")
      : setInspectCondErrorClass("machineNoErrorCanvas");
  }, [errors]);

  const resetValues = () => {
    history.go();
  };

  // const validateInvoice = async () => {
  //   await triggerValidation("tempInvoice");
  //   await triggerValidation("tempAmount");

  //   if (
  //     !Boolean(errors.tempInvoice) &&
  //     data.deposits &&
  //     data.deposits.length > 0
  //   ) {
  //     const t =
  //       data.deposits &&
  //       data.deposits.findIndex((i) => {
  //         return i.invoiceNum === getValues("tempInvoice");
  //       });

  //     if (t >= 0 && t !== attachInvoice.index) {
  //       setError("tempInvoice", "unique", "Invoice Number must be unique");
  //     } else {
  //       clearError("tempInvoice");
  //     }
  //   }
  // };

  const saveInvoice = async (e) => {
    //await validateInvoice();
    setIsLoading(true)
    if (!Boolean(errors.tempAmount)) {

      setIsProcessing(true);
    try{
    const depositItems = [{
      productId: depositProd.id,
      qty: 1,
      overridenUnitPrice: parseFloat(getValues("tempAmount"))
    }];
    const reqObj = {
      customerId:data.customer.id,
      custTypeId:data.customer.custTypeId,
      locationId:data.locationId && data.locationId,
      lineItems:depositItems
    }
    const result = await axios.post(
      `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
      reqObj,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const cal_data = result.data && result.data;
    cal_data.orgCode= data.customer.orgCode
    cal_data.customerId= data.customer.id
    cal_data.custTypeId= data.customer.custTypeId
    cal_data.locationId= data.locationId && data.locationId
    cal_data.invoiceDate=moment().format('MM/DD/YYYY')
    cal_data.jobId = data.id
    cal_data.typeId = inv_deposit && inv_deposit[0].id
    cal_data.jobId = data.id
      const payments = [
        {
          "status": "PAID",
          "paymentModeId": paymentMode,
          "paymentId": paymentIdDep,
          "paymentDetails": payment,
          "amount": Math.round(cal_data.total)
        }
      ]
      cal_data.payments = payments;
      const inv_result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices`,
        cal_data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
     const resStatus = inv_result && inv_result.data && inv_result.data.status;
     setInvoiceNo(inv_result && inv_result.data.invoiceNum);
     setMessage(inv_result && (inv_result.data.invoiceNum +" - Deposit Advice generated successfully"));
     setNewDeposit(true)
      setIsProcessing(false);
      setIsLoading(true)
      if(resStatus==="SUCCESS"){
      setProdInv({
        ...prodInv,
        success: true,
      });
      data.deposits[attachInvoice.index] = {
        lineId: attachInvoice.lineId,
        jobId: attachInvoice.jobId,
        invoiceNum: inv_result && inv_result.data.invoiceNum,
        amount: parseFloat(getValues("tempAmount")),
      };
      setAttachInvoice({
        ...attachInvoice,
        open: false,
      });
      }
      setPaymentMode(1)
      setNewDeposit(false)
    }
      catch(err){
        setIsLoading(false);
        setNewDeposit(false);
        setIsError(true);
        setIsProcessing(false);
        setAttachInvoice({ ...attachInvoice, open: false });
        console.log(err);
      }
    }
  };
  const handleCloseModal = (value) => {
    dispatch(closeModal());
  };

  const depositProduct = async ()=>{
    const result = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL}/${API_PROD_NAME}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setDepositProd(result.data.products && result.data.products[0]);
  }
  const lineItems = data.serviceParts && data.serviceParts.map(x => {
    const lineItem = {
      productId: x.productId,
      qty: x.qty,
      overridenUnitPrice: x.price,
      overridenHSNCode: x.HSNCode && x.HSNCode,
    }
    return lineItem;
  });

  // const handleClear = async () => {
  //   setCalculate(null)
  // }

  const handleQuote = async () => {
    const lineItemData = calculate && calculate.lineItems ? calculate.lineItems.map((item,key)=>{
      return{
        "lineNo": key+1,
        "productName": item.productName,
        "HSNCode": item.HSNCode,
        "IGST": item.IGST,
        "CGST": item.CGST,
        "SGST": item.SGST,
        "uom": item.uom,
        "qty": item.qty,
        "unitPrice": item.unitPrice,
        "salePrice": item.salePrice,
        "discountPct": item.discountPct,
        "amount": item.amount,
        "isSpecial":item.isSpecial,
        "isQtyDecimal":item.isQtyDecimal,
      }
    }):[]
    
    let estimateData={
      "jobNo": data.id,
      "invoiceDate": new Date(),
      "subtotal": calculate.subtotal,
      "CGST": calculate.CGST,
      "SGST": calculate.SGST,
      "IGST": calculate.IGST,
      "total": calculate.total,
      "roundOff": calculate.total ? (Math.round((Math.round(calculate.total)-calculate.total) * 100) / 100).toFixed(2):0,
      "org": data.org,
      "lineItems": lineItemData,
      "customer": data.customer,
      "amountInWords": calculate.amountInWords
    }
    setQuote(estimateData)
  }

  const handleCalculate = async (fdata) =>{
    const ser = getValues({ nest: true })["serviceParts"];
    setIsProcessing(true);
    try{
      let errHSN = [];
      const lineItemsReq = ser && ser.map((x, y) => {
        let isErr = (_.includes(HSNCodeData, x.HSNCode)) ? false : true;
        if(isErr){
          errHSN.push(y);
        }
        const lineItem = {
          productId: x.productId && Number(x.productId),
          qty: x.qty && Number(x.qty),
          overridenHSNCode: x.HSNCode && Number(x.HSNCode),
          overridenUnitPrice: x.price && Number(x.price),
        }
        return lineItem;
      });
      const reqObj = {
        customerId:data.customer.id,
        custTypeId:data.customer.custTypeId,
        locationId:data.locationId && data.locationId,
        lineItems:lineItemsReq
      }
      if(errHSN.length === 0){
        setErrHSN([]);
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
        reqObj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCalculate(result.data)
      setIsProcessing(false);
      }else{
        setErrHSN(errHSN);
        setIsProcessing(false);
      }
    }
    catch(err){
      console.log(111,err)
      if(err && err.response && err.response.data && err.response.data.message === "Couldn't compute the sale amount and taxes"){
        setMessage("Error occured !! Selected Service part's Price/Availability is zero")
      }
      setIsError(true);
      setIsProcessing(false);
    }
  }
  const handleInvCalGen = async (invData) =>{
    setIsProcessing(true);
    try{
      const cal_data = _.cloneDeep(calData);
      const payments = [
        {
          "status": "PAID",
          "paymentModeId": ((balanceAmount && balanceAmount.total<=0) || (paymentType && paymentType.id && paymentType.id===3))?null:paymentId,
          "paymentId": paymentData,
          "paymentDetails": paymentDetails,
          "amount": Math.round(totalAmount),
          "paymentTypeId": paymentType && paymentType.id ,
          "totalAmount": Math.round(balanceAmount && balanceAmount.total),
          "paidAmount": (balanceAmount.total<=0) ? 0 : (paymentType && paymentType.id && paymentType.id===1) ? (balanceAmount && balanceAmount.total) : amountPaid,
          "refundAmount": ((balanceAmount && balanceAmount.total<0 && (paymentType && paymentType.id && paymentType.id===1))?((Math.round(balanceAmount.total * 100) / 100).toFixed(2)*-1):refundAmount),
        }
      ]
      cal_data.isEInvoice = ((custDetails && custDetails.custTypeId ===2) && qrCodeStatus)? true : false;
      cal_data.isBtoB = (custDetails && custDetails.custTypeId ===2) ? true : false;
      cal_data.payments = payments;
      cal_data.remarkNote = data && data.notes; 
      const inv_result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices`,
        cal_data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
     const resStatus = inv_result && inv_result.data && inv_result.data.status;
     setMessage(inv_result && inv_result.data.message);
     setInvoiceNo(inv_result && inv_result.data.invoiceNum);
      setIsProcessing(false);
      if(resStatus==="SUCCESS"){
      setProdInv({
        ...prodInv,
        success: true,
      });
      }      
      setGenerated(true);
      setPayments({ ...payments, open: false });
      setPaymentId(1)
    }
      catch(err){
        setIsError(true);
        setIsProcessing(false);
        setPayments({ ...payments, open: false });
        console.log(err);
      }
  } 
  
  
 const openEmailInovice = async () =>{
  try {
    const data = await getCustomerInvoice();
    setInvoice(data);
    setSendInvoice(true);
    setSendQuote(false);
    setSendDepInvoice(false)
    setEmailSendData({ emailSubject: 'Vindhya Associates - Invoice',
                       emailId: data&&data.customer&&data.customer.email
                    })
   } catch (err) {
    setIsError(true);
   }
  }
const onCloseSendInvoice = () =>{
   setSendInvoice(false);
   setSendQuote(false);
   setSendDepInvoice(false);
   setEmailSendData({emailId:'',emailSubject:''})
  }
const onSendEmailInvoice =async () =>{
      let isEmail = false;
      let isSubject = (emailSubject&&emailSubject.length>1)?true:false;
      if(emailId&&emailId.length>0){
        isEmail = true;
        let emailIds = emailId.split(';');
        const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        _.forEach(emailIds,(data)=>{
          if (!mailformat.test(String(data).toLowerCase())){
            isEmail = false;
          }
        })
      }
      if(isEmail&&isSubject){
          setEmailHelperText(false);
          setEmailSubjectHelperText(false);
          let invoiceBodyData = () =>{
            return(
              <CompToPrintInv ref={componentRefInv} customerInv={invoice} />
              )
          }  
          var bodyFormData = new URLSearchParams();
          bodyFormData.append('to',emailId);
          bodyFormData.append('subject',emailSubject);
          bodyFormData.append('invoice',renderToString(invoiceBodyData()));
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/x-www-form-urlencoded',
              'Content-Length': bodyFormData.length
            }
          }
          const result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
            bodyFormData,
            config
          );
          if(result.status===200){
            setMessage(result.data&&result.data.message)
            setMailMessage(true)
            setSendInvoice(false);
            setEmailSendData({emailId:'',emailSubject:''})
          }
      }else{
        if(!isEmail){
          setEmailHelperText(true);
        }
        if(!isSubject){
          setEmailSubjectHelperText(true);
        }
      }
  }
  
  const rateValidation = (val)=>{
    const valid = Number(val) > 0;
    return valid;
  }


  const partsData = getValues({ nest: true })["serviceParts"];

 const openGeneratedQuote = () =>{
    const lineItemData = calculate && calculate.lineItems ? calculate.lineItems.map((item,key)=>{
      return{
        "lineNo": key+1,
        "productName": item.productName,
        "HSNCode": item.HSNCode,
        "IGST": item.IGST,
        "CGST": item.CGST,
        "SGST": item.SGST,
        "uom": item.uom,
        "qty": item.qty,
        "unitPrice": item.unitPrice,
        "salePrice": item.salePrice,
        "discountPct": item.discountPct,
        "amount": item.amount,
        "isSpecial":item.isSpecial,
        "isQtyDecimal":item.isQtyDecimal
      }
    }):[]
    
    let estimateData={
      "jobNo": data.id,
      "invoiceDate": new Date(),
      "subtotal": calculate.subtotal,
      "CGST": calculate.CGST,
      "SGST": calculate.SGST,
      "IGST": calculate.IGST,
      "total": calculate.total,
      "roundOff": calculate.total ? (Math.round((Math.round(calculate.total)-calculate.total) * 100) / 100).toFixed(2):0,
      "org": data.org,
      "lineItems": lineItemData,
      "customer": data.customer,
      "amountInWords": calculate.amountInWords
    }
    setQuote(estimateData)
    setSendQuote(true);
    setSendInvoice(false)
    setSendDepInvoice(false)
    setEmailSendData({ emailSubject: 'Vindhya Associates - Job Estimation',
                       emailId: data&&data.customer&&data.customer.email
                      })
  }

  const onSendEmailJobEstimate =async () =>{
    let isEmail = false;
    let isSubject = (emailSubject&&emailSubject.length>1)?true:false;
    if(emailId&&emailId.length>0){
      isEmail = true;
      let emailIds = emailId.split(';');
      const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      _.forEach(emailIds,(data)=>{
        if (!mailformat.test(String(data).toLowerCase())){
          isEmail = false;
        }
      })
    }
    if(isEmail&&isSubject){
        setEmailHelperText(false);
        setEmailSubjectHelperText(false);
        let invoiceBodyData = () =>{
          return(
            <CompToPrintEstimate ref={componentRefEstimate} customerInv={quote} job={data}/>
            )
        }  
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('to',emailId);
        bodyFormData.append('subject',emailSubject);
        bodyFormData.append('invoice',renderToString(invoiceBodyData()));
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/x-www-form-urlencoded',
            'Content-Length': bodyFormData.length
          }
        }
        const result = await axios.post(
          `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
          bodyFormData,
          config
        );
        if(result.status===200){
          setMessage(result.data&&result.data.message)
          setMailMessage(true)
          setSendQuote(false);
          setEmailSendData({emailId:'',emailSubject:''})
        }
    }else{
      if(!isEmail){
        setEmailHelperText(true);
      }
      if(!isSubject){
        setEmailSubjectHelperText(true);
      }
    }
}

const openDepositInovice =async (invNum) =>{
  try {
    const data = await getCustomerInvoice(invNum);
    setInvoice(data);
    setSendDepInvoice(true)
    setSendInvoice(false);
    setSendQuote(false);
    setEmailSendData({ emailSubject: 'Vindhya Associates - Deposit Invoice',
                       emailId: data&&data.customer&&data.customer.email
                    })
   } catch (err) {
    setIsError(true);
   }
}

const printDepositInovice = async (invNum) => {
  try {
    const data = await getCustomerInvoice(invNum);
    setInvoice(data);
    setSendInvoice(false);
    setSendQuote(false);
  } catch (err) {
    setIsError(true);
  }
}

const onCalculateClick = (index) => {
  const values = getValues({ nest: true })["serviceParts"];
  const rowValues = values[index];
  setPriceData({ ...priceData, productId:rowValues.productId, productName:rowValues.productName, amount: rowValues.price, qty: rowValues.qty, open: true });
}

const onSendEmailDepInvoice =async () =>{
  let isEmail = false;
  let isSubject = (emailSubject&&emailSubject.length>1)?true:false;
  if(emailId&&emailId.length>0){
    isEmail = true;
    let emailIds = emailId.split(';');
    const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    _.forEach(emailIds,(data)=>{
      if (!mailformat.test(String(data).toLowerCase())){
        isEmail = false;
      }
    })
  }
  if(isEmail&&isSubject){
      setEmailHelperText(false);
      setEmailSubjectHelperText(false);
      let invoiceBodyData = () =>{
        return(
          <CompToPrintInv ref={componentRefInv} customerInv={invoice} />
          )
      }  
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('to',emailId);
      bodyFormData.append('subject',emailSubject);
      bodyFormData.append('invoice',renderToString(invoiceBodyData()));
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/x-www-form-urlencoded',
          'Content-Length': bodyFormData.length
        }
      }
      const result = await axios.post(
        `${process.env.REACT_APP_SERVICE_URL}/invoices/mail`,
        bodyFormData,
        config
      );
      if(result.status===200){
        setMessage(result.data&&result.data.message)
        setMailMessage(true)
        setSendDepInvoice(false);
        setEmailSendData({emailId:'',emailSubject:''})
      }
  }else{
    if(!isEmail){
      setEmailHelperText(true);
    }
    if(!isSubject){
      setEmailSubjectHelperText(true);
    }
  }
}


const onEditDataCustSubmit = (data) =>{
  setShowCustForm(false);
  setEditCustDetails(data);    
}
const onEditCustCancel = () =>{
  setShowCustForm(false);
}


const onEditConsigneeDataSubmit = (data) =>{
  setShowConsingeeForm(false);
  setEditConsingeeDetails(data);    
}
const onEditConsigneeCancel = () =>{
  setShowConsingeeForm(false);
}


const onEditDispatchDataSubmit = (data) =>{
  setShowDispatchForm(false);
  setEditDispatchDetails(data);    
}
const onEditDispatchCancel = () =>{
  setShowDispatchForm(false);
}


const onEditCust = () =>{
  let editCust = {
    tradeName: custDetails.fullName,
    email: custDetails.email,
    mobileNumber: custDetails.mobileNumber,
    address1: custDetails.address1,
    address2: custDetails.address2,
    address3: custDetails.address3,
    pincode: custDetails.pincode,
    locality: custDetails.locality,
    state: custDetails.state,
    GSTIN: custDetails.GSTIN,
    stateCode: custDetails.state
  }
  setEditCustDetails(editCustDetails?editCustDetails:editCust);
  setShowCustForm(true);
 }

 const onEditConsingee = () =>{
  let editConsignee = {
    tradeName: custDetails.fullName,
    address1: custDetails.address1,
    address2: custDetails.address2,
    address3: custDetails.address3,
    pincode: custDetails.pincode,
    locality: custDetails.locality,
    state: custDetails.state,
    GSTIN: custDetails.GSTIN,
    stateCode: custDetails.state
  }
  setEditConsingeeDetails(editConsigneeDetails?editConsigneeDetails:editConsignee);
  setShowConsingeeForm(true);
 }
 
 const onEditDispatch = () =>{
  let editDispatch = {
    tradeName: orgDetails && orgDetails.name,
    address1: orgDetails && orgDetails.address1,
    address2: orgDetails && orgDetails.address2,
    pincode: orgDetails && orgDetails.pincode,
    locality: orgDetails && orgDetails.address3,
    state: orgDetails && orgDetails.state,
    stateCode: orgDetails && orgDetails.state
  }
  setEditDispatchDetails(editDispatchDetails?editDispatchDetails:editDispatch);
  setShowDispatchForm(true);
 }

 let generateEinvoiceAction = () =>{
    let requiredDetails = true;
    if(!(editCustDetails &&  editCustDetails.tradeName && editCustDetails.mobileNumber &&
      editCustDetails.address1 && editCustDetails.locality && editCustDetails.pincode && editCustDetails.state && editCustDetails.GSTIN)){
        setBDNotFilled(true);
        requiredDetails = false;
    }else{
      setBDNotFilled(false);
    }
    if(!(editConsigneeDetails &&  editConsigneeDetails.tradeName && editConsigneeDetails.address1 && 
      editConsigneeDetails.locality && editConsigneeDetails.pincode && editConsigneeDetails.state && editConsigneeDetails.GSTIN)){
        setCDNotFilled(true);
        requiredDetails = false;
    }else{
      setCDNotFilled(false);
    }
    if(!(editDispatchDetails &&  editDispatchDetails.tradeName && editDispatchDetails.address1 && 
      editDispatchDetails.locality && editDispatchDetails.pincode && editDispatchDetails.state)){
        setDDNotFilled(true);
        requiredDetails = false;
    }else{
      setDDNotFilled(false);
    }
    setRequiredDetailsNot(!requiredDetails);
    if(requiredDetails){
      setShowEinvoice(true)
    }
    
 }

 let submitEinvoiceAction = async (invNum) =>{
  try {    
    setIsProcessing(true);
    setIsLoading(true);
  let payloadJSON = {
    sellerDetails : sellerDetails,
    buyerDetails : editCustDetails,
    consigneeDetails : editConsigneeDetails,
    dispatchDetails : editDispatchDetails
  }
  let createEInvQR = await axios.post(
    `${process.env.REACT_APP_SERVICE_URL}/qrcode/job/invoice/${invNum}`,payloadJSON,
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
  );
    if(createEInvQR&& createEInvQR.data && createEInvQR.data.status==="SUCCESS"){
      await handleInvoicePrint(invNum);
      buttonRef && buttonRef.current && buttonRef.current.click()
      setMessage(createEInvQR && createEInvQR.data && createEInvQR.data.message);
      setIsProcessing(false);
      setIsLoading(false);
      setInvoiceNo(invNum);
      setProdInv({
        ...prodInv,
        success: true,
      });
      setShowEinvoice(false);
     
    }else{
      setMessage(createEInvQR && createEInvQR.data && createEInvQR.data.message);
      setIsProcessing(false);
      setIsLoading(false);
      setInvError(true);
      setShowEinvoice(false);
      setTimeout(()=>{
        window.location.reload();  
      }, 1000);
        
    }
  } catch (err) {
    setMessage("Unble to generate e-Invoice")
    setInvError(true);
    setIsProcessing(false);
    setIsLoading(false);
    setShowEinvoice(false);
  }
 }

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 10 }}>
      <Grid item container xs={12} >
              <Grid item xs={6}>
                <Breadcrumbs separator="›" maxItems={5} aria-label="breadcrumb">
                  <Link color="inherit" href="" onClick={() => history.push("/")}>
                    Home
                </Link>
                  <Typography component="span" color="textPrimary" style={{fontWeight: 'bold'}}>
                    {id?<span>Manage </span>:<span>Create </span>}Service Request
                </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
              {data.id ?<ReactToPrint  
              trigger={() => 
              <Button color="primary" variant="contained" 
              style={{ marginRight: 10, width: 'fit-content', height: 'fit-content' }}>
                PRINT</Button>} content={() => componentRefJob.current}/>:null}
              </Grid>
            </Grid>
        <Grid container spacing={2} alignItems="center" style={{marginTop: '10px'}}>
          <Grid item container md={4} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <Grid item md={4} xs={4}>
                <Grid item container justify="flex-end">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Job Number</Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={8}>
                <TextField
                  name="id"
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={props.isNew ? "New Job" : ""}
                  disabled
                  value={data.id}
                  inputRef={register}
                  InputProps={{
                    style: { color: "black" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={4} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <Grid item md={4} xs={4}>
                <Grid item container justify="flex-end">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Job Category<span style={{color: 'red'}}>*</span></Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={8}>
                <FormControl
                  fullWidth
                  disabled={!props.isNew}
                  placeholder="Job Category"
                  variant="outlined"
                  size="small"
                  error={Boolean(errors.categoryId)}
                >
                  <Controller
                    as={
                      <Select style={{ color: "black" }}>
                        {jCategories.map(function (jCat) {
                          return (
                            <MenuItem key={jCat.id} value={jCat.id}>
                              {jCat.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                    name="categoryId"
                    size="small"
                    variant="outlined"
                    rules={{ required: "Category is required" }}
                    control={control}
                    defaultValue=""
                  />
                </FormControl>
                <FormHelperText error>
                  {errors.categoryId && errors.categoryId.message}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={4} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <Grid item md={4} xs={4}>
                <Grid item container justify="flex-end">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Date</Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={8}>
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  size="small"
                  inputVariant="outlined"
                  format="dd/MM/yyyy HH:mm:ss"
                  inputRef={register}
                  name="createdOn"
                  disabled={!props.isNew}
                  value={data.createdOn}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider> */}
                <TextField
                  name="createdOn"
                  disabled
                  fullWidth
                  InputProps={{
                    style: { color: "black" },
                  }}
                  variant="outlined"
                  size="small"
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={4} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <Grid item md={4} xs={4}>
                <Grid item container justify="flex-end">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Job Type<span style={{color: 'red'}}>*</span></Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={8}>
                <FormControl
                  fullWidth
                  disabled={!props.isNew}
                  variant="outlined"
                  size="small"
                  error={Boolean(errors.typeId)}
                >
                  <Controller
                    as={
                      <Select style={{ color: "black" }}>
                        {jTypes.map(function (jType) {
                          return (
                            <MenuItem key={jType.id} value={jType.id}>
                              {jType.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                    name="typeId"
                    size="small"
                    variant="outlined"
                    rules={{ required: "Job Type is required" }}
                    control={control}
                    defaultValue=""
                  />
                </FormControl>
                <FormHelperText error>
                  {errors.typeId && errors.typeId.message}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={4} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <Grid item md={4} xs={4}>
                <Grid item container justify="flex-end">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>
                      Unique Serial Number
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={8}>
                <TextField
                  name="uniqueSerialNum"
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    style: { color: "black" },
                  }}
                  disabled={!props.isNew}
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={4} xs={12} direction="column">
            <Grid
              item
              container
              spacing={2}
              md={12}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <Grid item md={4} xs={4}>
                <Grid item container justify="flex-end">
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Store<span style={{color: 'red'}}>*</span></Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={8}>
                {!props.isNew?<TextField
                    name="locName"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={data && data.location && data.location.name}
                    InputProps={{
                      style: { color: "black" },
                    }}
                  />:null}
                  <FormControl
                  fullWidth
                  disabled={!props.isNew}
                  variant="outlined"
                  size="small"
                  error={Boolean(errors.locationId)}
                  style={!props.isNew?{ display: "none" }:{}}
                >
                  <Controller
                    as={
                      <Select style={{ color: "black" }}>
                        {serviceInventoryLocations.map(function (locality) {
                          return (
                            <MenuItem key={locality} value={locality.id}>
                              {locality.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    }
                    name="locationId"
                    size="small"
                    variant="outlined"
                    rules={{ required: "Store is required" }}
                    control={control}
                    defaultValue=""
                  />
                </FormControl>
                <FormHelperText error>
                  {errors.locationId && errors.locationId.message}
                </FormHelperText>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
        {props.isNew ? (
          <CustomerDetails
            data={data}
            handleCustomerSelection={handleCustomerSelection}
          />
        ) : (
          <CustomerDetails data={data} errors={errors} />
        )}
        <Box mt={2}>
        <ExpansionPanel defaultExpanded={props.isNew}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Box fontWeight={"fontWeightBold"}>Machine</Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <div style={{ paddingTop: 5 }}>
          <Grid container spacing={1}>
            <Grid item container md={6} xs={12} spacing={1} direction="column">
              <Grid item>
                <Grid
                  item
                  container
                  md={12}
                  xs={12}
                  spacing={1}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item md={8} xs={6}>
                    <Box fontWeight={"fontWeightBold"}>Machine Details<span style={{color: 'red'}}>*</span></Box>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    {props.isNew ? (
                      <IconButton
                        style={{ marginLeft: 20 }}
                        onClick={() => {
                          setProductModal({
                            ...productModal,
                            isForServiceParts: false,
                            open: true,
                          });
                        }}
                      >
                        <SearchIcon color="secondary" />
                      </IconButton>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              {(!props.isNew)?
              <Grid item>
                <div
                  className={machineErrorClass}
                  style={{ padding: "10px", height: "100%" }}
                >
                  <Grid container spacing={1}>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Product Name :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.product && data.product.productName
                            ? data.product.productName
                            : ""}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Brand : </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.brandName? data.brandName:data.product && data.product.brand
                            ? data.product.brand.description
                            : ""}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12} alignItems="center">
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Manufacturer :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.mfgName? data.mfgName:data.product && data.product.manufacturer
                            ? data.product.manufacturer.description
                            : ""}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Model :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.model? data.model:data.product ? data.product.model : ""}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Serial Number :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.serialNumber? data.serialNumber:data.product ? data.product.serialNumber : ""}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Category :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.product && data.product.category
                            ? data.product.category.description
                            : ""}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Type :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.product && data.product.type
                            ? data.product.type.description
                            : ""}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <FormHelperText error>
                  {errors.productId && errors.productId.message}
                </FormHelperText>
              </Grid>:
              <Grid item>
                <div
                  className={machineErrorClass}
                  style={{ padding: "10px", height: "100%" }}
                >
                  <Grid container spacing={1}>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Product Name :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.product && data.product.productName
                            ? data.product.productName
                            : ""}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"} style={{marginTop:'12px'}}>Brand : </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                        <TextField name="brandName" fullWidth onChange = {e => setData({ ...data, brandName : e.target.value})}
                          variant="outlined" value={data.brandName? data.brandName:''} size="small" InputProps={{ style: { color: "black" },}}
                          disabled={!props.isNew}/>
                      </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12} alignItems="center">
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Manufacturer :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                        <TextField name="mfgName" fullWidth onChange = {e => setData({ ...data, mfgName : e.target.value})}
                          variant="outlined" value={data.mfgName? data.mfgName:''} size="small" InputProps={{ style: { color: "black" },}}
                          disabled={!props.isNew}/>
                      </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"} style={{marginTop:'10px'}}>Model :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                        <TextField name="model" fullWidth onChange = {e => setData({ ...data, model : e.target.value})}
                          variant="outlined" value={data.model? data.model:''} size="small" InputProps={{ style: { color: "black" },}}
                          disabled={!props.isNew}/>
                      </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"} style={{marginTop:'10px'}}>Serial Number :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                        <TextField name="serialNumber" fullWidth onChange = {e => setData({ ...data, serialNumber : e.target.value})}
                          variant="outlined" value={data.serialNumber? data.serialNumber:''} size="small" InputProps={{ style: { color: "black" },}}
                          disabled={!props.isNew}/>
                      </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Category :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.product && data.product.category
                            ? data.product.category.description
                            : ""}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item container md={12} xs={12}>
                      <Grid item md={4} xs={4}>
                        <Box fontWeight={"fontWeightBold"}>Type :</Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box fontWeight={"fontWeightMedium"}>
                          {data.product && data.product.type
                            ? data.product.type.description
                            : ""}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <FormHelperText error>
                  {errors.productId && errors.productId.message}
                </FormHelperText>
              </Grid>}
            </Grid>
            <Grid item container md={6} xs={12} spacing={1} direction="column">
              <Grid item>
                <Grid
                  item
                  container
                  md={12}
                  xs={12}
                  spacing={1}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item md={8} xs={9}>
                    <Box fontWeight={"fontWeightBold"}>Inspection Report<span style={{color: 'red'}}>*</span></Box>
                  </Grid>
                  <Grid item md={4} xs={3} justify="flex-end">
                    {props.isNew ? (
                      <>
                        <IconButton
                          disabled={data.images && data.images.length === 5}
                          style={{ marginLeft: 20 }}
                          onClick={() => {
                            setCameraOpen(true);
                          }}
                        >
                          <PhotoCameraIcon
                            color={
                              data.images && data.images.length === 5
                                ? ""
                                : "secondary"
                            }
                          />
                        </IconButton>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <div
                  className={inspectCondErrorClass}
                  style={{ padding: "10px", height: 209 }}
                >
                  <Grid container direction="column">
                    <Grid item container md={12} xs={12}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={!props.isNew}
                      >
                        <Controller
                          as={
                            <RadioGroup name="inspectCondition">
                              <FormControlLabel
                                value="INTACT"
                                control={<Radio color="primary" />}
                                label="Intact"
                              />
                              <FormControlLabel
                                value="TAMPERED"
                                control={<Radio color="primary" />}
                                label="Tampered"
                              />
                              <FormControlLabel
                                value="DAMAGED"
                                control={
                                  <Radio color="primary" value="DAMAGED" />
                                }
                                label="Damaged"
                              />
                              <FormControlLabel
                                value="DISMANTLED"
                                control={
                                  <Radio color="primary" value="DISMANTLED" />
                                }
                                label="Dismantled"
                              />
                            </RadioGroup>
                          }
                          name="inspectCondition"
                          rules={{
                            required: "Inspection Condition is required",
                          }}
                          control={control}
                          defaultValue=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container>
                      <Grid item>
                        {/* <IconButton onClick={(e) => setOpenCarousel(true)}>
                          <VisibilityIcon color="secondary" />
                        </IconButton> */}
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={(e) => setOpenCarousel(true)}
                        >
                          View Snapshots - {data.images && data.images.length}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                <FormHelperText error>
                  {errors.inspectCondition && errors.inspectCondition.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid
              item
              container
              md={6}
              xs={12}
              alignItems="center"
              justify="center"
            >
              <Grid item md={4} xs={4}>
                <Grid item container>
                  <Grid item>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              md={6}
              xs={12}
              alignItems="center"
              justify="flex-end"
            >
              <Grid item md={4} xs={4}>
                <Grid item container>
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Customer Signature</Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={8}>
                {props.isNew ? (
                  <>
                    <SignaturePad
                      canvasProps={{
                        width: "250",
                        height: "75",
                        className: signCanvasStyle,
                      }}
                      backgroundColor="rgb(255,255,255)"
                      ref={signRef}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        signRef.current.clear();
                        setData({
                          ...data,
                          customerSignature: null,
                        });
                      }}
                    >
                      Clear Signature
                    </Button>
                  </>
                ) : data.customerSignature ? (
                  <img alt="sign" src={data.customerSignature} />
                ) : null}
              </Grid>
            </Grid>
       </Grid>
        </div>
        </ExpansionPanelDetails>
          </ExpansionPanel></Box>
          <Grid item container xs={12} style={{ paddingTop: 25 }}>
              <Grid item container direction="column" spacing={1}>
                <Grid item>
                  <Box fontWeight={"fontWeightBold"}>
                    Problems in the machine
                  </Box>
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    InputProps={{
                      style: { color: "black" },
                    }}
                    disabled={data.status && (data.status.code === "WC" || data.status.code === "IG")}
                    name="problems"
                    inputRef={register}
                    placeholder="eg: Not starting/running"
                    rows={3}
                    rowsMax={5}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12}  style={{ paddingTop: 25 }}>
              <Grid item container direction="column" spacing={1}>
                <Grid item>
                  <Box fontWeight={"fontWeightBold"} >Parts given along</Box>
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      style: { color: "black" },
                    }}
                    disabled={data.status && (data.status.code === "WC" || data.status.code === "IG")}
                    name="partsGivenAlong"
                    inputRef={register}
                    placeholder="eg:None.."
                    multiline
                    rows={3}
                    rowsMax={5}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              md={6}
              xs={12}
              alignItems="center"
              justify="center" style={{marginTop:'25px'}}
            >
              <Grid item md={4} xs={4}>
                <Grid item container>
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>
                      Expected Delivery Date
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={8}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    disabled={data.status && (data.status.code === "WC" || data.status.code === "IG")}
                    error={Boolean(errors.expectedDeliveryDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    inputRef={register({
                      required: true,
                    })}
                    name="expectedDeliveryDate"
                    value={data.expectedDeliveryDate}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
                <p2 style={{fontSize:'small'}}>Subject to availability of spares</p2>
            </Grid>
          {!props.isNew ? (
            <Box mt={3}>
            <Grid
              item
              container
              md={6}
              xs={12}
              spacing={1}
              alignItems="center"
              justify="center"
            >
              <Grid item md={4} xs={4}>
                <Grid item container>
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Technician<span style={{color: 'red'}}>*</span></Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={8} xs={8}>
                <FormControl
                  fullWidth
                  placeholder="Assigned Technician"
                  variant="outlined"
                  size="small"
                  disabled={isTechnician || isAdmin || (data.status && data.status.code === "WC") || (data.status && data.status.code === "IG")}
                  error={Boolean(errors.technicianId)}
                >
                  <Controller
                    as={
                      <Select style={{ color: "black" }}>
                        {techies &&
                          techies.map(function (technician) {
                            return (
                              <MenuItem
                                key={technician.id}
                                value={technician.id}
                              >
                                {technician.firstName} {technician.lastName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    }
                    name="technicianId"
                    size="small"
                    variant="outlined"
                    rules={{ required: "Technician is required" }}
                    control={control}
                    defaultValue=""
                  />
                </FormControl>
                <FormHelperText error>
                  {errors.technician && errors.technician.message}
                </FormHelperText>
              </Grid>
            </Grid></Box>
        ) : null}
        {!props.isNew ? (
          <div style={{ paddingTop: 50 }}>
            <Box fontWeight={"fontWeightBold"} style={{ paddingBottom: 20 }}>
              Service Parts<span style={{color: 'red'}}>*</span>
            </Box>

            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledHeaderCell align="left" style={{ width: "25%" }}>
                      Product Name
                    </StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "12%" }}>
                      HSN Code
                    </StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "12%" }}>
                      Qty
                    </StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "12%" }}>
                      Price
                    </StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "12%" }}>
                      Available Qty
                    </StyledHeaderCell>
                    <StyledHeaderCell align="left" style={{ width: "12%" }}>
                      UOM
                    </StyledHeaderCell>
                    <StyledHeaderCell
                      align="left"
                      style={{ width: "15%" }}
                    ></StyledHeaderCell>
                  </TableRow>
                </TableHead>
                {fields.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <Typography variant="subtitle1">
                          No Service Parts added.
                        </Typography>
                        {!isAdmin ? (
                          <Button
                          color="primary" size="medium"
                          variant="contained"
                          disabled={(data.status && data.status.code === "WC") || (data.status && data.status.code === "IG")}
                          onClick={(e) => {
                            append({
                              lineId: "",
                              product: {},
                              productName: "",
                              HSNCode:"",
                              qty: "",
                              price: "",
                              availability: "",
                              isSpecial:"",
                              uom:"",
                              isQtyDecimal:"",
                            });
                          }}
                        >
                          Add Product
                        </Button>                          
                        ) : null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}

                <TableBody>
                  {fields.map((servicePart, index) =>{
                  return (
                    <TableRow key={servicePart.id}>
                      <input
                        type="text"
                        style={{ display: "none" }}
                        name={`serviceParts[${index}].lineId`}
                        defaultValue={servicePart.lineId}
                        ref={register({})}
                      />
                      <input
                        type="text"
                        style={{ display: "none" }}
                        name={`serviceParts[${index}].productId`}
                        defaultValue={
                          servicePart.product && servicePart.product.id
                        }
                        ref={register({})}
                      />
                      <TableCell align="left">
                        <TextField
                          fullWidth
                          disabled
                          error={Boolean(
                            errors.serviceParts &&
                              errors.serviceParts[index] &&
                              errors.serviceParts[index].productName
                          )}
                          inputRef={register({
                            required: "Part Name required",
                          })}
                          size="small"
                          multiline
                          rows={1}
                          rowsMax={3}
                          name={`serviceParts[${index}].productName`}
                          defaultValue={
                            servicePart.product &&
                            servicePart.product.productName
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" style={{cursor:"pointer"}} disablePointerEvents={isAdmin || (data.status && data.status.code === "WC") || (data.status && data.status.code === "IG")}>
                                <SearchIcon  onClick={() =>
                                  setProductModal({
                                    ...productModal,
                                    isForServiceParts: true,
                                    servicePartsIndex: index,
                                    open: true,
                                  })
                                }
                                />
                              </InputAdornment>
                            ),
                            style: { fontSize: "14px"},
                          }}
                          variant="standard"
                        />
                        <FormHelperText error>
                          {errors.serviceParts &&
                            errors.serviceParts[index] &&
                            errors.serviceParts[index].productName &&
                            errors.serviceParts[index].productName.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                      <TextField
                        inputProps={{ style: { textAlign: 'right' } }}
                        size="small"
                        // required
                        disabled={isAdmin || (data.status && data.status.code === "WC") || (data.status && data.status.code === "IG")}
                        name={`serviceParts[${index}].HSNCode`}
                        defaultValue={servicePart.HSNCode}
                        inputRef={register({
                          required: "HSN is required",
                        })}
                        variant="standard"
                        error={Boolean(
                          errors.serviceParts &&
                          errors.serviceParts[index] &&
                          errors.serviceParts[index].HSNCode
                        ) || (_.includes(errHSNProIndex, index)) ? true : false}
                      />
                       <FormHelperText error={Boolean(
                          errors.serviceParts &&
                          errors.serviceParts[index] &&
                          errors.serviceParts[index].HSNCode
                        ) || (_.includes(errHSNProIndex, index)) ? true : false }>
                        {((errors.serviceParts &&
                          errors.serviceParts[index] &&
                          errors.serviceParts[index].HSNCode &&
                          errors.serviceParts[index].HSNCode.message) ||(_.includes(errHSNProIndex, index)) ? true : false)?"Enter Valid HSN Code":""}
                      </FormHelperText>
                    </TableCell>
                      <TableCell align="left">
                        <TextField
                          fullWidth
                          error={Boolean(
                            errors.serviceParts &&
                              errors.serviceParts[index] &&
                              errors.serviceParts[index].qty
                          )}
                          inputRef={register({
                            required: "Qty is required",
                            pattern: ((servicePart && servicePart.product && servicePart.product.isQtyDecimal) || (qtyDecimal && qtyDecimal[index]) === true) ? {
                              value: /^[0-9]*.[0-9]{0,3}$/ ,
                              message: "Qty should be greater than 1",
                            }:{
                              value: /^[1-9]\d*$/,
                              message: "Qty should whole & greater than 1",
                            },
                          })} disabled={isAdmin || (data.status && data.status.code === "WC") || (data.status && data.status.code === "IG")}
                          inputProps={{
                            style: {textAlign: 'right' },

                            endAdornment: (
                              <InputAdornment position="end">
                                {uom && uom[index]
                                  ? uom[index]
                                  : servicePart.product
                                  ? servicePart.product.uom
                                  : ""}
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          name={`serviceParts[${index}].qty`}
                          defaultValue={servicePart.qty}
                          variant="standard"
                        />
                        <FormHelperText error>
                          {errors.serviceParts &&
                            errors.serviceParts[index] &&
                            errors.serviceParts[index].qty &&
                            errors.serviceParts[index].qty.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          disabled={(isAdmin || (data.status && data.status.code === "WC") || (data.status && data.status.code === "IG"))}
                            error={Boolean(
                            errors.serviceParts &&
                              errors.serviceParts[index] &&
                              errors.serviceParts[index].price
                          )}
                          inputProps={{
                            style: { textAlign: 'right' },
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                          inputRef={register({
                            required: "Price is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Numbers and max two decimal places allowed",
                            },
                            validate:(value)=> rateValidation(value) || 'Price should be greater than 0'
                          })}
                    
                          size="small"
                          name={`serviceParts[${index}].price`}
                          defaultValue={servicePart.price ? (Math.round(servicePart.price * 100) / 100).toFixed(2):
                            servicePart.price===0 ? '0.00':'' }
                          variant="standard"
                        />
                        <FormHelperText error>
                          {errors.serviceParts &&
                            errors.serviceParts[index] &&
                            errors.serviceParts[index].price &&
                            errors.serviceParts[index].price.message}
                        </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          disabled
                          inputProps={{style: {textAlign: 'right' }}}
                          inputRef={register({})}
                          size="small"
                          name={`serviceParts[${index}].availability`}
                          defaultValue={
                            servicePart.product &&
                            servicePart.product.inventories &&
                            servicePart.product.inventories.filter(inv=> inv.locationId === (corporateLoc && corporateLoc[0].id))[0].openingQty
                          }
                          variant="standard"
                          error={(_.includes(errQtyProIndex, index)) ? true : false}
                        />
                         <FormHelperText error={(_.includes(errQtyProIndex, index)) ? true : false}>
                         {(_.includes(errQtyProIndex, index)) ?"Availability is less than quantity":""}
                      </FormHelperText>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          disabled
                          inputProps={{style: {textAlign: 'right' }}}
                          inputRef={register({})}
                          size="small"
                          name={`serviceParts[${index}].uom`}
                          defaultValue={servicePart.product && servicePart.product.uom && servicePart.product.uom }
                          variant="standard"
                        />
                      </TableCell>
                        
                      {(data.status && data.status.code !== "WC" && data.status.code !== "IG" && !isAdmin) || (!data.status && !isAdmin) ? (
                        <TableCell align="left">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              insert(index + 1, {
                                lineId: "",
                                productId: "",
                                productName: "",
                                HSNCode:"",
                                qty: "",
                                price: "",
                                availability: "",
                                isSpecial:"",
                                uom:"",
                                isQtyDecimal:"",
                              });
                              setErrHSN([]);
                              setErrQtyProd([]);
                            }}
                          >
                            <AddIcon color="secondary" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              remove(index);
                              setErrHSN([]);
                              setErrQtyProd([]);
                            }}
                          >
                            <DeleteIcon color="secondary" />
                          </IconButton>
                            <IconButton size="small" onClick={() => onCalculateClick(index)}>
                            <img src={calculateIcon} alt="history" />
                          </IconButton>
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}
                      <TableCell style={{display:'none'}}>
                      <TextField
                          disabled
                          inputProps={{style: {textAlign: 'right',display:'none' }}}
                          inputRef={register({})}
                          size="small"
                          name={`serviceParts[${index}].isSpecial`}
                          defaultValue={
                            (servicePart&&
                            servicePart.isSpecial)?true:false
                          }
                          variant="standard"
                        />
                        </TableCell>
                    </TableRow>
                  )})}
                </TableBody>
              </Table>
            </TableContainer>
            { !(data.status && (data.status.code === "IG" || data.status.code === "WC")) && <Grid item container xs={12} justify="flex-end">
            <Box mt={2}>
              <Button
                size="small"
                onClick={calculateMethods.handleSubmit(handleCalculate)}
                variant="contained"
                color="primary"
                style={{ marginRight: "10px" }}
              >
                Calculate
            </Button>
              {/* <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => handleClear()}
              >
                Clear
            </Button> */}
            </Box>
          </Grid>}
          </div>
        ) : null}
        {isServicePartMessage?<div>
          <Box>
            <Typography style={{textAlign: 'Left', color:"red" }}>Add Service Part before further action</Typography>
          </Box></div>:null}
        {isWarning?<div>
          <Box>
          {((data.status && data.status.code === "WC") || (data.status && data.status.code === "IG"))?null:
            <Typography style={{textAlign: 'right', color:"red",fontSize:"12px",marginTop:"4px" }}>{warningMessage}</Typography>}
          </Box></div>:null}

          {calculate && <Box mt={2}>
          <List dense={true} style={{ backgroundColor: "#F5F5F5", fontSize: "14px" }}>
            <ListItem alignItems="flex-start" divider={true} >
              <Grid item container xs={12}>
                <Grid item xs={11}>
                  Subtotal
          </Grid>
                <Grid item xs={1}>
                  <Box display="flex" justifyContent="flex-end"> {calculate.subtotal && (Math.round(calculate.subtotal * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start" divider={true}>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  GST
             </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start" divider={true}>

              <Grid item container xs={12} >
                <Grid item xs={11} >
                  <Box ml={2} >IGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box display="flex" justifyContent="flex-end">{calculate.IGST && (Math.round(calculate.IGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
                <Grid item xs={11} >
                  <Box mt={0.3} ml={2}>CGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box mt={1} display="flex" justifyContent="flex-end">{calculate.CGST && (Math.round(calculate.CGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
                <Grid item xs={11} >
                  <Box ml={2} >SGST</Box>
                </Grid>
                <Grid item xs={1} >
                  <Box display="flex" justifyContent="flex-end">{calculate.SGST && (Math.round(calculate.SGST * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem alignItems="flex-start">
              <Grid item container xs={12} style={{ fontWeight: "bold" }}>
                <Grid item xs={11}>
                  Total
          </Grid>
                <Grid item xs={1} style={{ borderLeft: "4px solid #FFFFFF" }}>
                  <Box display="flex" justifyContent="flex-end"> {calculate.total && (Math.round(calculate.total * 100) / 100).toFixed(2)}</Box>
                </Grid>
              </Grid>
            </ListItem>
          </List>
          <Box mt={2} style={{ fontSize: "12px" }}>{`Amount Chargeable(In words): ${calculate.amountInWords}`}</Box>
          <Box mt={2}>
          <ReactToPrint  onBeforeGetContent={()=>handleQuote()}
              trigger={() => 
              <Button color="primary" variant="contained" style={{ marginTop: 10, marginRight: 10, width: 'fit-content', height: 'fit-content' }}>
               print </Button>} content={() => componentRefEstimate.current}/>
               <Button
                onClick={openGeneratedQuote}
                color="primary"
                variant="contained"
                style={{ marginTop: 10, marginRight: 10, width: 'fit-content', height: 'fit-content' }}
              >
                Email
              </Button>
            </Box>
          </Box>}
        {!props.isNew ? (
          <div style={{ paddingTop: 50 }}>
            <Grid container spacing={2}>
              <Grid item container xs={12} md={5}>
                <Grid item container direction="column" spacing={1}>
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Notes</Box>
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      fullWidth
                      disabled={isAdmin || (data.status && data.status.code === "WC") || (data.status && data.status.code === "IG")}
                      multiline
                      name="notes"
                      inputRef={register}
                      inputProps={{ maxLength: 150 }}
                      placeholder="Notes..."
                      rows={3}
                      rowsMax={5}
                      size="small"
                    />
                    <FormHelperText >
                      Textfield allow only 500 character. 
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} md={2}>
                <Grid item container direction="column" spacing={1}>
                  <Grid item>
                    <Box fontWeight={"fontWeightBold"}>Deposit Advice</Box>
                  </Grid>
                  <Grid item>
                    <List
                      dense={true}
                      style={{
                        width: "100%",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 60,
                        border: "1px solid #0C4B60",
                        minHeight: 60,
                      }}
                    >
                      {data &&
                        data.deposits &&
                        data.deposits.map((invoice, index) => (
                          <ListItem
                            button={!isTechnician}
                            onClick={(e) =>
                              setAttachInvoice({
                                index: index,
                                jobId: data.id,
                                invoiceNum: invoice.invoiceNum,
                                amount: invoice.amount,
                                open: !isTechnician ? true : false,
                              })
                            }
                          >
                            <ListItemText>
                              <Box
                                fontWeight={"fontWeightMedium"}
                                fontSize={16}
                              >
                                {invoice.invoiceNum}
                              </Box>
                            </ListItemText>
                          </ListItem>
                        ))}
                    </List>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} md={5} spacing={1}>
                <Grid
                  item
                  container
                  md={12}
                  xs={12}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item md={4} xs={4}>
                    <Grid item container>
                      <Grid item>
                        <Box fontWeight={"fontWeightBold"}>Current Status</Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={8} xs={8}>
                    <FormControl
                      fullWidth
                      // disabled={isAdmin}
                      placeholder="Current Status"
                      variant="outlined"
                      size="small"
                      error={Boolean(errors.statusId)}
                      disabled={isAdmin || (data.status && data.status.code === "IG")}
                    >
                      <Controller
                        as={
                          <Select style={{ color: "black" }}>
                            {jStatuses &&
                              jStatuses.map(function (jStatus) {
                                return (
                                  <MenuItem key={jStatus.id} value={jStatus.id} disabled={jStatus.code==='IG'}>
                                    {jStatus.description}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        }
                        name="statusId"
                        size="small"
                        variant="outlined"
                        rules={{ required: "Status is required" }}
                        control={control}
                        defaultValue=""
                      />
                    </FormControl>
                    <FormHelperText error>
                      {errors.statusId && errors.statusId.message}
                    </FormHelperText>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  md={12}
                  xs={12}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item md={4} xs={4}>
                    <Grid item container>
                      <Grid item>
                        <Box fontWeight={"fontWeightBold"}>
                          Deposit Collected
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={8} xs={8}>
                    <Grid item container alignItems="center" justify="center" spacing={2}>
                      <Grid item xs={6} md={6}>
                        <TextField
                          variant="outlined"
                          disabled
                          name="depositCollected"
                          value={
                            data.deposits && data.deposits.length > 0
                              ? data.deposits
                                  .map((x) => x.amount)
                                  .reduce((a, b) => {
                                    return (
                                      parseFloat(a) + parseFloat(b)
                                    ).toFixed(2);
                                  })
                              : 0
                          }
                          InputProps={{
                            style: { color: "black" },
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={6} md={6} justify="flex-end">
                        <Button
                         variant="contained"
                         color="primary"
                         disabled={!isServiceAdvisor || (data.status && data.status.code === "IG")}
                         onClick={handleSubmit((data)=>{onSubmit(data,"DEPOSIT")})}
                        >Add Deposit</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : null}
        <ConfirmationDialog onClose={handleCloseModal} />

        <Dialog fullWidth={true} maxWidth={"sm"} open={cameraOpen}>
          <DialogTitle>
            <Grid container>
              <Grid item container xs={10} alignItems="center">
                <Box fontWeight={"fontWeightBold"}>Camera</Box>
              </Grid>
              <Grid
                item
                container
                xs={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      setCameraOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <Camera
            isMaxResolution={true}
            isImageMirror={false}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            onTakePhoto={(dataUri) => {
              data.images.push(dataUri);
              if (data.images && data.images.length === 5) {
                setCameraOpen(false);
              }
              setData({ ...data, images: data.images });
            }}
          />
        </Dialog>
        <Dialog fullWidth={true} maxWidth="sm" open={openCarousel}>
          <DialogTitle>
            <Grid container>
              <Grid item container xs={10} alignItems="center">
                <Box fontWeight={"fontWeightBold"}>Snapshots</Box>
              </Grid>
              <Grid
                item
                container
                xs={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      setOpenCarousel(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {data.images && data.images.length > 0 ? (
              <Carousel autoPlay={false} indicators={false} timeout={100}>
                {data.images &&
                  data.images.map((img, index) => (
                    <>
                      <img alt="newImg"
                        style={{ width: "100%", height: "100%" }}
                        src={props.isNew ? img : img.data}
                      />
                      {props.isNew ? (
                        <Grid
                          container
                          xs={12}
                          align="center"
                          alignItems="center"
                          justify="center"
                        >
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                data.images.splice(index, 1);
                                setData({ ...data, images: data.images });
                              }}
                            >
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ) : null}
                    </>
                  ))}
              </Carousel>
            ) : (
              <Grid
                container
                xs={12}
                spacing={2}
                align="center"
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Typography>
                    <b>No images to show</b>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Dialog>
        <Dialog fullWidth={true} maxWidth="sm" open={attachInvoice.open}>
          <DialogTitle>
            <Grid container>
              <Grid item container xs={10} alignItems="center">
                <Box fontWeight={"fontWeightBold"}>{(attachInvoice.invoiceNum)?'Deposit Details':'Add Deposit'}</Box>
              </Grid>
              <Grid
                item
                container
                xs={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      setAttachInvoice({ ...attachInvoice, open: false });
                      setPaymentMode(1)
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <DepositInvoice value={paymentMode} setPaymentId={setPaymentIdDep} setPayment={setPayment} setValue={setPaymentMode} isServiceAdvisor={isServiceAdvisor} attachInvoice={attachInvoice} errors={errors} register={register} clearError={clearError} />
          </DialogContent>
          <DialogActions>
          <Box mr={2}>
                {isLoading && <CircularProgress />}
              </Box>
            <Button
              variant="contained"
              color="secondary" disabled={isLoading}
              onClick={(e) => {
                setAttachInvoice({ ...attachInvoice, open: false });
                setPaymentMode(1)
              }}
            >
              Cancel
            </Button>
            {attachInvoice.invoiceNum?
             <>
             <Button
               variant="contained"
               color="primary"
               onClick={()=>openDepositInovice(attachInvoice.invoiceNum)}
             >
               Email
             </Button>
           </>
            :isServiceAdvisor ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(saveInvoice)}
                  disabled={(attachInvoice.invoiceNum || isLoading) ? true : false}
                >
                  Generate Receipt
                </Button>
              </>
            ) : null}
            {attachInvoice.invoiceNum ?
              <ReactToPrint
              onBeforeGetContent={() => printDepositInovice(attachInvoice.invoiceNum)}
                trigger={() =>
                  <Button color="primary" variant="contained"
                    style={{ marginRight: 10, width: 'fit-content', height: 'fit-content' }}>
                    PRINT</Button>} content={() => componentRefInv.current} /> : null}
          </DialogActions>
        </Dialog>

        {/* calculator */}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={priceData.open}
        onClose={() => {
          setPriceData({ ...priceData, open: false });
        }}
      >
        <DialogTitle style={{paddingBottom : "0px"}}>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Price Calculator
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setPriceData({ ...priceData, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Calculator
            cancel={() => {
              setPriceData({ ...priceData, open: false });
            }}
            priceData={priceData}
            intraState={custDetails && orgDetails && custDetails.state !== orgDetails.state}
          />
        </DialogContent>
      </Dialog>

        <Dialog fullWidth={true} maxWidth={"md"} open={productModal.open}>
          <Grid container>
            <Grid item container xs={12} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setProductModal({
                      ...productModal,
                      isForServiceParts: false,
                      open: false,
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <DialogContent>
            {(props.isNew)?
            <Products modal onRowSelect={handleProductSelection} />:
            <ServicePartsProducts 
            modal 
            custTypeId={data.customer &&data.customer.custTypeId} 
            locationId={corporateLoc && corporateLoc[0] && corporateLoc[0].id} 
            onRowSelect={handleProductSelection} />}
          </DialogContent>
        </Dialog>
        <Backdrop className={classes.backdrop} open={isProcessing}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <input
          type="hidden"
          name="productId"
          value={data.product && data.product.id}
          ref={register({
            required: "Machine Details required. Please select.",
          })}
        />
        <input type="hidden" name="id" value={data.id} ref={register} />

        <Grid container style={{ paddingTop: 40 }}>
          <Grid item container spacing={6}>
            {data.product && data.product.warrantyCard ? (
              <Grid item>
                <Link
                  rel="noopener"
                  underline="none"
                  target="_blank"
                  href={data.product.warrantyCard}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    justify="center"
                  >
                    <Grid item>
                      <img alt="warranty" src={warrantyCard} />
                    </Grid>
                    <Grid item>Warranty</Grid>
                  </Grid>
                </Link>
              </Grid>
            ) : null}

            {data.product && data.product.userManual ? (
              <Grid item>
                <Link
                  rel="noopener"
                  underline="none"
                  target="_blank"
                  href={data.product.userManual}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    justify="center"
                  >
                    <Grid item>
                      <img alt="userManual" src={userManual} />
                    </Grid>
                    <Grid item>User Manual</Grid>
                  </Grid>
                </Link>
              </Grid>
            ) : null}

            {data.product && data.product.machineDiagram ? (
              <Grid item>
                <Link
                  rel="noopener"
                  underline="none"
                  target="_blank"
                  href={data.product.machineDiagram}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    justify="center"
                  >
                    <Grid item>
                      <img alt="machineDiagram" src={machineDiagram} />
                    </Grid>
                    <Grid item>Machine Diagram</Grid>
                  </Grid>
                </Link>
              </Grid>
            ) : null}
          </Grid>
          <Grid container justify="flex-end" spacing={3} style={{display:'flex',alignItems:'baseline'}}>
            {((isServiceAdvisor || isTechnician) && !isAdmin) || (!data.status && !isAdmin)? (
              <>
                <Grid item>
                  <Button
                    // type="submit"
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 10, width: 100 }}
                    disabled={data.status && data.status.code === "IG"}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={(e) => {
                      resetValues();
                    }}
                    color="primary"
                    variant="contained"
                    disabled={data.status && data.status.code === "IG" }
                    style={{ marginRight: 10, width: 100 }}
                  >
                    Reset
                  </Button> 
                  </Grid>
              </>
            ) : null}
            {(isAdmin &&(data.status && data.status.code === "IG" && (isEInvoice && !invDetails.isSync)) ) ? <Grid item>
            <IconButton
              onClick={() => {
                setShowInvErr(true);
              }}
              style={{ display: eInvEWBErrorDetails.length === 0 ? "none" : "" }}
            >
              <img src={ErrInvoiceReportIcon} alt="history" />
            </IconButton> &nbsp;&nbsp;&nbsp;
            <Button disabled={!isEditAll} onClick={onEditCust} color="primary" size="small" variant="contained" style={{marginRight:'10px'}}>
              Edit Customer Details </Button>
              <Button disabled={!isEditAll} onClick={onEditConsingee} color="primary" size="small" variant="contained" style={{marginRight:'10px'}}>
              Edit Consignee Details </Button>
              <Button disabled={!isEditAll} onClick={onEditDispatch} color="primary" size="small" variant="contained" style={{marginRight:'10px'}}>
              Edit Dispatch Details</Button>
              <Button disabled={!isEditAll} onClick={generateEinvoiceAction} color="primary" size="small" variant="contained" style={{marginRight:'10px'}}>
              {eInvEWBErrorDetails && eInvEWBErrorDetails.length >0 ?"Update e-Invoice":"Generate e-Invoice"}</Button>
            </Grid>:null}
            { (data.status && data.status.code === "IG" )? 
            <ReactToPrint  onBeforeGetContent={()=>handleInvoicePrint(invoiceNo)}
              trigger={() => 
              <Button color="primary" variant="contained" style={{ marginTop: 10, marginRight: 10, width: 'fit-content', height: 'fit-content' }}>
                PRINT INVOICE </Button>} content={() => componentRefInv.current}/>
              :(data.status && data.status.code === "WC") ? (
              <Grid item>
                <Button
            onClick={handleCheckout}
            color="primary"
            variant="contained"
            disabled={!isServiceAdvisor}
          >
            Checkout
    </Button>
              </Grid>
            ) :null}
            { (data.status && data.status.code === "IG" )? 
            <Grid item>
            <Button
                onClick={openEmailInovice}
                color="primary"
                variant="contained"
                style={{ marginRight: 10 }}
              >
                Email
              </Button>
            </Grid>
            :null}
            <Grid item>
              <Button
                onClick={(e) => history.push("/jobs")}
                color="secondary"
                variant="contained"
                style={{ marginRight: 10 }}
              >
                {isAdmin ? "Back to List" : "Cancel"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Box display="none">
          <CompToPrintInv ref={componentRefInv} customerInv={invoice} />
          <CompToPrintEstimate ref={componentRefEstimate} customerInv={quote} job={data}/>
          <ReactToPrint onBeforeGetContent={()=>handleInvoicePrint(invoiceNo)}
            trigger={() => 
              <Button color="primary" size="small" variant="contained" ref={buttonRef}>
                PRINT INVOICE </Button>} content={() => componentRefInv.current}/>
        </Box>
        <Box display="none">
          <JobToPrint ref={componentRefJob}  job={data} />
          <ReactToPrint
            trigger={() => 
              <Button color="primary" size="small" variant="contained" ref={jobButtonRef}>
                PRINT </Button>} content={() => componentRefJob.current}/>
        </Box>
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={payments.open }
            onClose={() => {
              setPayments({ ...payments, open: false });
            }}
          >
            <DialogTitle>
              <Grid container>
                <Grid item container xs={9} alignItems="center">
                  <Grid item>
                    Payments
              </Grid>
                </Grid>
                <Grid item container xs={3} alignItems="center" justify="flex-end">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setPayments({ ...payments, open: false });
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent >
            <Payment
               cancel={() => {
                setPayments({ ...payments, open: false });
                setPaymentId(1)
                setPaymentDetails("")
                setPaymentData("")
              }}
              setPaymentId={setPaymentId}
              paymentId={paymentId}
              setPaymentDetails={setPaymentDetails}
              paymentData={paymentData}
              setPaymentData={setPaymentData}
              generateInvoice={()=>handleInvCalGen(data)}
              job={true}
              calculate={balanceAmount}
              setAmountPaid={setAmountPaid}
              setPaymentType={setPaymentType}
              setRefundAmount={setRefundAmount}
            />
            </DialogContent>
          </Dialog> 
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={isSendInvoice||isSendQuote||isSendDepInvoice}
        onClose={onCloseSendInvoice}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Email Invoice
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                    onClick={onCloseSendInvoice}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{height:'180px'}}>
            <Grid container>
               <Grid xs={3}>
                <Typography className='recordSaleInvoiceLabel' style={{fontWeight:"bold"}} component="span" >Mail to *</Typography>
               </Grid>
               <Grid item md={9}>
                <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailId"
                inputRef={register({
                required: "Email Id is required",
                  })}
                value={emailId}
                onChange = {e => setEmailSendData({ ...emailSendData, emailId: e.target.value})}
                />
                {isEmailHelpertext&&<FormHelperText style={{color:'red'}}>
                  Enter valid email id
                </FormHelperText>}
              </Grid>
              </Grid>
              <Grid container style={{paddingTop:'10px'}}>
               <Grid xs={3}>
                <Typography className='recordSaleInvoiceLabel' style={{fontWeight:"bold"}} component="span">Subject *</Typography>
               </Grid>
               <Grid item md={9}>
                <TextField
                variant="outlined"
                fullWidth
                size="small"
                name="emailSubject"
                inputRef={register({
                required: "Email subject is required",
                  })}
                value={emailSubject}
                onChange = {e => setEmailSendData({ ...emailSendData, emailSubject: e.target.value})}
                />
                {isEmailSubjectHelpertext&&<FormHelperText style={{color:'red'}}>
                  Enter email subject
                </FormHelperText>}
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" mt={4}>
              <Button
                onClick={(isSendDepInvoice)?onSendEmailDepInvoice:(isSendQuote)?onSendEmailJobEstimate:onSendEmailInvoice}
                color="secondary"
                size="small"
                variant="contained"
              >
                Send
              </Button>
            </Box>    
        </DialogContent>
      </Dialog>
         {/* Customer edit from*/}
         <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={showCustForm}
        onClose={() => {
          setShowCustForm(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Customer Details
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowCustForm(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <CustomerEditForm customerData={editCustDetails} onEditDataSubmit={onEditDataCustSubmit} onEditCustCancel={onEditCustCancel} required={isEInvoice}/>
        </DialogContent>
      </Dialog>
    {/* consingee from*/}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={showConsingeeForm}
        onClose={() => {
          setShowConsingeeForm(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Consignee Details
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowConsingeeForm(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <ConsigneeForm consigneeData={editConsigneeDetails} onEditDataSubmit={onEditConsigneeDataSubmit} onEditCancel={onEditConsigneeCancel} required={isEInvoice}/>
        </DialogContent>
      </Dialog>
    {/* Dispatch from*/}
    <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={showDispatchForm}
        onClose={() => {
          setShowDispatchForm(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Dispatch Details
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowDispatchForm(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <DispatchAddressForm dispatchData={editDispatchDetails} onEditDataSubmit={onEditDispatchDataSubmit} onEditCancel={onEditDispatchCancel} required={isEInvoice}/>
        </DialogContent>
      </Dialog>
      {/* Generate eInvoice */}
      <Dialog fullWidth={true} maxWidth="sm" open={showEinvoice}>
          <DialogTitle>
            <Grid container>
              <Grid item container xs={10} alignItems="center">
                <Box fontWeight={"fontWeightBold"}>{eInvEWBErrorDetails && eInvEWBErrorDetails.length >0 ?"Update e-Invoice":"Generate e-Invoice"}</Box>
              </Grid>
              <Grid
                item
                container
                xs={2}
                alignItems="center"
                justify="flex-end"
              >
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      setShowEinvoice(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <GenerateEInvoice invoiceDetails={invDetails} />
          </DialogContent>
          <DialogActions>
          <Box mr={2}>
                {isLoading && <CircularProgress />}
              </Box>
            <Button
              variant="contained"
              color="secondary" disabled={isLoading}
              onClick={(e) => {
               setShowEinvoice(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={()=>{submitEinvoiceAction(invDetails && invDetails.invoiceNum)}}
              disabled={isLoading ? true : false}
            >
              {eInvEWBErrorDetails && eInvEWBErrorDetails.length >0 ?"Update e-Invoice":"Generate e-Invoice"}
            </Button>
          </DialogActions>
        </Dialog>
          {/* eInv EWB error */}
   <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={showInvErr}
        onClose={() => {
          setShowInvErr(false);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Error lists
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setShowInvErr(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <ErrorLists setIsError={setIsError} erroInfo={eInvEWBErrorDetails} />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={invError}
        autoHideDuration={5000}
        onClose={() => setInvError(false)}
      >
        <MuiAlert
          onClose={() => setInvError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {message ? message : ""}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isError}
        autoHideDuration={5000}
        onClose={() => setIsError(false)}
      >
        <MuiAlert
          onClose={() => setIsError(false)}
          elevation={6}
          variant="filled"
          severity="error"
        >
          {message ? message : "Some error occured. Please retry after sometime."}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isSuccess}
        onClose={() => {
          setIsSuccess(false);
          if (props.isNew) {
            // history.push("/jobs/" + data.id);
            history.push({
              pathname:"/jobs/" + data.id,
              state: {
                jobLocData: initialLoc
              }
            });
          } else {
            history.replace({
              ...location,
              state:{jobLocData: corporateLoc},
            }) 
            history.go();
          }
        }}
        autoHideDuration={3000}
      >
        <MuiAlert
          onClose={() => {
            setIsSuccess(false);
            if (props.isNew) {
              // history.push("/jobs/" + data.id);
              history.push({
                pathname:"/jobs/" + data.id,
                state: {
                  jobLocData: initialLoc
                }
              });
            } else {
              history.replace({
                ...location,
                state:{jobLocData: corporateLoc},
              }) 
              history.go();
            }
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {props.isNew
            ? "Job created successfully. Job No : " + data.id
            :"Job updated successfully"}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={prodInv.success}
        onClose={() => {
          setProdInv({ ...prodInv, success: false })
          history.go();
        }}
        autoHideDuration={3000}>
        <MuiAlert
          onClose={() => {
            setProdInv({ ...prodInv, success: false })
            history.go();
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message}
        </MuiAlert>
      </Snackbar>
       <Snackbar
        open={isEmailMessage}
        onClose={() => {
          setMailMessage(false)
        }}
        autoHideDuration={3000}>
        <MuiAlert
          onClose={() => {
            setMailMessage(false)
          }}
          elevation={6}
          variant="filled"
          severity="success"
        >
          {message}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isProdWarning}
        autoHideDuration={5000}
        onClose={() => setIsProdWarning(false)}
      >
        <MuiAlert
          onClose={() => setIsProdWarning(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          Service part already exists in the list.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isRequiredDetailsNot}
        autoHideDuration={3000}
        onClose={() => setRequiredDetailsNot(false)}
      >
        <MuiAlert
          onClose={() => setRequiredDetailsNot(false)}
          elevation={6}
          variant="filled"
          severity="warning"
        >
          Enter required {isBDNotFilled?"Customer details ":""}{isCDNotFilled?"Consignee details ":""}{isDDNotFilled?"Dispatch details ":""}
        </MuiAlert>
      </Snackbar>
    </FormContext>
  );
};

export default JobCard;
