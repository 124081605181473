import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from '../common/util';
import { withStyles } from '@material-ui/styles';
import { transportModes } from "../common/constants"

const styles = theme => ({
  print: {
    '@media print': {
      display: 'block',
      pageBreakBefore: 'always',
    },
  },
  printTogether: {
    '@media print': {
      display: 'block',
      pageBreakInside: 'avoid',
    },
  }
});

const divStyle = {
  margin: '5px',
  width: '100%',
};
const pStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  textAlign: 'center',
  lineHeight: '1.6',
};
const pStyle2 = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '12px',
};
const tableStyle = {
  border: '1px solid #ccc',
  borderCollapse: 'collapse',
}

class CompToPrintInv extends React.Component {
  render() {
    const { classes } = this.props;
    const invDetails = this.props.customerInv != null && this.props.customerInv;
    const lineItems = this.props.customerInv != null && this.props.customerInv.lineItems;
    const labourCharges = this.props.customerInv != null && this.props.customerInv.labourCharges;
    const transportCharges = this.props.customerInv != null && this.props.customerInv.transportCharges;
    const customer = this.props.customerInv != null && this.props.customerInv.customer && this.props.customerInv.customer;
    const invShipDetails = this.props.customerInv != null && this.props.customerInv.invShipDetails && this.props.customerInv.invShipDetails;
    const invEWayDetails = this.props.customerInv != null && this.props.customerInv.invEWayDetails && this.props.customerInv.invEWayDetails;
    const invDispatchDetails = this.props.customerInv != null && this.props.customerInv.invDispatchDetails && this.props.customerInv.invDispatchDetails;
    const invCustmerDetails = this.props.customerInv != null && this.props.customerInv.invCustmerDetails && this.props.customerInv.invCustmerDetails;
    const custContacts = customer && customer.contacts && customer.contacts;
    const hsnSummary = invDetails && invDetails.HSNSummary;
    const paymentSummary = invDetails && invDetails.paymentSummary;
    const invOrgDetails = this.props.customerInv != null && this.props.customerInv.org && this.props.customerInv.org;
    let hsnTotal = 0;
    let hsnCGSTTotal = 0;
    let hsnSGSTTotal = 0;
    let hsnIGSTTotal = 0;
    hsnSummary && hsnSummary.map((row) => (
      hsnTotal += row.Total));
    hsnSummary && hsnSummary.map((row) => (
      hsnCGSTTotal += row.CGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnSGSTTotal += row.SGSTtotal));
    hsnSummary && hsnSummary.map((row) => (
      hsnIGSTTotal += row.IGSTtotal));
    let quantity = 0;
    invDetails.lineItems && invDetails.lineItems.map((row) => (
      quantity += row.qty));
    const invType = this.props.customerInv != null && this.props.customerInv.type;
    const isDeposit = invType && invType.code === "DEPOSIT";
    let isIntraState = false;
    if(invDetails && invDetails.org ){
      if(invCustmerDetails){
        isIntraState = (invCustmerDetails&& invCustmerDetails.state && invDetails.org.state && (invDetails.org.state === invCustmerDetails.state))?true:false;
      }else{
        isIntraState = (customer&& customer.state && invDetails.org.state && (invDetails.org.state === customer.state))?true:false;
      }
    }
    let transType = "";
    if(invEWayDetails && invEWayDetails.transportMode){
      transType = transportModes.find((data)=>{
        return (data.id === invEWayDetails.transportMode)?data:null;
      })
    }
    return (
      <div >
        <div className="row">
          {/* <div align="left" className="col-sm-6">
            <img alt="comLogo" src="https://res.cloudinary.com/dynamixe-digital-solution/image/upload/v1645520576/FSM/VACompanyLogo_sxuljv.png" 
            style={{ height: "100px" }} />
          </div> */}
             {(invDetails && invDetails.isEInvoice && invDetails.eInvQRCodeImg && (invDetails.isInvoiceStatus === "COMPLETED"))? <table style={{width:"100%", height:"250px", margin:"0px 15px", padding:"0px"}}>
                <tr>
                  <td align="left" style={{ verticalAlign:"bottom", paddingBottom:"15px"}}>
                    <span style={pStyle}><b>IRN</b>&nbsp;{invDetails && invDetails.eInvIRN}</span><br></br><br></br>
                    <span style={pStyle}><b>Ack No. </b>&nbsp;{invDetails && invDetails.eInvAckNo}</span><br></br><br></br>
                    <span style={pStyle}><b>Ack Date </b>&nbsp;{invDetails && invDetails.eInvAckDt && formatDate(invDetails.eInvAckDt)}</span>
                  </td>
                  <td align="right" style={{height:"250px"}}>{(invDetails && invDetails.eInvQRCodeImg)? <img alt="qrCodeImg" src={invDetails.eInvQRCodeImg} 
                    style={{ width:"250px", height: "250px",alignItems:"right" }} /> :null} </td>
                </tr>
              </table>:null}
        </div>
        
        {isDeposit ?
          <div style={divStyle} align="center">
            {/* Deposit Reciept */}
            {/* <Helmet><title>Vindhya Associates: Deposit Reciept</title></Helmet> */}
            <Helmet><title></title></Helmet>
            <div width="100%">
              <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name.toUpperCase()}</b></span><br />
              <span style={pStyle}>{invDetails && invDetails.location && (invDetails.location.address1.toUpperCase())}</span>
              <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address2.toUpperCase())}</div></span>
              <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address3.toUpperCase() + '-' + invDetails.location.pincode)}</div></span>
              <span style={pStyle}>{invDetails && invDetails.location && invDetails.location.mobileNumber ? invDetails.location.mobileNumber : "0824-2232444"}<br /></span>
              <span style={pStyle}>State :{invDetails && invDetails.locationState ? invDetails.locationState.name:  invDetails.org && (invDetails.org.state)}  Code: {invDetails && invDetails.org&&invDetails.org.GSTIN &&invDetails.org.GSTIN.slice(0,2)}<br /></span>
              <span style={pStyle}>GSTIN/UIN: {invDetails && invDetails.location && invDetails.location.GSTIN ? invDetails.location.GSTIN : (invDetails&&invDetails.org&&invDetails.org.GSTIN)?invDetails.org.GSTIN:"Not Available"}<br /></span>
              <span style={pStyle}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /><br /></span>
              <span style={pStyle}><b>Receipt Voucher<br /><br /><br /></b></span>
            </div>
            <div style={divStyle}>
              <table width="80%" padding="2px">
                <tr>
                  <td width="70%"><span style={pStyle}>No. : <b>{this.props.customerInv && this.props.customerInv.invoiceNum}</b></span></td>
                  <td width="30%"><span style={pStyle}>Dated : <b>{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</b></span></td>
                </tr>
              </table>
            </div>
            <div style={divStyle}>
              <table width="80%" style={{ borderCollapse: 'collapse' }} padding="2px">
                <thead>
                  <tr>
                    <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="75%">&nbsp;&nbsp;Particulars</td>
                    <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="25%" align="right">Amount</td>
                  </tr>
                </thead>
                <tbody>
                  {lineItems && lineItems.length === 0 ? (
                    <tr>
                      <td colspan="" align="center">
                        No records found
                      </td>
                    </tr>
                  ) : (
                    lineItems && lineItems.map((row, index) => (
                      <tr
                        key={index}
                        hover={true}
                      >
                        <td align="left" style={{ padding: '5px', borderRight: '1px solid #ccc' }} width="75%">
                          <span style={pStyle}><b>Account : </b><br /></span>
                          <span style={pStyle}><span style={{ marginLeft: '50px' }}>{(invCustmerDetails)?invCustmerDetails.tradeName:customer && customer.fullName}</span><br /></span>
                          <span style={pStyle} width="100%">
                            <span style={{ marginLeft: '50px' }}>Agst Ref : {row.productName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'} Cr</b></span>
                          </span><br /><br />
                          <span style={pStyle}><b>Through :</b><br /><span style={{ marginLeft: '50px' }}>{this.props.customerInv &&
                            this.props.customerInv.payments && this.props.customerInv.payments.length > 0 && this.props.customerInv.payments[0].paymentMode &&
                            this.props.customerInv.payments[0].paymentMode.description}</span><br /></span>
                          <span style={pStyle}><b>Amount (in words) :</b><br /><span style={{ marginLeft: '50px' }}>{invDetails && invDetails.amountInWords}</span></span>
                        </td>
                        <td align="right" valign="top">
                          <br /><br /><br />{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}
                        </td>
                      </tr>
                    ))
                  )}
                  <tr>
                    <td colspan="2" align="right" style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>{invDetails && invDetails.total ? (Math.round(invDetails.total * 100) / 100).toFixed(2) : '0.00'}</td>
                  </tr>
                  <tr>
                    <td colspan="2" align="right"><span style={pStyle}><br /><br />Authorised Signatory</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> :
          <div style={divStyle} align="center">
            {/* Invoice  */}
            {/* <Helmet><title>Vindhya Associates: Print Invoice</title></Helmet> */}
            <Helmet><title></title></Helmet>
            <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', textAlign: 'center', fontWeight: 'bold', lineHeight: '2', }}>Tax Invoice</span>
            <table style={tableStyle} width="95%" border="1px" color="#ccc">
              <tr>
                <td rowspan="4" width="50%">
                <table style={tableStyle} width="100%" border="0px" color="white" >
                  <tr>
                    <td width="60%">
                    <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name.toUpperCase()}<br /></b></span>
                      <span style={pStyle2}>
                        {invDetails && invDetails.location && (invDetails.location.address1.toUpperCase())}
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address2.toUpperCase())}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.location && (invDetails.location.address3.toUpperCase() + '-' + invDetails.location.pincode)}</div>
                        <div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.locationstate ? invDetails.locationstate.name : invDetails.location && invDetails.location.state}</div>
                        <span style={pStyle2}>{invDetails && invDetails.location && invDetails.location.mobileNumber ? invDetails.location.mobileNumber : "0824-2232444"}<br /></span>
                      </span>
                      <span style={pStyle2}>GSTIN/UIN: {invDetails && invDetails.org && invDetails.org.GSTIN ? invDetails.org.GSTIN : "Not Available"}<br /></span>
                      <span style={pStyle2}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}<br /></span>
                    
                    </td>
                    <td rowspan="4" width="50%" align="right">
                    <img alt="comLogo" src="https://res.cloudinary.com/dynamixe-digital-solution/image/upload/v1645520576/FSM/VACompanyLogo_sxuljv.png" 
                      style={{ height: "100px",width:"200px" }} />
                    </td>
                  </tr>
                </table>
                </td>
                <td width="25%">
                {(invDetails && invDetails.eInvEwbNo)?<div>
                  <table>
                      <tr>
                       <td style={pStyle2}>
                       Invoice No.
                       </td>
                       <td style={pStyle2} align="right">
                       e-Way Bill No.
                       </td>
                      </tr>
                      <tr>
                       <td style={pStyle2}>
                        <b>{this.props.customerInv && this.props.customerInv.invoiceNum}</b>
                       </td>
                       <td style={pStyle2} align="right">
                        <b>{invDetails.eInvEwbNo}</b>
                       </td>
                      </tr>
                    </table>
                    </div>:<div><span style={pStyle2}>Invoice No.</span><br />
                  <span style={pStyle2}><b>{this.props.customerInv && this.props.customerInv.invoiceNum}</b></span></div>}
                    
                    
                </td>
                <td width="25%"><span style={pStyle2}>Dated. <br /><b>{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</b></span></td>
              </tr>
              <tr>
                <td><span style={pStyle2}>Delivery Note.<br />&nbsp;</span></td>
                <td><span style={pStyle2}>Mode/Terms of Payment<br /><b>{this.props.customerInv &&
                  this.props.customerInv.payments && this.props.customerInv.payments.length > 0 &&
                  this.props.customerInv.payments[0].paymentMode &&
                  this.props.customerInv.payments[0].paymentMode.description}</b></span></td>
              </tr>
              <tr>
                <td><span style={pStyle2}>Reference No. & Date <br />&nbsp;</span><span style={pStyle2}>{(invEWayDetails&&invEWayDetails.vehicleNumber)?invEWayDetails.vehicleNumber:invDetails && invDetails.vehicleNo}&nbsp;{(invDetails &&( invDetails.vehicleNo || (invEWayDetails&&invEWayDetails.vehicleNumber)) && this.props.customerInv && this.props.customerInv.invoiceDate)?<b>Dt. </b>:""}{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</span></td>
                <td><span style={pStyle2}>Other Reference(s)<br />&nbsp;</span><span style={pStyle2}><b>{invDetails && invDetails.jobs && "JC-"+invDetails.jobs.id}</b></span></td>
                <tr>
                  <td><span style={pStyle2}>Buyer's Order No.<br /><b>{this.props.customerInv && this.props.customerInv.purchaseOrder && this.props.customerInv.purchaseOrder.poNum}</b></span></td>
                  <td><span style={pStyle2}>Dated<br /><b>{this.props.customerInv && this.props.customerInv.purchaseOrder && formatDate(this.props.customerInv.purchaseOrder.orderDate)}</b></span></td>
                </tr>
              </tr>
              <tr>
                <td rowspan="4">
                  <span style={pStyle2}>
                    Consignee (Ship to)<br /> <b>{(invShipDetails)?invShipDetails.tradeName:customer && customer.fullName}</b><br />
                    {(invShipDetails)?(invShipDetails.address1 + ' \n' + invShipDetails.address2 + ' \n' + invShipDetails.address3 + ' \n' + invShipDetails.state + '-' + invShipDetails.pincode):
                    customer && (customer.address1 + ' \n' + customer.address2 + ' \n' + customer.address3 + ' \n' + customer.state + '-' + customer.pincode)}<br />
                    GSTIN/UIN: {(invShipDetails)?invShipDetails.GSTIN:customer && customer.GSTIN}<br />
                    State: {(invShipDetails)?invShipDetails.state:customer && customer.state} {(invShipDetails && invShipDetails.GSTIN)?' Code: ' + invShipDetails.GSTIN.slice(0, 2):(customer && customer.GSTIN) ? ' Code: ' + customer.GSTIN.slice(0, 2) : ' '}<br />
                    Contact Person: {custContacts ? custContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                    Contact: {custContacts ? custContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                  </span>
                </td>
                <td><span style={pStyle2}>Dispatch Document No.<br />  <b>{(invEWayDetails && invEWayDetails.transporterDocNo)?invEWayDetails.transporterDocNo:invDetails && invDetails.dispatchDocNo}</b></span></td>
                <td><span style={pStyle2}>Delivery Note Date<br />&nbsp;</span></td>
              </tr>
              <tr>
                <td><span style={pStyle2}>Dispatched through<br />{(transType)?<b>{transType.id}-{transType.name}</b>:<b>{invDetails && invDetails.dispatchThrough}</b>}</span></td>
                <td><span style={pStyle2}>Destination<br /><b>{invShipDetails?invShipDetails.address3+" "+invShipDetails.locality:
                invCustmerDetails?invCustmerDetails.address3+" "+invCustmerDetails.locality:customer?customer.address3+" "+customer.locality:""}</b></span></td>
              </tr>
              <tr>
                <td><span style={pStyle2}>Vehicle No.<br /><b>{(invEWayDetails&&invEWayDetails.vehicleNumber)?invEWayDetails.vehicleNumber:invDetails && invDetails.vehicleNo}</b></span></td>
                <td><span style={pStyle2}>Vehicle LR-RR<br /><b>{invDetails && invDetails.vehicleLLR}</b></span></td>
              </tr>
              <tr>
                <td colspan="2" style={{ borderBottom: '1px solid #fff' }}><span style={pStyle2}>Terms of Delivery</span></td>
              </tr>
              <tr>
                <td>
                  <span style={pStyle2}>
                    Buyer (Bill to) <br /> <b>{(invCustmerDetails)?invCustmerDetails.tradeName:customer && customer.fullName}</b><br />
                    {(invCustmerDetails)?(invCustmerDetails.address1 + ' \n' + invCustmerDetails.address2 + ' \n' + invCustmerDetails.address3 + ' \n' + invCustmerDetails.state + '-' + invCustmerDetails.pincode):
                    customer && (customer.address1 + ' \n' + customer.address2 + ' \n' + customer.address3 + ' \n' + customer.state + '-' + customer.pincode)}<br />
                    GSTIN/UIN: {(invCustmerDetails)?invCustmerDetails.GSTIN:customer && customer.GSTIN}<br />
                    State: {(invCustmerDetails)?invCustmerDetails.state:customer && customer.state} {(invCustmerDetails && invCustmerDetails.GSTIN) ? ' Code: ' + invCustmerDetails.GSTIN.slice(0, 2) :(customer && customer.GSTIN) ? ' Code: ' + customer.GSTIN.slice(0, 2) : ' '}<br />
                    Contact Person: {custContacts ? custContacts.map((row) => (<span>{row.contactName + ", "}</span>)) : null}<br />
                    Contact: {custContacts ? custContacts.map((row) => (<span>{row.mobileNumber + ", "}</span>)) : null}
                  </span>
                </td>
                <td colspan="2"></td>
              </tr>
            </table>
            <div style={{ width: '100%', padding: '5px 2px' }}>
              <table style={tableStyle} width="95%">
                <thead>
                  <tr>
                    <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Sl No.</span></td>
                    <td width="25%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Description of Goods/Services</span></td>
                    <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                    <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>GST Rate</span></td>
                    <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Quantity</span></td>
                    <td width="10%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                    <td width="3%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>per</span></td>
                    <td width="7%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Disc.%</span></td>
                    <td width="15%" align="center" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                  </tr>
                </thead>
                <tbody>
                  {((lineItems && lineItems.length === 0) && (labourCharges === 0 && transportCharges === 0)) ? (
                    <tr>
                      <td colspan="9" align="center">
                        <span style={pStyle2}>No records found</span>
                      </td>
                    </tr>
                  ) : (
                    lineItems && lineItems.map((row, index) => (
                      <tr
                        key={index}
                        hover={true}
                      >
                        <td align="center" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {index + 1}
                          </span>
                        </td>
                        <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            <b>{row.productName}</b>
                          </span>
                        </td>
                        <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.HSNCode}
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.IGST} %
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            <b>{row.qty} {row.uom}</b>
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.unitPrice ? (Math.round(row.unitPrice * 100) / 100).toFixed(2) : ''}
                          </span>
                        </td>
                        <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.uom}
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            {row.discountPct ? (Math.round(row.discountPct * 100) / 100).toFixed(2) : ''}
                          </span>
                        </td>
                        <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                          <span style={pStyle2}>
                            <b>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</b>
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                  {lineItems && lineItems.length > 0 &&
                    <tr>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                      <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                        <span style={pStyle2}>{invDetails.subtotal ? (Math.round(invDetails.subtotal * 100) / 100).toFixed(2) : '0.00'}</span>
                      </td>
                    </tr>
                  }
                  {labourCharges && labourCharges > 0 ? <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Labour Charges</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.labourCharges ? (Math.round(invDetails.labourCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr> : ''}
                  {transportCharges && transportCharges > 0 ? <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>Transportation Charges (Sales)/packing</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.transportCharges ? (Math.round(invDetails.transportCharges * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr> : ''}
                  {isIntraState && <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>CGST</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.CGST ? (Math.round(invDetails.CGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr>}
                  {isIntraState && <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>SGST</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.SGST ? (Math.round(invDetails.SGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr>}
                  {(!isIntraState) && <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><b>IGST</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.IGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr>}
                  {invDetails.roundOff !== 0 && <tr>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}>&nbsp;</td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}><span style={pStyle2}><i>{invDetails.roundOff < 0 ? 'Less:' : ''}</i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={pStyle2}><b><i>Round Off</i></b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                      <span style={pStyle2}><b>{invDetails.roundOff ? (invDetails.roundOff > 0 ? (Math.round(invDetails.roundOff * 100) / 100).toFixed(2) : '(-)' + (Math.round(invDetails.roundOff * (-100)) / 100).toFixed(2)) : '0.00'}</b></span>
                    </td>
                  </tr>}
                  <tr>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{quantity}</b></span></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="left" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}></td>
                    <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle}><b>{invDetails.total ? (Math.round(invDetails.total * 100) / 100).toFixed(2) : '0.00'}</b></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.printTogether}>
              <table style={tableStyle} width="95%">
                <tbody>
                  <tr>
                    <td width="50%" style={{ borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>Amount Chargeable(in words) :</span>
                    </td>
                    <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}><i>E. &amp; O.E</i>&nbsp;</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle}><b>{invDetails.amountInWords}</b><br /><br /></span>
                    </td>
                  </tr>
                  {invDetails.remarkNote?<tr>
                    <td colspan="2" style={{ borderBottom: '1px solid #ccc' }}>
                      <span style={pStyle}><b>Remarks :</b>&nbsp;{invDetails.remarkNote}<br /><br /></span>
                    </td>
                  </tr>:null}
                  <tr>
                    <td colspan="2">
                      &nbsp;<br />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <span style={pStyle2}>Company’s PAN : <b>{invDetails && invDetails.org && invDetails.org.PAN ? invDetails.org.PAN : "Not Available"}</b></span>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" align="left">
                      <span style={pStyle2}><u>Declaration</u><br />Goods once sold will not be taken back. Subject to<br />
                        Mangalore jurisdiction.Goods whenever guarranteed or<br />
                        warranteed are done by the manufacturers, not by us.<br />
                        CST @2% against C form OR as applicable.</span>
                    </td>
                    <td>
                      <span style={pStyle2}>Company's Bank Details<br />
                        Bank Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>ICICI BANK</b><br />
                        A/c No. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <b>115305000053</b><br />
                        Branch &amp; IFS Code &nbsp;: <b>KULSHEKARA BRANCH &amp; ICIC0002418</b>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>Customer Seal and Signature<br /><br /><br /></span>
                    </td>
                    <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                      <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
             {/* e-Way Bill Section */}
             {(invDetails&& invDetails.isEInvoice && invDetails.isEWayBill && (invDetails.isEWayBillStatus === "COMPLETED")) &&
              <div className={classes.print}>
                <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '15px', textAlign: 'center', fontWeight: 'bold', lineHeight: '1.6', }}>
                  E-Way bill 
                </span>
                <div className="row">
                  <table style={{width:"100%", height:"150px", margin:"0px 15px", padding:"0px"}}>
                      <tr>
                        <td align="left" style={{ verticalAlign:"bottom", paddingBottom:"15px"}}>
                          <span style={pStyle}><b>Doc No.</b>&nbsp;Tax Invoice - {this.props.customerInv && this.props.customerInv.invoiceNum}</span><br></br>
                          <span style={pStyle}><b>Date</b>&nbsp;{invDetails && invDetails.eInvEwbDt && formatDate(invDetails.eInvEwbDt)}</span><br></br>
                          <span style={pStyle}><b>IRN</b>&nbsp;{invDetails && invDetails.eInvIRN}</span><br></br>
                          <span style={pStyle}><b>Ack No. </b>&nbsp;{invDetails && invDetails.eInvAckNo}</span><br></br>
                          <span style={pStyle}><b>Ack Date </b>&nbsp;{invDetails && invDetails.eInvAckDt && formatDate(invDetails.eInvAckDt)}</span>
                        </td>
                        <td align="right" style={{ verticalAlign:"bottom"}}>{(invDetails && invDetails.ewbImg)? <img alt="qrCodeImg" src={invDetails.ewbImg} 
                          style={{ width:"150px", height: "150px",alignItems:"right" }} /> :null} </td>
                      </tr>
                    </table>
                </div>
                <div style={{textAlign:"left", border:'1px solid #ccc', width:"95%", padding:"15px"}}><b>E-Way Bill Details </b><br></br><br></br>
                    <table style={tableStyle} width="100%" border="1px" color="#ccc">
                      <tr>
                        <td align="left" width="33.33%" style={{ border: '1px solid #fff' }}>
                          <span style={pStyle}>E-way Bill No. <b>{this.props.customerInv && this.props.customerInv.eInvEwbNo}</b></span><br></br>
                          <span style={pStyle}>Generated By. <b>{(invOrgDetails)?invOrgDetails.GSTIN:"--"}</b></span><br></br>
                          <span style={pStyle}>Supply Type. <b>{(invEWayDetails && invEWayDetails.supplyType)}-{(invEWayDetails && invEWayDetails.supplySubType)}</b></span>
                        </td>
                        <td align="left" width="33.33%" style={{border: '1px solid #fff' }}>
                          <span style={pStyle}>Mode {(transType)?<b>{transType.id}-{transType.name}</b>:<b>--</b>}</span><br></br>
                          <span style={pStyle}>Approx Distance <b>{(invEWayDetails && invEWayDetails.distance)?invEWayDetails.distance:"--"}</b></span><br></br>
                          <span style={pStyle}>Transaction Type <b>{(invOrgDetails)?invOrgDetails.GSTINType:"--"}</b>&nbsp;</span>
                        </td>
                        <td align="left" width="33.33%" style={{border: '1px solid #fff'}}>
                          <span style={pStyle}>Gen. Date <b>{invDetails && invDetails.eInvEwbDt && formatDate(invDetails.eInvEwbDt)}, {invDetails && invDetails.eInvEwbDt && new Date(invDetails.eInvEwbDt).toLocaleTimeString()}</b></span><br></br>
                          <span style={pStyle}>Valid Upto <b>{invDetails && invDetails.eInvEwbValid && formatDate(invDetails.eInvEwbValid)}, {invDetails && invDetails.eInvEwbValid && new Date(invDetails.eInvEwbValid).toLocaleTimeString()}</b></span><br></br>
                          <span style={pStyle}>&nbsp;</span>
                        </td>
                      </tr>
                    </table>
                </div>
                <div style={{textAlign:"left", border:'1px solid #ccc', width:"95%", padding:"15px", marginTop:"10px"}}><b>Address Details </b><br></br><br></br>
                    <table style={tableStyle} width="100%" border="1px" color="#ccc">
                      <tr>
                        <td align="left" width="50%" style={{ border: '1px solid #fff', verticalAlign:"top" }}>
                          <span style={pStyle}><b>From<br /> </b></span>
                          <span style={pStyle}>
                            {(invOrgDetails)?invOrgDetails.name:""}<br />
                            GSTIN/UIN: {(invOrgDetails)?invOrgDetails.GSTIN:""}<br />
                            State: {(invOrgDetails)?invOrgDetails.state:""} <br />
                            {/* Phone No: {invOrgDetails &&invOrgDetails.phoneNo}<br /> */}
                            {/* Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"} */}
                          </span>
                          <span style={pStyle}><b>Dispatch From (Ship From)<br /> </b></span>
                            <span style={pStyle}>
                              {/* {(invDispatchDetails)?invDispatchDetails.tradeName:invOrgDetails && invOrgDetails.name}<br /> */}
                              {(invDispatchDetails)?(invDispatchDetails.address3 + ' \n' + invDispatchDetails.locality + " "+ invDispatchDetails.state + '-' + invDispatchDetails.pincode):
                              invOrgDetails && (invOrgDetails.address3 + ' \n' + invDispatchDetails.locality +" "+ invOrgDetails.state + '-' + invOrgDetails.pincode)}<br />
                            </span>
                        </td>
                        <td align="left" width="50%" style={{border: '1px solid #fff', verticalAlign:"top" }}>
                          <span style={pStyle}><b>Buyer (Bill To)<br /> </b></span>
                          <span style={pStyle}>
                            {(invCustmerDetails)?invCustmerDetails.tradeName:customer && customer.fullName}<br />
                            GSTIN/UIN: {(invCustmerDetails)?invCustmerDetails.GSTIN:customer && customer.GSTIN}<br />
                            State: {(invCustmerDetails)?invCustmerDetails.state:customer && customer.state} <br />
                          </span>
                          <span> </span>
                          <span style={pStyle}><b>Consignee (Ship To)<br /> </b></span>
                            <span style={pStyle}>
                              {(invShipDetails)?(invShipDetails.address1 + ' \n' + invShipDetails.address2 + ' \n' + invShipDetails.address3+" "+invShipDetails.locality +' \n' + invShipDetails.state + '-' + invShipDetails.pincode):
                              customer && (customer.address1 + ' \n' + customer.address2 + ' \n' + customer.address3 + ' \n' + customer.locality+" "+customer.state + '-' + customer.pincode)}<br />
                            </span>
                        </td>
                      </tr>
                    </table>
                </div>
                <div style={{border:'1px solid #ccc', width:"99%", marginTop:"10px"}}>
                    {/* <div style={{textAlign:"left", borderBottom:"2px solid #ccc"}}><span style={{padding:"15px 0px 0px 15px"}}><b>Goods Details : </b></span><br></br>

                    </div><br></br> */}
                  <div style={{textAlign:"left", borderBottom:"2px solid #ccc"}}><div style={{padding:"15px"}}><b>Goods Details </b><br></br><br></br>
                    <table style={tableStyle} width="100%" border="1px" color="#ccc">
                      <thead>
                        <tr style={{borderBottom:"2px solid #ccc", marginBottom:"50px"}}>
                          <td width="3%" align="left" style={{ border: '1px solid #fff', padding:"5px 0px"  }}><span style={pStyle}>HSN Code</span></td>
                          <td width="25%" align="left" style={{ border: '1px solid #fff', padding:"5px 0px"  }}><span style={pStyle}>Product Name</span></td>
                          <td width="10%" align="left" style={{ border: '1px solid #fff', padding:"5px 0px"  }}><span style={pStyle}>Quantity</span></td>
                          <td width="10%" align="left" style={{ border: '1px solid #fff', padding:"5px 0px"  }}><span style={pStyle}>Taxable Amt</span></td>
                          <td width="10%" align="left" style={{ border: '1px solid #fff', padding:"5px 0px"  }}><span style={pStyle}>Tax Rate</span></td>
                        </tr>
                      </thead>
                      <tbody>
                      {lineItems && lineItems.length === 0 ? null
                        : (
                          lineItems && lineItems.map((row, index) => {
                            console.log("row",row);
                            return(
                            <tr
                              key={index}
                              style={(index+1 === hsnSummary.length )?{}:{borderBottom:"2px solid #ccc"}}
                              >
                              <td align="left" style={{ border: '1px solid #fff', padding:"5px 0px" }}>
                                <span style={pStyle}>{row.HSNCode}</span></td>
                              <td align="left" style={{ border: '1px solid #fff', padding:"5px 0px"  }}>
                                <span style={pStyle}>{row.productName}</span></td>
                              <td align="left" style={{ border: '1px solid #fff', padding:"5px 0px"  }}>
                                <span style={pStyle}>{row.qty}</span></td>
                              <td align="left" style={{ border: '1px solid #fff', padding:"5px 0px"  }}>
                                <span style={pStyle}>{row.unitPrice ? (Math.round(row.unitPrice * 100) / 100).toFixed(2) : '0.00'}</span></td>
                              <td align="left" style={{ border: '1px solid #fff', padding:"5px 0px"  }}>
                                <span style={pStyle}>{row.IGST ? (Math.round(row.IGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                              </tr>
                          )})
                      )}
                    </tbody>
                    </table></div>
                </div>
                    <div style={{padding:"15px"}}>
                      <table style={tableStyle} width="100%" border="1px" color="#ccc">
                        <tr>
                          <td align="left" width="33.33%" style={{ border: '1px solid #fff' }}>
                            <span style={pStyle}>Total Taxable Amt <b>{invDetails.subtotal ? (Math.round(invDetails.subtotal * 100) / 100).toFixed(2) : '0.00'}</b></span><br></br>
                            <span style={pStyle}>IGST Amt <b>{(!isIntraState)?invDetails.IGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00': '0.00'}</b></span><br></br>
                          </td>
                          <td align="left" width="33.33%" style={{border: '1px solid #fff' }}>
                            <span style={pStyle}>Other Amt <b>{(invDetails.transportCharges && invDetails.labourCharges)?(Math.round((invDetails.transportCharges+invDetails.labourCharges) * 100) / 100).toFixed(2):(invDetails.transportCharges) ? (Math.round(invDetails.transportCharges * 100) / 100).toFixed(2) : invDetails.labourCharges ? (Math.round(invDetails.labourCharges * 100) / 100).toFixed(2) : '0.00'}
                            </b></span><br></br>
                            <span style={pStyle}>SGST Amt <b>{invDetails.SGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00'}</b></span><br></br>
                          </td>
                          <td align="left" width="33.33%" style={{border: '1px solid #fff'}}>
                            <span style={pStyle}>Total Inv Amt <b>{invDetails.total ? (Math.round(invDetails.total * 100) / 100).toFixed(2) : '0.00'}</b></span><br></br>
                            <span style={pStyle}>CGST Amt <b>{invDetails.CGST ? (Math.round(invDetails.IGST * 100) / 100).toFixed(2) : '0.00'}</b></span><br></br>
                          </td>
                        </tr>
                      </table>
                    </div>
                </div>
                <div style={{textAlign:"left", border:'1px solid #ccc', width:"95%", padding:"15px", marginTop:"10px"}}><b>Transportation Details </b><br></br><br></br>
                    <table style={tableStyle} width="100%" border="1px" color="#ccc">
                      <tr>
                        <td align="left" width="50%" style={{ border: '1px solid #fff' }}>
                          <span style={pStyle}>Transporter Id <b>{(invEWayDetails && invEWayDetails.transporterId)?invEWayDetails.transporterId:"--"}</b></span><br></br>
                          <span style={pStyle}>Name <b>{(invEWayDetails && invEWayDetails.transporterName)?invEWayDetails.transporterName:"--"}</b></span><br></br>
                        </td>
                        <td align="left" width="50%" style={{border: '1px solid #fff' }}>
                          <span style={pStyle}>Doc No. <b>{(invEWayDetails && invEWayDetails.transporterDocNo)?invEWayDetails.transporterDocNo:"--"}</b></span><br></br>
                          <span style={pStyle}>Date <b>{invEWayDetails && invEWayDetails.transporterDocDate && formatDate(invEWayDetails.transporterDocDate)}</b></span><br></br>
                        </td>
                      </tr>
                    </table>
                </div>
                <div style={{textAlign:"left", border:'1px solid #ccc', width:"95%", padding:"15px", marginTop:"10px"}}><b>Vehicle Details </b><br></br><br></br>
                    <table style={tableStyle} width="100%" border="1px" color="#ccc">
                      <tr>
                        <td align="left" width="50%" style={{ border: '1px solid #fff' }}>
                          <span style={pStyle}>Vehicle No. <b>{(invEWayDetails && invEWayDetails.vehicleNumber)?invEWayDetails.vehicleNumber:"--"}</b></span><br></br>
                          <span style={pStyle}>From <b>{(invDispatchDetails && invDispatchDetails.locality)?invDispatchDetails.locality:"--"}</b></span><br></br>
                        </td>
                        <td align="left" width="50%" style={{border: '1px solid #fff' }}>
                          <span style={pStyle}>CEWD No. <b>--</b></span><br></br>
                          <span style={pStyle}></span><br></br>
                        </td>
                      </tr>
                    </table>
                </div>
              </div>}
            {/* HSN Summary Section */}
            {(hsnSummary && hsnSummary.length > 0) &&
              <div className={classes.print}>
                <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '15px', textAlign: 'center', fontWeight: 'bold', lineHeight: '1.6', }}>
                  Tax Invoice<br />(Tax Analysis)
                </span>
                <div>
                  <table style={tableStyle} width="90%" border="1px" color="#ccc">
                    <tr>
                      <td align="left" width="50%" style={{ borderRight: '1px solid #fff', borderBottom: '1px solid #fff' }}>
                        <span style={pStyle}>Invoice No. <b>{this.props.customerInv && this.props.customerInv.invoiceNum}</b></span>
                      </td>
                      <td align="right" width="50%" style={{ borderBottom: '1px solid #fff' }}>
                        <span style={pStyle}>Dated <b>{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</b>&nbsp;</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" align="center">
                        <span style={pStyle}><b>{invDetails && invDetails.org && invDetails.org.name}</b></span><br />
                        <span style={pStyle}>{invDetails && invDetails.org && (invDetails.org.address1)}</span><br />
                        <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address2)}</div></span>
                        <span style={pStyle}><div style={{ whiteSpace: 'pre-line' }}>{invDetails && invDetails.org && (invDetails.org.address3 + '-' + invDetails.org.pincode)}</div></span>
                        <span style={pStyle}>{invDetails && invDetails.org && invDetails.org.phoneNo ? invDetails.org.phoneNo : "0824-2232444"}<br /></span>
                        <span style={pStyle}>State :{invDetails && invDetails.org && (invDetails.org.state)} Code: {invDetails && invDetails.org && invDetails.org.GSTIN.slice(0, 2)}</span><br />
                        <span style={pStyle}>GSTIN/UIN: {invDetails && invDetails.org && invDetails.org.GSTIN ? invDetails.org.GSTIN : "Not Available"}</span><br />
                        <span style={pStyle}>Email: {invDetails && invDetails.org && invDetails.org.email ? invDetails.org.email : "Not Available"}</span><br />
                        <span style={pStyle}>Party : <span style={{ fontWeight: 'bold' }}>{(invCustmerDetails)?invCustmerDetails.tradeName:customer && customer.fullName}</span><br />
                          <span style={pStyle}>
                            <div style={{ whiteSpace: 'pre-line' }}>
                              {(invCustmerDetails&& invCustmerDetails.address1)?invCustmerDetails.address1:(customer && customer.address1) ? customer.address1 + ' \n' : ''}
                              {(invCustmerDetails&& invCustmerDetails.address2)?invCustmerDetails.address2:(customer && customer.address2) ? customer.address2 + ' \n' : ''}
                              {(invCustmerDetails&& invCustmerDetails.address3)?invCustmerDetails.address3:(customer && customer.address3 + '-' + customer.pincode) ? customer.address3 + '-' + customer.pincode + ' \n' : ''}
                            </div>
                          </span>
                          <span style={pStyle}>GSTIN/UIN: {(invCustmerDetails&& invCustmerDetails.GSTIN)?invCustmerDetails.GSTIN:(customer && customer.GSTIN) ? customer.GSTIN : 'Not Available'}</span><br />
                          <span style={pStyle}></span>State: {(invCustmerDetails&& invCustmerDetails.state)?invCustmerDetails.state:(customer && customer.state) ? customer.state : 'Not Available'} {(invCustmerDetails && invCustmerDetails.GSTIN) ? ' Code: ' + invCustmerDetails.GSTIN.slice(0, 2) :(customer && customer.GSTIN) ? ' Code: ' + customer.GSTIN.slice(0, 2) : ' '}</span><br />
                      </td>
                    </tr>
                  </table>
                  {(!isIntraState) ?
                    (
                      <div>
                        <table style={tableStyle} width="90%">
                          <thead>
                            <tr>
                              <td align="center" width="50%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                              <td align="center" width="15%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Taxable <br />Value</span></td>
                              <td align="center" width="20%" colspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Integrated Tax</span></td>
                              <td align="center" width="15%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Total <br /> Tax Amount</span></td>
                            </tr>
                            <tr>
                              <td align="center" width="7%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                              <td align="center" width="13%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                            </tr>
                          </thead>
                          <tbody>
                            {hsnSummary && hsnSummary.length === 0 ? (
                              <tr>
                                <td colSpan={7} align="center">
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              hsnSummary && hsnSummary.map((row, index) => (
                                <tr
                                  key={index}
                                  hover={true}
                                >
                                  <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.HSNCode}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.Total ? (Math.round(row.Total * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.IGST ? (Math.round(row.IGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.IGSTtotal ? (Math.round(row.IGSTtotal * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{(row.IGSTtotal ? (Math.round(row.IGSTtotal * 100) / 100).toFixed(2) : '0.00')}</span></td>
                                </tr>
                              ))
                            )}
                            <tr>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnTotal ? (Math.round(hsnTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnIGSTTotal ? (Math.round(hsnIGSTTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{invDetails && invDetails.totalTax ? (Math.round(invDetails.totalTax * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className={classes.printTogether}>
                          <table style={tableStyle} width="90%">
                            <tbody>
                              <tr>
                                <td colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                  <span style={pStyle2}>Tax Amount (in words) :</span> <span style={pStyle}><b>{invDetails && invDetails.taxAmountInWords}</b></span><br /><br />
                                </td>
                              </tr>
                              <tr>
                                <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                  <span style={pStyle2}><br /><br /><br /></span>
                                </td>
                                <td width="50%" align="right" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <table style={tableStyle} width="90%">
                          <thead>
                            <tr>
                              <td align="center" width="30%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>HSN/SAC</span></td>
                              <td align="center" width="15%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Taxable <br />Value</span></td>
                              <td align="center" width="20%" colspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Central Tax</span></td>
                              <td align="center" width="20%" colspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>State Tax</span></td>
                              <td align="center" width="15%" rowspan="2" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Total <br /> Tax Amount</span></td>
                            </tr>
                            <tr>
                              <td align="center" width="7%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                              <td align="center" width="13%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                              <td align="center" width="7%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Rate</span></td>
                              <td align="center" width="13%" style={{ borderRight: '1px solid #ccc', borderBottom: '1px solid #ccc' }}><span style={pStyle2}>Amount</span></td>
                            </tr>
                          </thead>
                          <tbody>
                            {hsnSummary && hsnSummary.length === 0 ? (
                              <tr>
                                <td colSpan={5} align="center">
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              hsnSummary && hsnSummary.map((row, index) => (
                                <tr
                                  key={index}
                                  hover={true}
                                >
                                  <td align="left" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.HSNCode}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.Total ? (Math.round(row.Total * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.CGST ? (Math.round(row.CGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.CGSTtotal ? (Math.round(row.CGSTtotal * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.SGST ? (Math.round(row.SGST * 100) / 100).toFixed(2) : '0.00'}%</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{row.SGSTtotal ? (Math.round(row.SGSTtotal * 100) / 100).toFixed(2) : '0.00'}</span></td>
                                  <td align="right" style={{ borderRight: '1px solid #ccc' }}>
                                    <span style={pStyle2}>{(Math.round((row.CGSTtotal + row.SGSTtotal) * 100) / 100).toFixed(2)}</span></td>
                                </tr>
                              ))
                            )}
                            <tr>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>Total</b></span></td>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnTotal ? (Math.round(hsnTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnCGSTTotal ? (Math.round(hsnCGSTTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{hsnSGSTTotal ? (Math.round(hsnSGSTTotal * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                              <td align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}><span style={pStyle2}><b>{invDetails && invDetails.totalTax ? (Math.round(invDetails.totalTax * 100) / 100).toFixed(2) : '0.00'}</b></span></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className={classes.printTogether}>
                          <table style={tableStyle} width="90%">
                            <tbody>
                              <tr>
                                <td colspan="2" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                  <span style={pStyle2}>Tax Amount (in words) :</span> <span style={pStyle}><b>{invDetails && invDetails.taxAmountInWords}</b></span><br /><br />
                                </td>
                              </tr>
                              <tr>
                                <td width="50%" align="left" valign="top" style={{ borderRight: '1px solid #ccc' }}>
                                  <span style={pStyle2}><br /><br /><br /></span>
                                </td>
                                <td width="50%" align="right" style={{ borderRight: '1px solid #ccc', borderTop: '1px solid #ccc' }}>
                                  <span style={pStyle2}>for {invDetails && invDetails.org && invDetails.org.name}<br /><br /><br />Authorised Signatory</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>}
            {/* Payment Summary */}
            {(paymentSummary && paymentSummary.length > 0) &&
              <div className={classes.print}>
                <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '15px', textAlign: 'center', fontWeight: 'bold', lineHeight: '1.6', }}>
                  <br /><br />Payment Summary<br /><br /><br /><br />
                </span>
                <div style={divStyle}>
                  <table width="80%" padding="2px">
                    <tr>
                      <td width="70%"><span style={pStyle}>Invoice No. : <b>{this.props.customerInv && this.props.customerInv.invoiceNum}</b></span></td>
                      <td width="30%"><span style={pStyle}>Dated : <b>{this.props.customerInv && formatDate(this.props.customerInv.invoiceDate)}</b></span></td>
                    </tr>
                  </table>
                </div>
                <div style={divStyle}>
                  <table width="80%" style={{ borderCollapse: 'collapse' }} padding="2px">
                    <thead>
                      <tr>
                        <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="5%">&nbsp;&nbsp;S.No</td>
                        <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="70%">&nbsp;&nbsp;Particulars</td>
                        <td style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }} width="25%" align="right">Amount</td>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentSummary && paymentSummary.length === 0 ? (
                        <tr>
                          <td colspan="" align="center">
                            No records found
                          </td>
                        </tr>
                      ) : (
                        paymentSummary && paymentSummary.map((row, index) => (
                          <tr
                            key={index}
                            hover={true}
                          >
                            <td align="center" style={{ padding: '5px', borderRight: '1px solid #ccc' }} valign="top" width="5%">
                              <span style={pStyle}>{row.lineNo}</span>
                            </td>
                            <td align="left" style={{ padding: '5px', borderRight: '1px solid #ccc' }} width="70%">
                              <span style={pStyle}>{row.desc}</span>
                            </td>
                            <td align="right" valign="top" width="25%">
                              <span style={pStyle}>{row.amount ? (Math.round(row.amount * 100) / 100).toFixed(2) : '0.00'}</span>
                            </td>
                          </tr>
                        ))
                      )}
                      <tr>
                        <td colspan="3" align="right" style={{ fontWeight: 'bold', padding: '5px', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc' }}>{invDetails && invDetails.netPayable ? (Math.round(invDetails.netPayable * 100) / 100).toFixed(2) : '0.00'}</td>
                      </tr>
                      <tr>
                        <td colspan="3" align="left"><span style={pStyle}><b>Amount (in words) :</b><br /><span style={{ marginLeft: '50px' }}>{invDetails && invDetails.paymentAmtinWords}</span></span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>}
          </div>}
      </div>
    );
  }
}
export default withStyles(styles)(CompToPrintInv);