import React, { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import calculateIcon from '../../../images/calculate.svg';
import {
  Typography,
  TextField,
  FormHelperText,
  IconButton,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  ListItem,
  List
} from "@material-ui/core";
import { StyledHeaderCell } from "../../common/StyledHeaderCell";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import _ from 'lodash';
import moment from 'moment';
import SearchIcon from "@material-ui/icons/Search";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";
import { API_PURCHASE_ORDER } from "../../../config/constants";
import DateFnsUtils from "@date-io/date-fns";
import { poStatus, poTypes, locations } from '../../common/lookupSlice';
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchProduct from "../../productGroups/searchProduct";
import Calculator from '../../recordSales/calculator';
let errorMsg;

function PurchaseForm(props) {
  const { id } = useParams();
  const poStatusLookUp = useSelector(poStatus);
  const inventoryLocations = useSelector(locations);
  const corporateLocation = inventoryLocations.filter(loc=>loc.type && loc.type.code === "CORPORATE");
  const corporateLocationId = corporateLocation.length>0 ? corporateLocation[0].id:1;
  const [selectProduct, setSelectProduct] = useState({ open: false,test: null });
  const poTypesLookUp = useSelector(poTypes);
  const token = localStorage.getItem("authToken");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [negPrice,setNegPrice] = useState([]);
  const [errQtyProIndex, setErrQtyProd] = useState([]);
  const [calculate, setCalculate] = useState(null);
  const sts_id = props.form.data.status ? props.form.data.status.id : '';
  const [date, setDate] = useState({orderDate: props.form.data.orderDate ? moment(props.form.data.orderDate).format('DD/MM/YYYY'):null});
  const { handleSubmit, control, errors, register, setValue,getValues } = useForm({
    defaultValues: {
      poNum: props.form.data.poNum,
      customerId: props.form.data ? props.form.data.customerId : id,
      orderDate: date.orderDate,
      statusId: props.form.data.status ? props.form.data.status.id : 1,
      typeId: props.form.data.type ? props.form.data.type.id :(props.isQuotation)?3: 2,
      lineItems: props.form.data.lineItems,
      isQtyDecimal: props.form.data.lineItems && props.form.data.lineItems.isQtyDecimal ? props.form.data.lineItems.isQtyDecimal : "",
    },
  });
  const [priceData, setPriceData] = useState({ open: false });
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: "lineItems",
  });

  const onSubmit = async (data, type) => {
    if(data.orderDate){
      var startDateArray = data.orderDate && data.orderDate.split("/");
      data.orderDate = startDateArray[1] + '/' + startDateArray[0] + '/' + startDateArray[2];
    }
    let errqtyProd = [];
    if(data.lineItems){
    data.customerId = id;
    data.statusId = data.statusId ? data.statusId : 1; 
    if(!data.typeId){
      delete data.typeId;
    } 
    delete data.id;
    setIsProcessing(true);
    setIsError(false);
    errorMsg = "";
    if (data.lineItems) {
      let modifiedItems = data.lineItems.map((obj, objIndex) => {
        obj.id = obj.contactId;
        let isQtyErr =false;
        if(obj.isQtyDecimal === "false"){
          if(!(Number.isInteger(parseFloat(obj.orderQty)))){
            isQtyErr = true;
          }
        }
        if(isQtyErr){
          errqtyProd.push(objIndex)
        } 
        delete obj["id"];
        return obj;
      });
      data.lineItems = JSON.stringify(modifiedItems);
    }else{
      data.lineItems = "[]";
    }
    if(errqtyProd.length === 0){
      if(type==="SAVE"){
        try {
          if (props.form.mode === "C") {
            
            await axios.post(
              `${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } else if (props.form.mode === "U") {
            await axios.patch(
              `${process.env.REACT_APP_SERVICE_URL}/${API_PURCHASE_ORDER}/${props.form.data.poNum}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          }
          setIsProcessing(false);
          props.success();
        } catch (err) {
          errorMsg = err.response.data.message;
          setIsError(true);
          setIsProcessing(false);
        }
      }else{
        try {
          let modifiedItems = JSON.parse(data.lineItems)
          const lineItemsReq = modifiedItems && modifiedItems.map(x => 
            {
            const lineItem = {
              productId: x && x.productId && Number(x.productId),
              qty: x.orderQty && Number(x.orderQty),
              overridenUnitPrice: x.negotiatedPrice && Number(x.negotiatedPrice),
              overridenDiscountPcnt: x.discountPct && Number(x.discountPct),
            }
            return lineItem;
          });
          const reqObj = {
            customerId: props && props.existCustData && props.existCustData.id,
            custTypeId: props && props.existCustData && props.existCustData.custTypeId,
            locationId: 6,
            lineItems: lineItemsReq
          }
          const result = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/pos/calculate`,
            reqObj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          let tempcalculate = (result && result.data) ? result.data : []
          const salesValue = tempcalculate.lineItems.map((x, y) => {
            let discAmount =  x.amount ? (Math.round((parseFloat(x.amount)/parseFloat(x.qty)) * 100) / 100).toFixed(2) : '0.00'
            return {
              productId: x.productId,
              productCode: x.productName,
              orderQty: x.qty,
              negotiatedPrice: x.unitPrice ? (Math.round(x.unitPrice * 100) / 100).toFixed(2) : '0.00',
              discountPct: x.discountPct ? (Math.round(x.discountPct * 100) / 100).toFixed(2) : '0.00',
              fnAmount: x.amount ? (Math.round(x.amount * 100) / 100).toFixed(2) : '0.00',
              discAmount: discAmount
            }
          })
          setValue("lineItems", salesValue)
          setCalculate(tempcalculate)
          setIsProcessing(false);
        } catch (err) {
          console.log(err);
          errorMsg = err && err.response && err.response.data && err.response.data.message;
          setIsError(true);
          setIsProcessing(false);
        }
      }
    }else{
        setErrQtyProd(errqtyProd);
        setIsProcessing(false);
      }
  }else{
      errorMsg = "Product is required";
      setIsError(true);
    }
  };

  const handleDateChange = (value,type) => {
    const clonedDate = _.cloneDeep(date);
    clonedDate[type] = value
    setDate(clonedDate)
  };

  const handlePriceChange=(e,index)=>{
    const clonedNegPrice = _.cloneDeep(negPrice)
    clonedNegPrice[index] = e.target.value;
    setNegPrice(clonedNegPrice)
  }

  const onCalculateClick = (index) => {
    const values = getValues({ nest: true })["lineItems"];
    const rowValues = values[index];
    setPriceData({ ...priceData, productId: rowValues.productId, productName: rowValues.productCode, amount: rowValues.negotiatedPrice, qty: rowValues.orderQty, discount: rowValues.discountPct, open: true });
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={4} xs={4}>
            <Typography variant="caption">Product Order No. </Typography>
            <TextField
              variant="outlined"
              fullWidth placeholder="Auto Generated"
              InputProps={{
                autoFocus: true,
                name: "poNum",
              }} value={props.form.data.poNum}
              size="small"
              name="poNum" disabled/>
          </Grid>
          <Grid item md={4} xs={4}>
            <Grid container>
            <Grid item md={12} xs={12}>
            <Typography variant="caption">Order Date *</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    size="small"
                    fullWidth
                    helperText=""
                    error={Boolean(errors.orderDate)}
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    InputProps={{
                      style: { color: "black" },
                    }}
                    inputRef={register({
                      required: "Date is required",
                      pattern: {
                        value: /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
                      },
                    })}
                    name="orderDate"
                    value={date.orderDate} disabled={props.form.mode === "C" ? false:!(sts_id===1) || props.readOnly}
                    onChange={(value)=>handleDateChange(value,"orderDate")}
                  />
                </MuiPickersUtilsProvider>
            <FormHelperText error>
              {errors.orderDate && errors.orderDate.message}
            </FormHelperText>
            </Grid>
            </Grid>
          
        
          </Grid>
        </Grid>
        <Grid item container md={12} xs={12} spacing={1}>
          <Grid item md={4} xs={4}>
          <Typography variant="caption">Status</Typography>
            <FormControl fullWidth variant="outlined" size="small">
              <Controller
                as={
                  <Select>
                    {poStatusLookUp.map(function (type) {
                      return (
                        <MenuItem key={type.id} value={type.id}>
                          {type.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                } name="statusId" size="small" variant="outlined"
                control={control} defaultValue="" disabled={sts_id===2 || sts_id===3 || props.readOnly || (props.form.mode === "C" && (props.isPO || props.isQuotation))}/>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={4}>
          <Typography variant="caption">Customer Purchase Order Type</Typography>
            <FormControl fullWidth variant="outlined" size="small">
              <Controller
                as={
                  <Select>
                    {poTypesLookUp.map(function (type) {
                      return (
                        <MenuItem key={type.id} value={type.id}>
                          {type.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                } name="typeId" size="small" variant="outlined"
                control={control} defaultValue="" disabled={sts_id===2 ||  props.readOnly || sts_id===3 || (props.form.mode === "C" && (props.isPO || props.isQuotation))}/>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 40, paddingBottom: 40 }}>
        {fields.map((contact, index) => (
          <>
            <input
              type="hidden"
              name={`lineItems[${index}].contactId`}
              value={`${contact.contactId}`}
              ref={register()}
            />
            <input
              type="hidden"
              name={`lineItems[${index}].contactId`}
              value={`${contact.contactId}`}
              ref={register()}
            />
          </>
        ))}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledHeaderCell align="left"  style={{ width: "30%" }} colSpan={4}>Product Name</StyledHeaderCell>
                <StyledHeaderCell align="left">Order Qty</StyledHeaderCell>
                <StyledHeaderCell align="left">Fullfilled Qty</StyledHeaderCell>
                <StyledHeaderCell align="left">Available Qty</StyledHeaderCell>
                <StyledHeaderCell align="left">Product Rate</StyledHeaderCell>
                <StyledHeaderCell align="left">Disc %</StyledHeaderCell>
                <StyledHeaderCell align="left" style={{ width: "10%" }}>Disc. Rate Per Unit</StyledHeaderCell>
                <StyledHeaderCell align="left">Amount</StyledHeaderCell>
                {(sts_id!==2) && sts_id!==3 && !props.readOnly &&
                <StyledHeaderCell align="left">Action</StyledHeaderCell>}
              </TableRow>
            </TableHead>
            {fields.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    <Typography variant="subtitle1">
                      Nothing Purchased.
                    </Typography>
                    <Button
              variant="contained"
              onClick={(e) => {
                append({
                  contactId: "",
                  productId:"",
                  orderQty: "",
                  availableQty: "",
                  negotiatedPrice: "",
                  discountPct: "",
                  isQtyDecimal:false,
                });
                setErrQtyProd([]);
              }}
              color="primary"
              size="small"
            >
              ADD PRODUCT
            </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}

            <TableBody>
              {fields.map((contact, index) => {
                return(
                <TableRow key={contact.id}>
                  <TableCell align="left"  colSpan={4}>
                    <TextField
                    variant="standard"
                    fullWidth
                    error={Boolean(
                      errors.lineItems &&
                        errors.lineItems[index] &&
                        errors.lineItems[index].productCode
                    )}
                    inputRef={register({
                      required: "Product is required",
                    })}
                    onClick={(e)=> setSelectProduct({ ...selectProduct, open: true, lineKey:index, lineItems:props.form.data.lineItems,create:props.form.mode === "C"})}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{cursor:"pointer"}}>
                          {(sts_id!==2) && sts_id!==3 && <SearchIcon  onClick={(e)=>  setSelectProduct({ ...selectProduct, open: true})}/>}
                        </InputAdornment>
                      ),
                      style: { fontSize: "14px"},
                    }}
                    multiline
                    rows={1}
                    rowsMax={3}
                    size="small"
                    name={`lineItems[${index}].productCode`}
                    key={contact.id}  disabled
                    defaultValue={contact.productCode}
                  />
                  <TextField
                    variant="standard"
                    fullWidth
                    error={Boolean(
                      errors.lineItems &&
                        errors.lineItems[index] &&
                        errors.lineItems[index].productId
                    )}
                    inputRef={register({
                    })}
                    size="small"
                    style={{display:"none"}}
                    name={`lineItems[${index}].productId`}
                    key={contact.id}  disabled
                    defaultValue={props.form.data.lineItems && (props.form.data.lineItems)[index] ? (props.form.data.lineItems)[index].product && (props.form.data.lineItems)[index].product.id:''}
                  />
                    <FormHelperText error>
                      {errors.lineItems &&
                        errors.lineItems[index] &&
                        errors.lineItems[index].productCode &&
                        errors.lineItems[index].productCode.message}
                    </FormHelperText>
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      error={Boolean(
                        errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].orderQty
                      ) || (_.includes(errQtyProIndex, index)) ? true : false}
                      inputProps={{style: { textAlign: 'right' }}}
                      inputRef={register({
                        required: "Required",
                        pattern: {
                          value:  /^[0-9]*.[0-9]{0,3}$/,
                          message: "Qty should be greater than 0",
                        },
                      })}
                      size="small"
                      name={`lineItems[${index}].orderQty`}
                      defaultValue={contact.orderQty} disabled={sts_id===2 || props.readOnly || sts_id===3}
                      variant="standard"
                    />
                    <FormHelperText error>
                      {(errors.lineItems &&
                        errors.lineItems[index] &&
                        errors.lineItems[index].orderQty &&
                        errors.lineItems[index].orderQty.message) || ((_.includes(errQtyProIndex, index)) ? true : false)?"Enter Valid Qty":""}
                    </FormHelperText>
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      error={Boolean(
                        errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].fulfilledQty
                      )}
                      inputProps={{style: { textAlign: 'right' }}}
                      inputRef={register({
                        required: "Required",
                        pattern: {
                          value: /^[0-9]{1,}$/,
                          message: "Required",
                        },
                      })}
                      size="small" disabled={true}
                      name={`lineItems[${index}].fulfilledQty`}
                      defaultValue={props.form.data.lineItems && (props.form.data.lineItems)[index] ? (props.form.data.lineItems)[index].product && (props.form.data.lineItems)[index].product.fulfilledQty:''}
                      variant="standard"
                    />
                    <FormHelperText error>
                      {errors.lineItems &&
                        errors.lineItems[index] &&
                        errors.lineItems[index].fulfilledQty &&
                        errors.lineItems[index].fulfilledQty.message}
                    </FormHelperText>
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      error={Boolean(
                        errors.lineItems &&
                          errors.lineItems[index] &&
                          errors.lineItems[index].availableQty
                      )}
                      inputProps={{style: { textAlign: 'right' }}}
                      inputRef={register({})}
                      size="small" disabled={true}
                      name={`lineItems[${index}].availableQty`}
                      defaultValue={props.form.data.lineItems && (props.form.data.lineItems)[index] && (props.form.data.lineItems)[index].product ? 
                      ((props.form.data.lineItems)[index].product.inventories && ((props.form.data.lineItems)[index].product.inventories.filter(x=>x.locationId === corporateLocationId)).length > 0 && ((props.form.data.lineItems)[index].product.inventories.filter(x=>x.locationId === corporateLocationId))[0].openingQty)  || 0:''}
                      variant="standard"
                    />
                    <FormHelperText error>
                      {errors.lineItems &&
                        errors.lineItems[index] &&
                        errors.lineItems[index].availableQty &&
                        errors.lineItems[index].availableQty.message}
                    </FormHelperText>
                  </TableCell>
                  <TableCell align="right">
                  <TextField
                    variant="standard"
                    fullWidth
                    inputProps={{style: { textAlign: 'right' }}}
                    error={Boolean(
                      errors.lineItems &&
                        errors.lineItems[index] &&
                        errors.lineItems[index].negotiatedPrice
                    )}
                    inputRef={register({
                      required: "Product Price Required",
                      pattern: {
                        value: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
                        message: "Product Price Required",
                      },
                    })}
                    size="small" disabled={sts_id===2 || sts_id===3 || props.readOnly}
                    name={`lineItems[${index}].negotiatedPrice`}
                    onChange={(e)=>handlePriceChange(e,index)}
                    key={contact.id}  
                    defaultValue={props.form.data.lineItems && (props.form.data.lineItems)[index] ? (props.form.data.lineItems)[index].product && (props.form.data.lineItems)[index].product.negotiatedPrice:''}
                  />
                    <FormHelperText error>
                      {errors.lineItems &&
                        errors.lineItems[index] &&
                        errors.lineItems[index].negotiatedPrice &&
                        errors.lineItems[index].negotiatedPrice.message}
                    </FormHelperText>
                  </TableCell>
                  <TableCell>
                  <TextField
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          name={`lineItems[${index}].discountPct`}
                          inputRef={register({
                          })}
                          defaultValue={props.form.data.lineItems && (props.form.data.lineItems)[index] ? (props.form.data.lineItems)[index].product && (props.form.data.lineItems)[index].product.discountPct:''}
                          variant="standard"
                          fullWidth
                        />
                  </TableCell>
                  <TableCell>
                  <TextField
                          disabled
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          inputRef={register({})}
                          name={`lineItems[${index}].discAmount`}
                          defaultValue={contact.discAmount}
                          variant="standard"
                          fullWidth
                        />
                  </TableCell>
                  <TableCell>
                  <TextField
                          disabled
                          inputProps={{ style: { textAlign: 'right' } }}
                          size="small"
                          inputRef={register({})}
                          name={`lineItems[${index}].fnAmount`}
                          defaultValue={contact.fnAmount} 
                          variant="standard"
                          fullWidth
                        />
                  </TableCell>
                  <TableCell style={{display:'none'}}>
                      <TextField
                          disabled
                          inputProps={{style: {textAlign: 'right',display:'none' }}}
                          inputRef={register({})}
                          size="small"
                          name={`lineItems[${index}].isQtyDecimal`}
                          defaultValue={props.form.data.lineItems && (props.form.data.lineItems)[index] ? (props.form.data.lineItems)[index].product && (props.form.data.lineItems)[index].product.isQtyDecimal:''}
                          variant="standard"
                        />
                        </TableCell>
                  {(sts_id!==2 && sts_id!==3 && !props.readOnly) && <TableCell align="left" style={{width:'12%'}}>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        insert(index + 1, {
                          contactId: "",
                          productId:"",
                          productCode:"",
                          orderQty: "",
                          availableQty: "",
                          negotiatedPrice: "",
                          discountPct: "",
                          isQtyDecimal:false,
                        });
                        setErrQtyProd([]);
                      }
                    }
                    >
                      <AddIcon color="secondary" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        remove(index);                        
                        setErrQtyProd([]);
                      }}
                    >
                      <DeleteIcon color="secondary" />
                    </IconButton>
                    <IconButton size="small" onClick={() => onCalculateClick(index)}>
                          <img src={calculateIcon} alt="calculteicon" />
                    </IconButton>
                  </TableCell>
                  }
                </TableRow>
              )})}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={selectProduct.open}
        onClose={() => {
          setSelectProduct({ ...selectProduct, open: false });
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Select Product
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setSelectProduct({ ...selectProduct, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <SearchProduct
            form={selectProduct}
            setValue = {setValue}
            getValues={getValues}
            exist={true}
            lineIdentifier = {true}
            addonData = {{customerType:props.customerTypeId,locationId:corporateLocationId }}
            cancel={() => {
              setSelectProduct({ ...selectProduct, open: false });
            }}
            success={() => {
              setSelectProduct({ ...selectProduct, open: false, success: true });
            }}
          />
        </DialogContent>
      </Dialog>
         {/* calculator */}
         <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={priceData.open}
        onClose={() => {
          setPriceData({ ...priceData, open: false });
        }}
      >
        <DialogTitle style={{ paddingBottom: "0px" }}>
          <Grid container>
            <Grid item container xs={9} alignItems="center">
              <Grid item>
                Price Calculator
              </Grid>
            </Grid>
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Grid item>
                <IconButton
                  onClick={() => {
                    setPriceData({ ...priceData, open: false });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent >
          <Calculator
            cancel={() => {
              setPriceData({ ...priceData, open: false });
            }}
            priceData={priceData}
            intraState={props.existCustData && props.existCustData.org && (props.existCustData.state !== props.existCustData.org.state)}
          />
        </DialogContent>
      </Dialog>
      </div>

      <Grid container spacing={2} style={{ paddingTop: 10 }}>
        <Grid
          item
          container
          xs={6}
          justify={isProcessing ? "flex-end" : "flex-start"}
        >
          {isProcessing ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : null}
          {isError ? (
            <Grid item>
              <Typography error>
                <FormHelperText error>{errorMsg}</FormHelperText>
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid container>
        <Grid item xs={12}>     
          {calculate && <Box mt={1}>
            <List dense={true} style={{ backgroundColor: "#F5F5F5", fontSize: "14px" }}>
              <ListItem alignItems="flex-start" divider={true} >
                <Grid item container xs={12}>
                  <Grid item xs={11}>
                    Subtotal
                  </Grid>
                  <Grid item xs={1}>
                    <Box display="flex" justifyContent="flex-end"> {calculate.subtotal && (Math.round(calculate.subtotal * 100) / 100).toFixed(2)}</Box>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem alignItems="flex-start" divider={true}>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    GST
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem alignItems="flex-start" divider={true}>

                <Grid item container xs={12} >
                  <Grid item xs={11} >
                    <Box ml={2} >IGST</Box>
                  </Grid>
                  <Grid item xs={1} >
                    <Box display="flex" justifyContent="flex-end">{calculate.IGST && (Math.round(calculate.IGST * 100) / 100).toFixed(2)}</Box>
                  </Grid>
                  <Grid item xs={11} >
                    <Box mt={0.3} ml={2}>CGST</Box>
                  </Grid>
                  <Grid item xs={1} >
                    <Box mt={1} display="flex" justifyContent="flex-end">{calculate.CGST && (Math.round(calculate.CGST * 100) / 100).toFixed(2)}</Box>
                  </Grid>
                  <Grid item xs={11} >
                    <Box ml={2} >SGST</Box>
                  </Grid>
                  <Grid item xs={1} >
                    <Box display="flex" justifyContent="flex-end">{calculate.SGST && (Math.round(calculate.SGST * 100) / 100).toFixed(2)}</Box>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem alignItems="flex-start">
                <Grid item container xs={12} style={{ fontWeight: "bold" }}>
                  <Grid item xs={11}>
                    Total
                  </Grid>
                  <Grid item xs={1} style={{ borderLeft: "4px solid #FFFFFF" }}>
                    <Box display="flex" justifyContent="flex-end"> {calculate.total && (Math.round(calculate.total * 100) / 100).toFixed(2)}</Box>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
            <Box mt={2} style={{ fontSize: "12px" }}>{`Amount Chargeable(In words): ${calculate.amountInWords}`}</Box>
          </Box>} 
        </Grid>
      </Grid>
        {!(sts_id===2 || props.readOnly || sts_id===3) && <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justify="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                setCalculate(null)
                props.cancel();
              }}
              color="primary"
              size="small"
              type="reset"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSubmit((data)=>{onSubmit(data,"CALCULATE")})}
            >
              Calculate
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={handleSubmit((data)=>{onSubmit(data,"SAVE")})}
            >
              Save
            </Button>
          </Grid>
        </Grid>}
      </Grid>
      <input type="hidden" name="id" ref={register()} />
     
    </form>
  );
}

export default PurchaseForm;
