import React from "react";
import { useHistory } from "react-router-dom";
import customers from "../../images/Customers.svg";
import products from "../../images/Products.svg";
import users from "../../images/Users.svg";
import inventory from "../../images/Inventory.svg";
import batchJobs from "../../images/BatchJobs.svg";
import productGroups from "../../images/ProductGroups.svg";
import serviceRequests from "../../images/ServiceRequests.svg";
import reports from "../../images/Reports.svg";

import { Link, Typography, Box, Grid, Paper } from "@material-ui/core";

const AdminDashboard = () => {
  const history = useHistory();
  
  const handleUserGuide = () =>{
    // window.location.href = "/userguide.pdf";
    window.open("/userguide.pdf", '_blank');
  }

  const renderFeatureTiles = (features) => {
    return features.map((feature, index) => (
      <Grid item md={3} xs={12} key={index}>
        {feature.path &&
        <Link
          component="button"
          style={{ textDecoration: "none" }}
          onClick={() => {
            history.push(feature.path);
          }}
        >
          <Paper elevation={10} style={{ width: 200, height: 200 }}>
            <Box
              style={{ paddingTop: 10 }}
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <img
                src={feature.icon}
                alt= "feature icon"
                style={{ width: "70%", height: "70%" }}
              />
            </Box>
            <Box
              style={{
                paddingTop: 10,
                color: "#0C4B60",
              }}
              fontWeight={"fontWeightBold"}
              fontSize={12}
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <Typography>
                <b>{feature.description}</b>
              </Typography>
            </Box>
          </Paper>
        </Link>}
      </Grid>
    ))
  }

  const featuresLower = [
    {
      description: "Customers",
      icon: customers,
      path: "/customers",
    },
    {
      description: "Service Requests",
      icon: serviceRequests,
      path: "/jobs",
    },
    {
      description: "Users",
      icon: users,
      path: "/users"
    },
    {
      description: "Data Management",
      icon: batchJobs,
      path: "/datasync"
    }
  ];

  const featuresTop = [
    {
      description: "Inventory",
      icon: inventory,
      path: "/inventory",
    },
    {
      description: "Product Groups",
      icon: productGroups,
      path: "/productGroups",
    },
    {
      description: "Products",
      icon: products,
      path: "/products"
    },
    {
      description: "Reports",
      icon: reports,
      path: "/reports"
    }
  ];

  
  const renderQuiclLinks = (links) => {
    return (
      <Grid item xs={12}>
        <Grid container
        align="center"
        alignItems="center"
        justify="right"
        style={{justifyContent:"end", paddingRight:"4%"}}
        >
        <Grid item>
          <span>Quick Links</span>
          {links && links.map((link, index) => (
            <span key={index} style={{paddingLeft:"15px", fontWeight:"bold"}}>
              {link.path &&
              <Link
                component="span"
                className="underLine"
                style={{cursor: "pointer" }}
                onClick={() => {(link.description === "User Guide")?handleUserGuide()
                  :history.push(link.path);
                }}
              >
              {link.description}
              </Link>}
            </span>
            ))}
            </Grid>
      </Grid>
    </Grid>
    )
  }
  const quickLinks = [
    {
      description: "Quotations",
      path: "/customers?from=quotation",
    },
    {
      description: "Customer Purchase Orders",
      path: "/customers?from=purchaseorder",
    },
    {
      description: "User Guide",
      path: "/userguide.pdf",
    },
  ]

  return (
    <Grid
      className="dashboard-grid-container"
      container
      align="center"
      alignItems="center"
      justify="left"
      spacing={3}
    >
      {renderQuiclLinks(quickLinks)}
      {renderFeatureTiles(featuresTop)}
      {renderFeatureTiles(featuresLower)}
    </Grid>
  );
};

export default AdminDashboard;
